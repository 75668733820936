import React, { useEffect, useState } from 'react';
import { Button, Flex, Form, message, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useChangeProduct, useImportTableUid, useUpdateOrderPricePreview } from '@/hooks';
import { useOrderExcelRowByUID, useUpdateOrderExcelRow } from '@/hooksApi/useImport';
import { Heading } from '../Heading';
import { Store } from 'antd/es/form/interface';
import {
  arrayToCommaSeparatedObject,
  attachCountryCodeToPhone,
  commaSeparatedObjectToArray,
  parsePhoneNumber,
} from '@/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useFetchListProvinces, useFetchAreaByProvinceCode } from '@/hooksApi/useRegion';
import {
  CustomerDetailsCard,
  DeliveryInstructionCard,
  OrderIdCard,
  PackageAndPaymentCard,
  ProductDetailsCard,
} from '../OrderForm';
import { BasicProduct, OrderExcelRow, PaymentType, ShipmentFeeType } from '@/types';
import useProductStore from '@/stores/productStore';

interface ModalProps {
  editingOrderUid?: string;
  onClose: () => void;
}

const EditOrderForm: React.FC<ModalProps> = ({ editingOrderUid, onClose }) => {
  const [selectedProvinceCode, setSelectedProvinceCode] = useState<string | undefined>(undefined);

  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const tableUid = useImportTableUid((state) => state.importTableUid); // Access the Table UID setter from Zustand
  const { mutate: updateOrder, isLoading: updateLoading } = useUpdateOrderExcelRow(
    tableUid,
    editingOrderUid || '',
  );

  const { data: orderRow, isLoading: orderRowLoading } = useOrderExcelRowByUID(
    tableUid,
    editingOrderUid || '',
  );

  const { data: provinceList } = useFetchListProvinces();

  const { data: areaList, isLoading: areaListLoading } = useFetchAreaByProvinceCode(
    selectedProvinceCode!,
  );

  const { products, resetProducts } = useProductStore();

  const {
    handleFormUpdated,
    orderPrice,
    setOrderPrice,
    orderPriceLoading,
    customShipmentFee,
    setCustomShipmentFee,
    setPackageSize,
    checkOrderFormValidation,
    handleProductChanged,
  } = useUpdateOrderPricePreview(products, form, true);

  const { handleAddProduct, handleDeleteProduct, setProducts, handleUpdateProduct } =
    useChangeProduct(handleProductChanged);

  useEffect(() => {
    return () => {
      form.resetFields();
      resetProducts();
    };
  }, []);

  useEffect(() => {
    if (!areaListLoading && areaList && areaList?.object.length > 0) {
      const area = areaList.object.find((item) => item.uid === orderRow?.object.region?.uid);

      form.setFieldsValue({ region_uid: area?.uid });
    }
  }, [areaListLoading, areaList, orderRow, editingOrderUid]);

  useEffect(() => {
    form.resetFields();
    if (orderRow) {
      setOrderPrice({
        ...orderPrice,
        // ! Shipment fee is missing.
        // ! Elham mentioned that this is not required
        orders_value: Number(orderRow.object.product_price) ?? 0,
      });

      setInitialProductList(orderRow.object);

      const initFormData = getInitialFormValue(orderRow.object);

      form.setFieldsValue(initFormData);
    }
  }, [orderRow, form, editingOrderUid]);

  const getInitialFormValue = (orderData: OrderExcelRow) => {
    const provinceCode = provinceList?.object.find(
      (item) => item.province_code === orderData.region?.province_code,
    )?.province_code;
    const area_uid =
      provinceCode && provinceCode === selectedProvinceCode ? orderData.region?.uid : undefined;
    if (provinceCode && provinceCode !== selectedProvinceCode)
      setSelectedProvinceCode(provinceCode);

    const primaryPhoneInfo = parsePhoneNumber(orderData.phone);
    const secondaryPhoneInfo = parsePhoneNumber(orderData.secondary_phone ?? undefined);

    const data = {
      name: orderData.full_name || '',
      address: orderData.address_text || '',
      phoneNumberPrimary: primaryPhoneInfo.phone || '',
      countryCodePrimary: primaryPhoneInfo.countryCode,
      countryCodeSecondary: secondaryPhoneInfo?.countryCode,
      secondaryPhoneNumber: secondaryPhoneInfo?.phone,
      deliveryInstruction: orderData.description || '',
      packageSize: orderData.size || '',
      isFragile: orderData.is_fragile,
      CodOrPrepaid: orderData.payment_type,
      CustomerOrMerchant: orderData.shipment_fee_type,
      orderId: orderData.custom_id,
      pickupOrDropoff: orderData.pick_up_type,
      province: provinceCode,
      region_uid: area_uid,
      // saved_pick_up_address_uid: orderRow?.object?.pick_up_address.uid,
    };

    return data;
  };

  const setInitialProductList = (orderData: OrderExcelRow) => {
    const commaSeparatedObj = {
      product_title: orderData?.product_title,
      product_price: orderData?.product_price,
      product_quantity: orderData?.product_quantity,
    };
    const productsArrayFromObject = commaSeparatedObjectToArray(commaSeparatedObj);

    if (productsArrayFromObject) {
      setProducts(productsArrayFromObject);
    }
  };

  const transformValuesToUpdateOrder = (values: Store) => {
    const commaSeparatedProduct = arrayToCommaSeparatedObject(products);

    const primaryPhoneNumber = attachCountryCodeToPhone(
      values?.phoneNumberPrimary,
      values?.countryCodePrimary,
    );

    const secondaryPhoneNumber = attachCountryCodeToPhone(
      values?.secondaryPhoneNumber,
      values?.countryCodeSecondary,
    );

    return {
      is_fragile: values?.isFragile || false,
      shipment_fee_type: values?.CustomerOrMerchant || ShipmentFeeType.BY_MERCHANT,
      payment_type: values?.CodOrPrepaid || PaymentType.COLLECT_ON_DELIVERY,
      pick_up_type: values?.pickupOrDropoff || 'PICK_UP',
      description: values?.deliveryInstruction || '',
      custom_id: values?.orderId || '',
      // saved_pick_up_address_uid: values?.saved_pick_up_address_uid || '',
      size: values?.packageSize || 'S',
      full_name: values?.name || '',
      address_text: values?.address || '',
      phone: primaryPhoneNumber,
      secondary_phone: secondaryPhoneNumber,
      product_title: commaSeparatedProduct.product_title,
      product_quantity: commaSeparatedProduct.product_quantity,
      product_price: commaSeparatedProduct.product_price,
      region_uid: values.region_uid,
    };
  };

  const onFinish = async (values: Store) => {
    const orderPayload = transformValuesToUpdateOrder(values);

    updateOrder(orderPayload, {
      onSuccess: () => {
        queryClient.invalidateQueries(['import']);
        queryClient.invalidateQueries(['import-valid']);
        queryClient.invalidateQueries(['import-invalid']);

        message.success(t('updated_successfully'));
        onClose();
      },
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Form submission failed:', errorInfo);
    message.error(t('submission_failed'));
  };

  const handleSetSelectedProvinceCode = (provinceCode?: string) => {
    form.setFieldsValue({ province: provinceCode });
    form.setFieldsValue({ region_uid: undefined });
    form.setFieldValue('region_uid', undefined);
    setSelectedProvinceCode(provinceCode);
  };

  const handleCreateProduct = (product: BasicProduct) => {
    const formValues = form.getFieldsValue();

    const newProductList = [...(formValues.products ?? []), product];

    if (checkOrderFormValidation({ ...formValues, products: newProductList }, true)) {
      handleAddProduct(product);
      return true;
    }
    return false;
  };

  if (orderRowLoading) {
    return <Spin size='small' className='flex justify-center items-center h-screen' />;
  } else
    return (
      <Form
        form={form}
        name='edit-row-order'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={handleFormUpdated}
      >
        <CustomerDetailsCard
          form={form}
          selectedProvinceCode={selectedProvinceCode}
          setSelectedProvinceCode={handleSetSelectedProvinceCode}
        />
        <DeliveryInstructionCard />
        <OrderIdCard />
        <ProductDetailsCard
          products={products}
          handleCreateProduct={handleCreateProduct}
          handleDeleteProduct={handleDeleteProduct}
          handleUpdateProduct={handleUpdateProduct}
        />
        <PackageAndPaymentCard
          form={form}
          onPackageSizeChange={setPackageSize}
          orderPrice={orderPrice}
          orderPriceLoading={orderPriceLoading}
          paymentType={orderRow?.object?.payment_type}
          shipmentFeeType={orderRow?.object?.shipment_fee_type}
          packageSize={orderRow?.object?.size}
          onCustomShipmentFeeChange={setCustomShipmentFee}
          customShipmentFee={customShipmentFee}
        />
        <Flex className='flex justify-end w-full gap-x-4 pt-2 '>
          <Button
            style={{
              paddingTop: '26px',
              paddingBottom: '26px',
            }}
            className='px-[26px] rounded-lg'
            type='default'
            onClick={onClose}
          >
            <Heading
              title={t('cancel')}
              fontSize='text-sm'
              fontWeight='font-bold'
              extraClassName='leading-5 uppercase'
            />
          </Button>
          <Button
            style={{ paddingTop: '26px', paddingBottom: '26px' }}
            type='primary'
            htmlType='submit'
            className='px-[26px] rounded-lg'
            loading={updateLoading}
          >
            <Heading
              title={t('update')}
              fontSize='text-sm'
              fontWeight='font-bold'
              extraClassName='leading-5 uppercase'
            />
          </Button>
        </Flex>
      </Form>
    );
};

export default EditOrderForm;
