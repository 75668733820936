import React, { useEffect, useState } from 'react';
import { Col, Flex, Form, FormInstance, Radio, RadioChangeEvent, Row, Typography } from 'antd';
import { Card } from '../../Card';
import { Heading } from '../../Heading';
import { useTranslation } from 'react-i18next';
import { CustomRadioButton } from '../../CustomRadioButton';
// import SwitchWalletCard from './SwitchWalletCard';
// import DiscountInputCard from './DiscountInputCard';
import OrderShipmentValueCard from './OrderShipmentValueCard/OrderShipmentValueCard';
import { PaymentType, Preview, ShipmentFeeType } from '@/types';
import CustomShipmentFeeCard from './CustomShipmentFeeCard';

const { Text } = Typography;

interface PaymentCardProps {
  form: FormInstance;
  orderPrice: Preview;
  orderPriceLoading: boolean;
  paymentType?: PaymentType;
  shipmentFeeType?: ShipmentFeeType;
  onCustomShipmentFeeChange: (shipmentFee?: number) => void;
  customShipmentFee?: number;
}

export const PaymentCard: React.FC<PaymentCardProps> = ({
  form,
  orderPrice,
  orderPriceLoading,
  paymentType,
  shipmentFeeType,
  onCustomShipmentFeeChange,
  customShipmentFee,
}) => {
  const { t } = useTranslation();
  // const [discountCode, setDiscountCode] = useState('');

  const [selectedPaymentTypeValue, setSelectedPaymentTypeValue] = useState(
    paymentType ?? PaymentType.COLLECT_ON_DELIVERY,
  );

  const [selectedShipmentFeeTypeValue, setSelectedShipmentFeeTypeValue] = useState(
    shipmentFeeType ?? ShipmentFeeType.BY_MERCHANT,
  );

  useEffect(() => {
    const initialPaymentType = paymentType || form.getFieldValue('CodOrPrepaid'); // Use packageSizeProp if available
    const initialShipmentFeeType = shipmentFeeType || form.getFieldValue('CustomerOrMerchant'); // Use packageSizeProp if available

    if (initialPaymentType && initialShipmentFeeType) {
      setSelectedPaymentTypeValue(initialPaymentType);
      setSelectedShipmentFeeTypeValue(initialShipmentFeeType);
      form.setFieldsValue({
        CodOrPrepaid: initialPaymentType,
        CustomerOrMerchant: initialShipmentFeeType,
      });
    }
  }, [paymentType, shipmentFeeType, form]);

  const handleRadioChangePaymentType = (e: RadioChangeEvent) => {
    setSelectedPaymentTypeValue(e.target.value);
  };

  // const handleRadioChangeShipmentFeeType = (e: RadioChangeEvent) => {
  //   setSelectedShipmentFeeTypeValue(e.target.value);
  // };

  return (
    <Card>
      <Heading
        title={t('payment')}
        fontSize='text-lg'
        fontWeight='font-bold'
        extraClassName='uppercase'
      />
      <Flex vertical className='mt-4'>
        <Text className='font-normal mb-2 text-xs leading-[10px] text-customGrayShade5 capitalize'>
          {t('order_title')}
        </Text>

        <Form.Item name='CodOrPrepaid'>
          <Radio.Group
            value={selectedPaymentTypeValue}
            onChange={handleRadioChangePaymentType}
            className='w-full'
          >
            <Row gutter={[24, 16]} className='mt-[19.5px]'>
              <Col xl={12} lg={24} md={24} xs={24}>
                <CustomRadioButton
                  value={PaymentType.COLLECT_ON_DELIVERY}
                  selectedValue={selectedPaymentTypeValue}
                  label={t('cod')}
                />
              </Col>
              <Col xl={12} lg={24} md={24} xs={24}>
                <CustomRadioButton
                  value={PaymentType.PREPAID}
                  selectedValue={selectedPaymentTypeValue}
                  label={t('prepaid')}
                />
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>
      </Flex>
      {/* <Flex vertical className='mt-4'>
        <Text className='font-normal mb-2 text-xs leading-[10px] text-customGrayShade5 capitalize'>
          {t('shipment_fee')}
        </Text>
        <Form.Item name='CustomerOrMerchant' initialValue={shipmentFeeType}>
          <Radio.Group
            value={selectedShipmentFeeTypeValue}
            onChange={handleRadioChangeShipmentFeeType}
            className='w-full'
          >
            <Row gutter={[24, 16]} className='mt-[19.5px]'>
              <Col lg={12} md={24} xs={24}>
                <CustomRadioButton
                  value={ShipmentFeeType.BY_CUSTOMER}
                  selectedValue={selectedShipmentFeeTypeValue}
                  label={t('customer')}
                />
              </Col>
              <Col lg={12} md={24} xs={24}>
                <CustomRadioButton
                  value={ShipmentFeeType.BY_MERCHANT}
                  selectedValue={selectedShipmentFeeTypeValue}
                  label={t('merchant')}
                />
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>
      </Flex> */}
      {/* {selectedShipmentFeeTypeValue === ShipmentFeeType.BY_CUSTOMER && (
        <CustomShipmentFeeCard
          onCustomShipmentFeeChange={onCustomShipmentFeeChange}
          customShipmentFee={customShipmentFee}
        />
      )} */}
      {/* V0 remove */}
      {/* <SwitchWalletCard
        availableIqd={1}
        walletBalanceText={t('wallet_balance')}
        availableText={t('available')}
        iqdText={t('iqd')}
      />
      <DiscountInputCard
        placeholder={t('discount_code')}
        value={discountCode}
        onChange={setDiscountCode}
      /> */}
      <OrderShipmentValueCard
        orderValue={orderPrice.orders_value}
        shipmentFee={orderPrice.orders_shipment_fee}
        customShipmentFee={orderPrice.orders_custom_shipment_fee}
        customShipmentFeeText={t('customer_shipping_fee')}
        // walletFee={-2000}
        // walletFeeText={t('paid_from_wallet')}

        shipmentFeeType={selectedShipmentFeeTypeValue}
        paymentType={selectedPaymentTypeValue}
        total={orderPrice.orders_value_with_shipment_fee}
        orderPriceLoading={orderPriceLoading}
        orderValueText={t('order_value')}
        shipmentFeeText={t('shipment_fee')}
        totalText={t('total')}
        currency={t('iqd')}
      />
    </Card>
  );
};
