import { Button, Dropdown, Flex, MenuProps, Space, Typography } from 'antd';
import { DeleteIcon, DuplicateIcon, EditOutline, EyeIcon, MoreOutline } from '../Icons';
import { Heading } from '../Heading';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { OrderStatusTab } from '@/types';
import { useHandleCancelOrder } from '@/hooks';
import { ROUTE } from '@/config';
import { ResolveModal } from '../ResolveModal';
import { useResolveModalStore } from '@/hooks';
import { OrderDataType } from '@/hooks/useOrderData';
import { ItemType } from 'antd/es/menu/interface';
import { useDuplicateOrder } from '@/hooksApi';
import { useQueryClient } from '@tanstack/react-query';

export interface ActionProps {
  uid?: string;
  activeTab?: OrderStatusTab;
  cancelLabel?: string;

  orderData?: OrderDataType;
  savePrevPage?: boolean;
  button?: {
    label?: string;
    icon?: ReactNode;
    disabled?: boolean;
    onClick?: (() => void) | 'openResolveModal';
    navigateLink?: string;
  };
  menuItems?: OrderMenuItemsEnum[];
  customMenuItems?: CustomMenuItemData[];
}

interface CustomMenuItemData {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  leadingIcon?: ReactNode;
  loading?: boolean;
  key: string;
}

interface MenuItemData {
  key: string;
  label: ReactNode;
  onClick: () => void;
}

export enum OrderMenuItemsEnum {
  EDIT_ORDER = 'edit',
  CANCEL_ORDER = 'cancel',
  DISABLE_CANCEL_ORDER = 'disable-cancel',
  VIEW_ORDER = 'view',
  DUPLICATE_ORDER = 'duplicate',
}

const ActionsTable = ({
  button,
  menuItems,
  customMenuItems,
  uid,
  activeTab,
  cancelLabel,
  orderData,
  savePrevPage,
}: ActionProps) => {
  const [resolveModalOpen, setResolveModalOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  // Zustand store setters
  const setFullName = useResolveModalStore((state) => state.setFullName);
  const setPhone = useResolveModalStore((state) => state.setPhone);
  const setOrderValue = useResolveModalStore((state) => state.setOrderValue);
  const setAddressTitle = useResolveModalStore((state) => state.setAddressTitle);

  // mutate cancel or delete order
  const { handleCancelOrder, isLoading: isCanceling } = useHandleCancelOrder(
    uid || '',
    activeTab || OrderStatusTab.ReadyToShip,
  );

  const { mutate: duplicateOrder, isLoading: isDuplicating } = useDuplicateOrder();

  ////! refactored const { data } = useOrderByUID( uid || '');

  if (orderData && resolveModalOpen && uid && orderData?.merchant_action_required) {
    setFullName(orderData?.customerName || '');
    setPhone(orderData.phone || '');
    setOrderValue(String(orderData?.orderValue || 0));
    setAddressTitle(orderData?.pickupLocation || '');
  }

  const orderMenuItems: { [key in OrderMenuItemsEnum]: MenuItemData } = {
    [OrderMenuItemsEnum.EDIT_ORDER]: {
      key: OrderMenuItemsEnum.EDIT_ORDER,
      label: <Heading title={t('edit')} leadingIcon={<EditOutline />} rotation={false} />,
      onClick: () => customNavigate(generatePath(ROUTE.ORDER_EDIT, { orderUid: uid })),
    },
    [OrderMenuItemsEnum.VIEW_ORDER]: {
      key: OrderMenuItemsEnum.VIEW_ORDER,
      label: (
        <Heading
          title={t('view')}
          leadingIcon={<EyeIcon stroke='#fff' fill='#fff' />}
          rotation={false}
        />
      ),
      onClick: () =>
        activeTab === OrderStatusTab.RTO
          ? customNavigate(`${ROUTE.ORDER_DETAILS_RTO}/${uid}`)
          : customNavigate(`${ROUTE.ORDER_DETAILS}/${uid}`),
    },
    [OrderMenuItemsEnum.CANCEL_ORDER]: {
      key: OrderMenuItemsEnum.CANCEL_ORDER,
      label: (
        <Heading
          title={cancelLabel ?? t('cancel')}
          leadingIcon={<DeleteIcon />}
          loading={isCanceling}
        />
      ),
      onClick: handleCancelOrder,
    },
    [OrderMenuItemsEnum.DISABLE_CANCEL_ORDER]: {
      key: OrderMenuItemsEnum.CANCEL_ORDER,
      label: (
        <Heading
          title={cancelLabel ?? t('cancel')}
          leadingIcon={<DeleteIcon />}
          disabled={true}
          parentClassName='cursor-not-allowed'
        />
      ),
      onClick: () => {},
    },
    [OrderMenuItemsEnum.DUPLICATE_ORDER]: {
      key: OrderMenuItemsEnum.DUPLICATE_ORDER,
      label: (
        <Heading
          title={t('duplicate_order')}
          leadingIcon={<DuplicateIcon />}
          loading={isDuplicating}
        />
      ),
      onClick: () => {
        duplicateOrder(uid || '', {
          onSuccess: () => {
            queryClient.invalidateQueries(['orders']);
          },
        });
      },
    },
  };

  const getCustomMenuItems = (customMenuItems?: CustomMenuItemData[]): ItemType[] => {
    const items: ItemType[] = [];
    customMenuItems?.forEach((item, index) => {
      items.push({
        key: item.key,
        label: (
          <Heading
            title={item.label}
            loading={item.loading}
            leadingIcon={item.leadingIcon}
            disabled={item.disabled}
          />
        ),
        onClick: item.onClick,
      });
      if (index !== customMenuItems.length - 1) items.push(dropDownDividerItem);
    });

    return items ?? [];
  };

  const customNavigate = (route: string) => {
    return navigate(route, {
      state: savePrevPage ? { prevPage: location.pathname + location.search } : undefined,
    });
  };

  const openResolveModal = () => {
    setResolveModalOpen(true);
  };

  const dropDownDividerItem: ItemType = {
    type: 'divider',
  };

  const getMenuItems = () => {
    const customItems = getCustomMenuItems(customMenuItems);
    const items: ItemType[] = [];
    if (menuItems && menuItems?.length > 0) {
      menuItems.forEach((item, index) => {
        const itemWithSeparator: ItemType[] =
          index === menuItems.length - 1
            ? [orderMenuItems[item] as ItemType]
            : [orderMenuItems[item] as ItemType, dropDownDividerItem];
        items.push(...itemWithSeparator);
      });
    }

    return [...items, ...customItems];
  };

  return (
    <Flex justify='end'>
      <Space>
        {button && (
          <Button
            type={button.label ? 'primary' : 'default'}
            className={`${
              button.label ? 'px-5 ' : 'border-solid w-[44px] px-1 border-customTunaPrimary'
            }`}
            onClick={
              button.navigateLink
                ? () => customNavigate(button.navigateLink || '')
                : button.onClick === 'openResolveModal'
                  ? openResolveModal
                  : button.onClick
            }
            disabled={button.disabled}
          >
            {button.label ? (
              <Heading
                title={button.label}
                leadingIcon={button.icon}
                fontSize='text-xs'
                fontWeight='font-bold'
                extraClassName='uppercase  leading-[20px]'
              />
            ) : (
              button.icon
            )}
          </Button>
        )}

        {(menuItems && menuItems?.length > 0) ||
        (customMenuItems && customMenuItems?.length > 0) ? (
          <Dropdown
            menu={{ items: getMenuItems() }}
            trigger={['click']}
            className='relative right-0'
          >
            <Flex className='flex justify-center cursor-pointer items-center w-[44px] h-[44px] rounded-lg border border-solid border-customTunaPrimary'>
              <MoreOutline width={24} height={24} />
            </Flex>
          </Dropdown>
        ) : null}

        <ResolveModal
          visible={resolveModalOpen}
          onClose={() => setResolveModalOpen(false)}
          orderId={uid}
          platformCode={orderData?.platform_code}
          comment={orderData?.merchant_action_required_text}
        />
      </Space>
    </Flex>
  );
};

export default ActionsTable;
