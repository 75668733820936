import { useRemittances, useTransactions } from '@/hooksApi';
import { FinanceTab, RemittanceRecord } from '@/types';
import { formatDateByDayjs } from '@/utils';

const useRemittanceData = (current: number, activeTab: FinanceTab, pageSize?: number) => {
  const { data, isLoading, isFetching } = useRemittances(
    current,
    pageSize,
    activeTab === FinanceTab.COD || activeTab === FinanceTab.Balance,
  );
  const {
    data: dataTransactions,
    isLoading: loadingTransaction,
    isFetching: fetchingTransaction,
  } = useTransactions(current, pageSize, activeTab === FinanceTab.Transactions);

  const dataRemittance: RemittanceRecord[] =
    data?.object?.items?.map((remittance) => ({
      key: remittance?.uid,
      date: formatDateByDayjs(remittance.created_at, 'D MMMM YYYY'),
      remittanceId: remittance?.platform_code,
      amount: `${remittance?.amount}`,
      status: remittance?.status?.title,
    })) || [];

  const dataRemittanceTransactions =
    dataTransactions?.object?.items?.map((transaction) => ({
      key: transaction?.uid,
      date: formatDateByDayjs(transaction?.created_at, 'D MMMM YYYY'),
      transactionId: transaction?.platform_code || '---',
      orderId:
        transaction.item_platform_code && transaction.item_platform_code.startsWith('BXO')
          ? transaction.item_platform_code
          : '---',
      type: transaction?.type,
      title: transaction?.subject,
      amount: transaction?.amount,
    })) || [];

  // totals
  const totals = data?.object?.total || 0;
  const totalTransactions = dataTransactions?.object?.total || 0;

  return {
    dataRemittance,
    totals,
    totalTransactions,
    isLoading,
    loadingTransaction,
    isFetching,
    fetchingTransaction,
    dataRemittanceTransactions,
  };
};

export default useRemittanceData;
