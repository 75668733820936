import React, { useEffect, useState } from 'react';
import { Checkbox, Col, Flex, Form, FormInstance, Row } from 'antd';

import { Card } from '../Card';
import { PackageSize } from '../PackageSize';
import { Heading } from '../Heading';
import { useTranslation } from 'react-i18next';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { BoxLargeIcon, BoxMediumIcon, BoxSmallIcon, BoxXLargeIcon } from '../Icons';

export type PackageSizeFormValues = 'S' | 'M' | 'L' | 'XL';
interface PackageSizeOption {
  key: PackageSizeFormValues;
  description: string;
  title: string;
  icon: React.FC; // Icon component for size
}

export enum PackageSizeEnum {
  S = 'small',
  M = 'medium',
  L = 'large',
  XL = 'x_large',
}

interface PackageSizeCardProps {
  form: FormInstance; // Accept form instance from parent
  onPackageSizeChange: (size: string) => void; // New prop to handle size change
  packageSizeProp?: string;
}

const PackageSizeEditCard: React.FC<PackageSizeCardProps> = ({
  form,
  onPackageSizeChange,
  packageSizeProp,
}) => {
  const [selectedSize, setSelectedSize] = useState<PackageSizeFormValues>('S');
  const [isFragileChecked, setIsFragileChecked] = useState(false);
  const { t } = useTranslation();

  const options: PackageSizeOption[] = [
    { title: 'small', key: 'S', description: t('up_to_one_kg'), icon: BoxSmallIcon },
    { title: 'medium', key: 'M', description: t('up_to_three_kg'), icon: BoxMediumIcon },
    { title: 'large', key: 'L', description: t('up_to_five_kg'), icon: BoxLargeIcon },
    { title: 'x_large', key: 'XL', description: t('up_to_*_kg'), icon: BoxLargeIcon },
  ];

  const handleSelect = async (selectedSize: PackageSizeFormValues) => {
    setSelectedSize(selectedSize);
    form.setFieldsValue({ packageSize: selectedSize });
    onPackageSizeChange(selectedSize);
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    setIsFragileChecked(e.target.checked);
  };

  // Sync the selected size when the form initializes or updates
  useEffect(() => {
    const initialSize: PackageSizeFormValues | undefined =
      packageSizeProp || form.getFieldValue('packageSize');

    if (initialSize) {
      setSelectedSize(initialSize);
      form.setFieldsValue({ packageSize: initialSize });
    }
  }, [form, packageSizeProp]);

  return (
    <Form.Item name='packageSize'>
      <Card>
        <Heading
          title={t('package_size')}
          fontSize='text-lg'
          fontWeight='font-bold'
          extraClassName='uppercase'
        />
        <Row gutter={[12, 8]} className='mt-3'>
          {options.map((option, index) => (
            <Col key={option.key} xxl={6} xl={8} lg={12} md={24} xs={24}>
              <PackageSize
                packageSizeKey={option.key}
                title={option.title}
                description={option.description}
                icon={option.icon}
                selectedValue={selectedSize}
                onSelect={handleSelect} // Call handleSelect to update state and form
              />
            </Col>
          ))}
        </Row>
        <Flex className='flex items-center gap-x-2 mt-4'>
          <Form.Item
            initialValue={isFragileChecked} // Set the initial value
            className='mt-[6px] mb-0'
            name='isFragile'
            valuePropName='checked'
          >
            <Checkbox
              checked={form.getFieldValue('isFragile')}
              onChange={handleCheckboxChange}
              className='custom-checkbox'
            >
              <Heading
                title={t('package_content_is_fragile')}
                fontSize='text-base'
                fontWeight='font-normal'
                extraClassName='leading-[20.48px]'
              />
            </Checkbox>
          </Form.Item>
        </Flex>
      </Card>
    </Form.Item>
  );
};

export default PackageSizeEditCard;
