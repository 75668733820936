import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd';
import PackageSizeCard from './PackageSizeCard';
import { PaymentCard } from './PaymentCard';
import { PaymentType, Preview, ShipmentFeeType } from '@/types';

interface PackageAndPaymentCardProps {
  form: FormInstance;
  onPackageSizeChange: (size: string) => void;
  orderPrice: Preview;
  orderPriceLoading: boolean;
  paymentType?: PaymentType;
  shipmentFeeType?: ShipmentFeeType;
  packageSize?: string;
  onCustomShipmentFeeChange: (shipmentFee?: number) => void;
  customShipmentFee?: number;
}
const PackageAndPaymentCard: React.FC<PackageAndPaymentCardProps> = ({
  form,
  onPackageSizeChange,
  orderPrice,
  orderPriceLoading,
  paymentType,
  shipmentFeeType,
  packageSize,
  onCustomShipmentFeeChange,
  customShipmentFee,
}) => {
  return (
    <>
      <PackageSizeCard
        form={form}
        packageSizeProp={packageSize}
        onPackageSizeChange={onPackageSizeChange}
      />

      <PaymentCard
        form={form}
        orderPrice={orderPrice}
        orderPriceLoading={orderPriceLoading}
        paymentType={paymentType}
        shipmentFeeType={shipmentFeeType}
        onCustomShipmentFeeChange={onCustomShipmentFeeChange}
        customShipmentFee={customShipmentFee}
      />
    </>
  );
};

export default PackageAndPaymentCard;
