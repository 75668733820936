import React from 'react';
import { Col, Flex, Form, Select, Typography } from 'antd';

const { Option } = Select;
const { Text } = Typography;

interface DataItem {
  uid: string; // Add the uid field
  title: string;
  description: string;
}

interface CustomSelectProps {
  data: DataItem[];
  title?: string;
  formItemName: string;
  placeholder?: string;
  titleStyle?: string;
  parentStyle?: string;
  isLoading?: boolean;
  onSelect?: (uid: string) => void;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  data,
  title,
  titleStyle,
  placeholder,
  parentStyle,
  isLoading,
  onSelect,
  formItemName,
}) => {
  const handleChange = (value: string) => {
    const selectedItem = data.find((item) => item.title === value);
    if (selectedItem && onSelect) {
      onSelect(selectedItem?.uid);
    }
  };

  return (
    <Flex vertical className={`${parentStyle}`}>
      <Text className={`${titleStyle} mt-1`}>{title}</Text>
      <Form.Item name={formItemName}>
        <Select
          loading={isLoading}
          onChange={handleChange}
          variant='borderless'
          placeholder={placeholder}
          className='border px-[5px]  border-solid w-full h-[81px] custom-select-placeholder custom-select-item border-customGrayShade4 rounded-[10px]'
        >
          {data.map((item, index) => (
            <Option key={index} value={item.uid}>
              <Col>
                <Text className='font-bold text-lg leading-[20.88px]  text-customTunaPrimary'>
                  {item.title}
                </Text>
                <br />
                <Text className='font-normal text-base leading-[20.48px]  text-customTunaPrimary'>
                  {item.description}
                </Text>
              </Col>
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Flex>
  );
};

export default CustomSelect;
