import { Status } from './order';
import { PickupAddress } from './pickupAddress';

export enum PickupPageTabEnum {
  Pickups = 'pickups',
  RTO = 'rto',
}

// Define the object structure of the response
export interface PickupObject {
  items: PickupItem[];
  page: number;
  total: number;
  pages: number;
  perPage: number;
}

// Define the full response structure
export interface PickupResponse {
  status: string;
  code: number;
  message: string;
  object: PickupObject;
}

export interface PickupItem {
  uid: string;
  platform_code: string;
  date: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  driver: DriverItem | null;
  status: Status;
  pick_up_address: PickupAddress;
  task: string | null;
}

export interface DriverItem {
  uid: string;
  first_name: string;
  last_name: string;
  phone: string;
  email: string | null;
  type: string;
  last_position?: {
    lat: string;
    lng: string;
  };
}

export interface PickupIdRequest {
  id: string;
}

export interface PickUpParams {
  saved_pick_up_address_uid?: string;
  date?: string;
  order_uids: string[];
}

export interface PickupDataResponse {
  status: string;
  code: number;
  message: string;
}

export interface PickUpSingleResponse {
  status: string;
  code: number;
  message: string;
  object: {
    uid: string;
    platform_code: string;
    date: string | null;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    orders_count: number;
    shipment_fee: number;
    order_value: number;
    driver: DriverItem | null;
    total_price: number;
    status: Status;
    task: {
      lat: string;
      lng: string;
    };
  };
}

export interface PickupRequest {
  full_name?: string;
  phone?: string;
  address_text?: string;
  lat?: string;
  lng?: string;
  email?: string;
  type?: string;
  default?: boolean;
  region_uid?: string;
  description?: string;
}
