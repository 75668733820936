import { Col, Divider, Flex, Row } from 'antd';
import { Heading } from '../Heading';
import { Card } from '../Card';
import { useTranslation } from 'react-i18next';
import { CustomSettingsCard } from '.';
import { BellIcon, CustomerNotificationIcon } from '../Icons';
import { THEME_VARIABLE } from '@/config';
import { useCrisp } from '@/hooks';
import { SettingSection } from '@/pages/settings';

interface Props {
  selectedSection: SettingSection;
}

const HelpAndSupport = ({ selectedSection }: Props) => {
  const { t } = useTranslation();
  const { showCrispChat, triggerHelpdeskSearch } = useCrisp();
  return (
    <Flex vertical className='mt-6'>
      <Heading
        title={t('help_support')}
        fontSize='text-lg'
        fontWeight='font-bold'
        extraClassName='uppercase leading-[20.88px]'
      />
      <Card extraClassName='mt-3 px-12'>
        <Row gutter={[24, 16]}>
          <Col lg={24} md={24} xs={24}>
            <CustomSettingsCard
              selectedSection={selectedSection}
              IconLeft={
                <BellIcon width={24} height={24} color={THEME_VARIABLE.customTunaPrimary} />
              }
              title={t('live_chat')}
              description={t('customize_your_communication')}
              onclick={showCrispChat}
            />

            <Divider />
            <CustomSettingsCard
              selectedSection={selectedSection}
              IconLeft={
                <CustomerNotificationIcon
                  width={24}
                  height={24}
                  color={THEME_VARIABLE.customTunaPrimary}
                />
              }
              onclick={triggerHelpdeskSearch}
              title={t('faq')}
              description={t('find_answers_to_common')}
            />
          </Col>
        </Row>
      </Card>
    </Flex>
  );
};

export default HelpAndSupport;
