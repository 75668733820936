import { Flex, Space } from 'antd';
import { PickupIcon, RightIcon } from '../Icons';
import { Heading } from '../Heading';
import { useTranslation } from 'react-i18next';
// import { PriceWithUnit } from '../Price';
import { THEME_VARIABLE } from '@/config';

interface ProductDetailsProps {
  value?: string;
  title?: string;
  sectionTitle?: string;
}

const ProductDetails = ({ value, title, sectionTitle }: ProductDetailsProps) => {
  const { t } = useTranslation();
  const dataTitle: string = title || t('order_pickup');

  return (
    <Flex vertical className='w-full mt-8'>
      {sectionTitle && <Heading title={sectionTitle} fontSize='text-lg' fontWeight='font-bold' />}

      <Space
        className='w-full bg-customGrayShade1 p-3 rounded-[10px] flex justify-between'
        align='center'
      >
        <Heading
          leadingIcon={
            <PickupIcon
              fillColor={THEME_VARIABLE.customGrayShade5}
              width={24}
              height={24}
              className='w-4 h-5'
            />
          }
          title={dataTitle}
        />
        <Heading title={value || '----'} trailingIcon={<RightIcon />} fontWeight='font-bold' />
      </Space>
      {/* <Flex className='w-full p-3' align='center' justify='space-between'>
        <Heading
          leadingIcon={
            <CashIcon
              color={THEME_VARIABLE.customGrayShade5}
              width={19}
              height={18}
              className='mt-1'
            />
          }
          title={t('cash_to_receive')}
        />
        <Flex align='center' className='gap-x-2'>
          <PriceWithUnit
            color={THEME_VARIABLE.customTunaPrimary}
            value={15000}
            subTitle={t('IQD')}
            fontSize='14px'
            fontSizeSub='text-sm'
            fontWeight='font-normal'
            fontWeightSub='font-medium'
          />
          <Heading title='' trailingIcon={<RightIcon />} />
        </Flex>
      </Flex>
      <Divider className='my-2' />
      <Flex className='w-full p-3' align='center' justify='space-between'>
        <Heading
          leadingIcon={
            <ReciveReturnIcon fillColor={THEME_VARIABLE.customGrayShade5} width={14} height={14} />
          }
          title={t('returns_to_receive')}
        />
        <Heading title='8' trailingIcon={<RightIcon />} fontWeight='font-bold' />
      </Flex> */}
    </Flex>
  );
};

export default ProductDetails;
