import { useEffect } from 'react';
import {
  Col,
  Flex,
  Radio,
  RadioChangeEvent,
  Row,
  Typography,
  Form,
  FormInstance,
  Input,
} from 'antd';
import { Card } from '../Card';
import { Heading } from '../Heading';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddNewLocationModal } from '../AddNewLocationModal';
import { LOCAL_EN } from '@/config';
import { useFetchPickupAddresses } from '@/hooksApi/usePickupAddress';
import { Store } from 'antd/es/form/interface';
import { getWarehouseAddressType } from '@/utils';
import { CustomSelect } from '../CustomSelect';
import { PickupAddress } from '@/types';
import { CustomRadioButton } from '../CustomRadioButton';
const { Text } = Typography;

interface PickupAddressCardProps {
  form: FormInstance;
  orderStatus?: string;
  previewOrderPrice: (orderPayload: Store) => void;
}

const PickupAddressCard = ({ form, previewOrderPrice, orderStatus }: PickupAddressCardProps) => {
  const [selectedValue, setSelectedValue] = useState('PICK_UP');
  const [addNewLocationModalOpen, setAddNewLocationModalOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const handleRadioChange = (e: RadioChangeEvent) => {
    setSelectedValue(e.target.value);
    form.setFieldsValue({ saved_pick_up_address_uid: '' });
  };

  const { data: pickupAddresses, isLoading: pickupAddressLoading } = useFetchPickupAddresses();

  const typedSelectData: PickupAddress[] = getWarehouseAddressType(pickupAddresses?.object?.items);

  useEffect(() => {
    const initPickupType = form.getFieldValue('pickupOrDropoff') ?? 'PICK_UP';
    if (initPickupType) {
      form.setFieldsValue({
        pickupOrDropoff: initPickupType,
      });
      setSelectedValue(initPickupType);
    }
  }, [form]);

  useEffect(() => {
    if (!orderStatus) {
      // if !orderStatus so it's not editing
      const saved_pick_up_address_uid = form.getFieldValue('saved_pick_up_address_uid');

      if (typedSelectData.length > 0 && !saved_pick_up_address_uid) {
        const defaultAddress = typedSelectData.find((item) => !!item.default)?.uid;
        form.setFieldsValue({ saved_pick_up_address_uid: defaultAddress });
      }
    }
  }, [typedSelectData, form]);

  const handleSelectPickupAddress = (uid: string) => {
    form.setFieldsValue({ saved_pick_up_address_uid: uid });
    const formValues = form.getFieldsValue();

    previewOrderPrice({ ...formValues, saved_pick_up_address_uid: uid });
  };

  return (
    <Card>
      <Flex>
        <Heading
          title={t('pickup_dropoff')}
          fontSize='text-lg'
          fontWeight='font-bold'
          extraClassName='uppercase'
        />
        <Text className='text-colorError text-2xl mx-1'>*</Text>
      </Flex>
      <Form.Item name='pickupOrDropoff'>
        <Radio.Group className='w-full' value={selectedValue} onChange={handleRadioChange}>
          <Row gutter={[24, 16]} className='mt-[19.5px]'>
            <Col lg={12} md={24} xs={24}>
              <CustomRadioButton
                size='lg'
                value={'PICK_UP'}
                selectedValue={selectedValue}
                label={t('boxy_collects')}
                className='h-[81px] normal-case'
              />
            </Col>
            {/* V0 remove */}
            {/* <Col lg={12} md={24} xs={24}>
              <Flex
                className={`h-[81px] ${
                  selectedValue === 'DROP_OFF'
                    ? 'bg-customYellowPrimary border border-solid border-customTunaPrimary'
                    : 'border border-solid border-customGrayShade4'
                } rounded-[10px] flex items-center justify-start pl-5`}
              >
                <Radio
                  value='DROP_OFF'
                  className={`custom-radio ${
                    selectedValue === 'DROP_OFF'
                      ? 'text-customTunaPrimary'
                      : 'text-customGrayShade5'
                  } flex items-center font-bold text-lg leading-[20.88px]`}
                >
                  {t('you_drop_off')}
                </Radio>
              </Flex>
            </Col> */}
          </Row>
        </Radio.Group>
      </Form.Item>

      {selectedValue === 'PICK_UP' ? (
        <Row>
          <Col lg={24} md={24} xs={24}>
            {typedSelectData.length !== 0 ? (
              <>
                {!orderStatus || orderStatus === 'new' ? (
                  <CustomSelect
                    formItemName='saved_pick_up_address_uid'
                    placeholder={t('select_pickup')}
                    isLoading={pickupAddressLoading}
                    data={typedSelectData.map((item) => ({
                      ...item,
                      description: item.address_text,
                    }))}
                    title={t('boxy_agent_will_collect')}
                    titleStyle='font-normal text-base leading-[20.48px] text-customTunaPrimary mb-[14px]'
                    parentStyle='mt-3'
                    onSelect={handleSelectPickupAddress}
                  />
                ) : (
                  <>
                    <Form.Item name='saved_pick_up_address_uid' />
                    <Text>{t('pickup_location')}</Text>
                    <Form.Item name='freezed_pick_up_address_title'>
                      <Input className='h-12' disabled />
                    </Form.Item>
                  </>
                )}
              </>
            ) : (
              <Flex vertical>
                <Text className='font-normal text-base my-3 leading-[20.48px] text-customTunaPrimary'>
                  {t('boxy_agent_will_collect')}
                </Text>
                <Flex
                  className={`rounded-[10px] gap-x-1 py-[30px] ${
                    i18n.language === LOCAL_EN ? 'pl-4' : 'pr-4'
                  } pl-4 border border-solid border-customGrayShade4`}
                >
                  <Text className='font-normal text-base leading-[20.48px] text-customTunaPrimary'>
                    {t('there_is_no_address_in_your_list')}
                  </Text>
                  <Text
                    onClick={() => {
                      setAddNewLocationModalOpen((prev) => !prev);
                      // clearPickupAddresses();
                    }}
                    className='font-normal cursor-pointer text-base leading-[20.48px] capitalize text-customBlue'
                  >
                    {t('address_list')}
                  </Text>
                </Flex>
              </Flex>
            )}
          </Col>
        </Row>
      ) : (
        <Row className='mt-3'>
          <Col lg={24} md={24} xs={24}>
            <Text className='font-normal text-base leading-[20.48px] text-customTunaPrimary mb-[14px]'>
              {t('you_drop_off_your_packages')}
            </Text>
          </Col>
        </Row>
      )}
      <AddNewLocationModal
        visible={addNewLocationModalOpen}
        onClose={() => setAddNewLocationModalOpen(false)}
        updatePreviewOrderPrice={(addressUid?: string) =>
          addressUid && handleSelectPickupAddress(addressUid)
        }
      />
    </Card>
  );
};

export default PickupAddressCard;
