export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then(function (registration) {
          console.log('Service Worker registered with scope:', registration.scope);
          askNotificationPermission();
        })
        .catch(function (error) {
          console.log('Service Worker registration failed:', error);
        });
    });
  }
};

export const askNotificationPermission = () => {
  if ('Notification' in window) {
    Notification.requestPermission().then(function (permission) {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
      } else {
        console.log('Notification permission denied.');
      }
    });
  }
};
