import { fetchLabelRTO_CSV, fetchLabelRTO_PDF } from '@/api';
import {
  BottomSheet,
  Card,
  DownloadIcon,
  DriverInfo,
  Heading,
  MapComponent,
  OrderStatus,
  PaymentStatus,
  PDFIcon,
  ProductDetails,
} from '@/components';
import { ROUTE } from '@/config';
import { useFetchSingleRTOById } from '@/hooksApi';
import { downloadFile, isValidLatLng } from '@/utils';
import { Col, Flex, Layout, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const RTOSingle = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  // const { mutate: CancelRTOById, isLoading: cancelRTOLoading } = useCancelRTOById();

  const { data: dataSingleRTO, isLoading: loadingSingleRTO } = useFetchSingleRTOById(
    id as string,
    600000,
  );

  const items = [
    {
      title: t('rto-scheduled'),
      // description: '12:23 PM',
    },
    {
      title: t('rto-in-transit'),
    },
    {
      title: t('rto-delivered'),
    },
  ];

  const getStatusActiveRTO = (active: string): number => {
    switch (active) {
      case 'new':
        return -1;
      case 'scheduled':
        return 0;
      case 'out-for-delivery':
        return 1;
      case 'delivered':
      case 'rto-partially-delivered':
        return 2;
      default:
        return 0;
    }
  };

  const getRTOMenuItems = (uid: string) => {
    return [
      {
        key: '1',
        label: 'PDF',
        onClick: async () => {
          // Handle PDF download
          await downloadFile(uid, fetchLabelRTO_PDF, `RTO_${uid}.pdf`);
        },
        leadingIcon: <PDFIcon />,
      },
      {
        key: '2',
        label: 'CSV',
        onClick: async () => {
          // Handle CSV download
          await downloadFile(uid, fetchLabelRTO_CSV, `RTO_${uid}.csv`);
        },
        leadingIcon: <DownloadIcon color='#939496' />,
      },
    ];
  };

  if (loadingSingleRTO) return <Spin />;

  return (
    <Layout className='relative h-fit'>
      <Row justify='start' align='middle'>
        <Heading
          routeLink={ROUTE.RTO_LIST}
          title={t('rto_details')}
          fontSize='text-2xl'
          fontWeight='font-bold'
        />
      </Row>

      <Row gutter={[24, 16]} className='mt-6 mb-32'>
        {/* Map */}
        {isValidLatLng(dataSingleRTO?.object.task?.lat, dataSingleRTO?.object.task?.lng) && (
          <Col md={14}>
            <Flex vertical className={`max-h-[28rem] rounded-[10px] shadow-card-dark`}>
              <MapComponent
                targetLocation={
                  dataSingleRTO?.object.task
                    ? {
                        latitude: Number(dataSingleRTO?.object.task.lat),
                        longitude: Number(dataSingleRTO?.object.task.lng),
                      }
                    : undefined
                }
                driverLocation={
                  dataSingleRTO?.object.driver?.last_position &&
                  isValidLatLng(
                    dataSingleRTO?.object.driver?.last_position.lat,
                    dataSingleRTO?.object.driver?.last_position.lng,
                  )
                    ? {
                        latitude: Number(dataSingleRTO?.object.driver?.last_position.lat),
                        longitude: Number(dataSingleRTO?.object.driver?.last_position.lng),
                      }
                    : undefined
                }
              />
            </Flex>
          </Col>
        )}

        {/*End of Map */}

        {/* ORDERS STATUS And Payment */}
        <Col md={10}>
          <Flex vertical justify='space-between h-full flex flex-col'>
            <Card extraClassName='px-0 py-6 mb-5'>
              <OrderStatus
                statusMessage={t(dataSingleRTO?.object?.status?.title || 'new')}
                items={items}
                current={getStatusActiveRTO(dataSingleRTO?.object?.status?.title || 'new')}
              />
            </Card>
            <Card>
              <PaymentStatus
                headerTitle={t('payment')}
                titleFirst={t('order_value')}
                titleSecond={t('shipment_fee')}
                total={Number(dataSingleRTO?.object?.total_price) || 0}
                valueFirst={Number(dataSingleRTO?.object?.order_value) || 0}
                valueSecond={Number(dataSingleRTO?.object?.shipment_fee) || 0}
              />
            </Card>
          </Flex>
        </Col>
        {/* End of ORDERS STATUS And Payment */}

        <Col md={14} xs={24} className='mb-16'>
          <Card>
            <DriverInfo
              assign={dataSingleRTO?.object.driver === null ? false : true}
              driver={dataSingleRTO?.object.driver}
            />
          </Card>
          <Card>
            <ProductDetails
              sectionTitle={t('rto_details')}
              title={t('Orders_Drop_Off')}
              value={String(dataSingleRTO?.object?.orders_count)}
            />
          </Card>
        </Col>
      </Row>
      <Row className='sticky bottom-4'>
        <BottomSheet
          isVisible
          withoutButton
          showRecords={false}
          menuItems={getRTOMenuItems(dataSingleRTO?.object.uid ?? '')}
        />
      </Row>
    </Layout>
  );
};

export default RTOSingle;
