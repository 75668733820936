import { axiosJSONInstance } from '@/config';
import { ResponseType, FileList } from '@/types';

export const fetchExportedFiles = async (): Promise<ResponseType<FileList>> => {
  const response = await axiosJSONInstance.get(`/merchants/orders/exports`);
  return response.data;
};

export const exportFilesAsync = async (query: string): Promise<ResponseType<null>> => {
  const response = await axiosJSONInstance.post(`/merchants/orders/exports?${query}`);
  return response.data;
};
