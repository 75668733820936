import { Flex, Menu, MenuProps } from 'antd';
import { Heading } from '../Heading';
import { useTranslation } from 'react-i18next';
import { CustomSettingsCard } from '.';
import {
  CompanyIcon,
  EnvironmentIcon,
  FileIcon,
  KeyOutlineIcon,
  LinkOutline,
  TeamIcon,
} from '../Icons';
import { THEME_VARIABLE } from '@/config';
import { SettingSection } from '@/pages/settings';

interface Props {
  selectedSection: SettingSection;
  handleMenuClick: MenuProps['onClick'];
}
const AccountCard = ({ handleMenuClick, selectedSection }: Props) => {
  const { t } = useTranslation();

  return (
    <Flex vertical>
      <Heading
        title={t('account')}
        upperCase
        fontSize='text-lg'
        fontWeight='font-bold'
        extraClassName='leading-[20.88px]'
      />
      <Menu
        className='py-4 px-5 rounded-lg shadow-card-dark mt-2'
        mode='vertical'
        selectedKeys={[selectedSection]}
        onClick={handleMenuClick}
      >
        <Menu.Item key={SettingSection.Profile} className='h-[64px] '>
          <CustomSettingsCard
            IconLeft={
              <CompanyIcon width={24} height={24} color={THEME_VARIABLE.customTunaPrimary} />
            }
            title={t('profile')}
            description={t('manage_your_personal')}
            extraClassName='h-[64px] rounded-4'
            borderBottomStyle={`1px solid ${THEME_VARIABLE.customGrayShade3}`}
            selectedSection={selectedSection}
            sectionName={SettingSection.Profile}
          />
        </Menu.Item>
        <Menu.Item key={SettingSection.Location} className='h-[64px]'>
          <CustomSettingsCard
            IconLeft={
              <EnvironmentIcon width={24} height={24} color={THEME_VARIABLE.customTunaPrimary} />
            }
            title={t('locations')}
            description={t('manage_your_addresses')}
            extraClassName='h-[64px] '
            selectedSection={selectedSection}
            sectionName={SettingSection.Location}
          />
        </Menu.Item>
        {/* <Menu.Item key={SettingSection.TeamMembers} className='h-[64px]'>
          <CustomSettingsCard
            IconLeft={<TeamIcon width={24} height={24} color={THEME_VARIABLE.customTunaPrimary} />}
            title={t('team_members')}
            description={t('manage_your_team_member')}
            extraClassName='h-[64px] '
            selectedSection={selectedSection}
            sectionName={SettingSection.TeamMembers}
          />
        </Menu.Item> */}
        <Menu.Item key={SettingSection.File} className='h-[64px]'>
          <CustomSettingsCard
            IconLeft={<FileIcon width={24} height={24} color={THEME_VARIABLE.customTunaPrimary} />}
            title={t('file')}
            // description={t('manage_your_addresses')}
            extraClassName='h-[64px] '
            selectedSection={selectedSection}
            sectionName={SettingSection.File}
          />
        </Menu.Item>
        {/* V0 remove */}
        {/* <Menu.Item key='teamMembers' className='h-[64px]'>
      <CustomSettingsCard
        IconLeft={
          <TeamIcon width={24} height={24} color={THEME_VARIABLE.customTunaPrimary} />
        }
        title={t('team_members')}
        description={t('manage_your_team_member')}
        extraClassName='h-[64px] '
        leftIconBackgroundColor={
          selectedSection === 'teamMembers' ? 'bg-customYellowPrimary' : 'bg-customGrayShade2'
        }
        descriptionColor={
          selectedSection === 'teamMembers' ? 'text-customTunaPrimary' : 'text-customGrayShade5'
        }
      />
    </Menu.Item> */}
      </Menu>
    </Flex>
  );
};

export default AccountCard;
