import { Layout, Select, Space, Typography } from 'antd';
import { useSelectStore } from '@/stores';
import MixBarChart from './MixBarChart';
import { InformationIcon } from '../Icons';
import { useTranslation } from 'react-i18next';
import { Heading } from '../Heading';

const { Option } = Select;
const { Text } = Typography;

const OrderPerformanceByChart = () => {
  const { selectedValue, setSelectedValue } = useSelectStore();
  const { t } = useTranslation();

  const handleChange = (value: string) => {
    setSelectedValue(value);
  };

  return (
    <Layout className='p-6 flex h-full justify-between bg-white rounded-[10px] min-h-96 shadow-card-dark'>
      <Space className='flex justify-between items-start mb-4'>
        <Heading
          trailingIcon={<InformationIcon />}
          textTooltip='test'
          title={t('order_performance')}
          level={3}
          extraClassName='mb-0'
        />
        <Select defaultValue={selectedValue} variant='borderless' onChange={handleChange}>
          <Option value='last_week'>Last Week</Option>
          <Option value='last_month'>Last Month</Option>
          <Option value='last_year'>Last Year</Option>
        </Select>
      </Space>
      <Space align='center' className='flex space-x-4 items-center my-6'>
        <Space className='flex items-center'>
          <Text className='inline-block w-4 h-4 bg-customYellowPrimary rounded-md mr-2'></Text>
          <Text className='text-gray-600 text-sm'>{t('delivered')}</Text>
        </Space>
        <Space className='flex items-center'>
          <Text className='inline-block w-4 h-4 bg-customTunaPrimary rounded-md mr-2'></Text>
          <Text className='text-gray-600 text-sm'>{t('returned')}</Text>
        </Space>
      </Space>
      <MixBarChart />
    </Layout>
  );
};

export default OrderPerformanceByChart;
