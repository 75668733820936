import { Col, Form, Input, Row, Typography } from 'antd';
import { Heading } from '../Heading';
import { Card } from '../Card';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const OrderIdCard = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <Heading
        title={t('order_id')}
        fontSize='text-lg'
        fontWeight='font-bold'
        extraClassName='uppercase'
      />
      <Text className='font-normal text-base leading-[20.48px]'>{t('if_you_do_not')}</Text>
      <Row>
        <Col lg={24} md={24} xs={24}>
          <Form.Item name='orderId'>
            <Input
              autoComplete='off'
              variant='borderless'
              className='h-[56px] rounded-[10px] border mt-4 border-solid  border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px]'
              placeholder={t('order_id_custom')}
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default OrderIdCard;
