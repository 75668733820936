import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import usePickupData from './usePickupData';
import { getColumns } from '@/pages/pickups/list.tsx';

/**
 * Custom hook to manage Pickup tab data and columns
 * @param current Current page number
 * @param pageSize Size of each page
 * @param enabled Whether the tab is currently active
 * @param navigate Navigation function
 * @param queryClient Query client instance
 */
const usePickupTab = (
  current: number,
  pageSize: number,
  enabled = true,
  navigate: NavigateFunction,
  queryClient: QueryClient,
) => {
  const { t } = useTranslation();

  const { dataPickup, totals, isFetching } = usePickupData(current, pageSize, enabled);

  // Get columns for Pickup tab
  const columns = getColumns(t, navigate, queryClient);

  return {
    data: dataPickup,
    totals,
    isFetching,
    columns,
  };
};

export default usePickupTab;
