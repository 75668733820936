import { Col, Flex, message, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Card } from '../../Card';
import { Heading } from '../../Heading';
import { CopyIcon, DeleteIcon } from '@/components/Icons';
import { useDeleteApiToken } from '@/hooksApi';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  name: string;
  apiKey: string;
}
const AccessTokenItem = ({ name, apiKey }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate: deleteApiToken } = useDeleteApiToken();

  const handleCopy = () => {
    if (apiKey) {
      navigator.clipboard.writeText(apiKey);
      message.success(t('copy'));
    }
  };

  const handleDeleteToken = () => {
    if (apiKey)
      deleteApiToken(apiKey, {
        onSuccess: () => {
          queryClient.invalidateQueries(['api-token']);

          message.success(t('operation_completed_successfully'));
        },
      });
  };

  return (
    <Card>
      <Row>
        <Col lg={7} md={7} xs={24}>
          <Heading fontWeight='font-bold' title={name} level={5} />
        </Col>
        <Col lg={13} md={13} xs={24}>
          <Flex vertical gap={12}>
            <Flex vertical gap={6}>
              <Typography.Text className='whitespace-nowrap text-base font-light text-customTunaPrimary'>
                {t('api_key') + ': '}
              </Typography.Text>
              <Typography.Text className='text-base font-light text-customGrayShade5'>
                {apiKey ?? '---'}
              </Typography.Text>
            </Flex>
            <Flex vertical gap={6}>
              <Typography.Text className='whitespace-nowrap text-base font-light text-customTunaPrimary'>
                {t('api_secret') + ': '}
              </Typography.Text>
              <Typography.Text className='text-base font-light text-customGrayShade5'>
                {'****************************************************'}
              </Typography.Text>
            </Flex>
          </Flex>
        </Col>
        <Col lg={4} md={4} xs={24}>
          <Flex gap={16} className='h-full items-center' justify='center'>
            <CopyIcon width={24} height={24} className='cursor-pointer' onClick={handleCopy} />

            <DeleteIcon
              width={24}
              height={24}
              color='#38383E'
              className='cursor-pointer'
              onClick={handleDeleteToken}
            />
          </Flex>
        </Col>
      </Row>
    </Card>
  );
};

export default AccessTokenItem;
