import { useEffect, useState } from 'react';
import { Col, Layout, MenuProps, Row } from 'antd';
import TabBar, { TabItem } from '@/components/Tab/TabBar';
import { useTranslation } from 'react-i18next';
import { FinanceTab } from '@/types/columns';
import Balance from './balance';
import { BottomSheet, ExportIcon, Heading, InvoiceIcon, TableAction } from '@/components';
import { getColumns } from './list';
import { useSearchParams } from 'react-router-dom';
import useRemittanceData from '@/hooks/useRemittanceData';
import { scrollTop } from '@/utils';

const pageSize = 25;

const Finance = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  // dropdown items bottomsheet
  const menuItems: MenuProps['items'] = [
    {
      key: '1',
      label: <Heading title={t('export_csv')} leadingIcon={<ExportIcon />} rotation={false} />,
    },
  ];

  // handle active checkbox Item
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  // pagination
  const [currentPage, setCurrentPage] = useState(1);

  // find active tab
  const [activeTab, setActiveTab] = useState<FinanceTab>(
    searchParams.get('activeTab') ? FinanceTab.COD : FinanceTab.Balance,
  );

  // fetch data
  const {
    dataRemittance,
    fetchingTransaction,
    isFetching,
    totals,
    totalTransactions,
    dataRemittanceTransactions,
  } = useRemittanceData(currentPage, activeTab, pageSize);

  // tabs value
  const tabItems: TabItem[] = [
    {
      key: FinanceTab.Balance,
      title: t('balance'),
    },
    {
      key: FinanceTab.COD,
      title: t('cod_remittances'),
    },
    {
      key: FinanceTab.Transactions,
      title: t('transactions'),
    },
  ];

  // handle onChange pagination
  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [activeTab]);

  useEffect(() => {
    scrollTop();
  }, [currentPage]);

  return (
    <Layout className='overflow-hidden relative w-full h-fit'>
      <Row className='w-full my-6'>
        {/* Start TabBar */}
        <Col md={24} xs={24}>
          <TabBar activeTab={activeTab} setActiveTab={setActiveTab as any} tabItems={tabItems} />
        </Col>
        {/* End TabBar */}

        {/* Start Content */}
        <Col md={24} xs={24} className='mt-6 mb-48'>
          {/* should render balance or tables */}
          {activeTab === FinanceTab.Balance ? (
            <Balance setActiveTab={setActiveTab} />
          ) : (
            <TableAction
              columns={getColumns(activeTab, t)}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              data={FinanceTab.COD === activeTab ? dataRemittance : dataRemittanceTransactions}
              pageSize={pageSize}
              current={currentPage}
              total={FinanceTab.COD === activeTab ? totals : totalTransactions}
              loading={FinanceTab.Transactions === activeTab ? fetchingTransaction : isFetching}
              onPaginationChange={handlePaginationChange}
              rowSelectionEnabled={false}
            />
          )}
        </Col>
        {/* End Content */}
      </Row>
      {/* Start bottomSheet */}
      {FinanceTab.Transactions === activeTab && (
        <BottomSheet
          isVisible={selectedRowKeys.length > 0}
          numberSelected={selectedRowKeys.length}
          total={totals}
          labelBtn={`${t('export_csv')} (11)`}
          Icon={<InvoiceIcon />}
          menuItems={menuItems}
        />
      )}
      {/* End bottomSheet */}
    </Layout>
  );
};

export default Finance;
