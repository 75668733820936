import React from 'react';
import { Flex, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import EditOrderForm from './EditOrderForm';

interface ModalProps {
  editingOrderUid?: string;
  onClose: () => void;
}

const EditOrderModal: React.FC<ModalProps> = ({ editingOrderUid, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      className='rounded-[20px] bg-white border border-customGrayShade3'
      title={
        <Flex
          className='font-bold text-lg leading-5 text-customTunaPrimary '
          style={{ borderBottom: '1px solid #E1E1E3', paddingBottom: '20px' }}
        >
          {t('edit')}
        </Flex>
      }
      width={760}
      centered
      footer={null}
      open={!!editingOrderUid}
      onOk={onClose}
      onCancel={onClose}
      destroyOnClose
    >
      {editingOrderUid && <EditOrderForm onClose={onClose} editingOrderUid={editingOrderUid} />}
    </Modal>
  );
};

export default EditOrderModal;
