import {
  useFetchListOrderReady,
  useFetchListInprogress,
  useFetchListOnHold,
  useFetchListFinished,
  useFetchListByStatusSlug,
  useFetchListRTO,
  useFetchOrderEnRoute,
  useFetchOrderCollecting,
  useFetchReturningOrigin,
  useFetchReturningWarehouse,
  useFetchActionRequiredOrderList,
  useFetchListRtoRequest,
} from '@/hooksApi/useOrder';
import { OrderStatusTab } from '@/types/columns.ts';
import { OrderListResponse, Product } from '@/types';
import { formatDateByDayjs, getOrderPickupAddress } from '@/utils';
import { ISearch } from './useSearchStore';
import { UseQueryResult } from '@tanstack/react-query';
import { MerchantTitleOrderStatusEnum, OrderStatusEnum } from '@/config';

export interface OrderStatus {
  slug: OrderStatusEnum;
  merchantTitle: MerchantTitleOrderStatusEnum;
}
export interface OrderDataType {
  key: string;
  orderDate: string;
  orderId: string;
  customerName?: string;
  customerAddress?: string;
  orderValue: number;
  platform_code: string;
  custom_id: string;
  status: OrderStatus;
  pickupLocation: string;
  pickupAddressId: string | null;
  products: Product[];
  driver: {
    phone: string | undefined;
    assign: boolean;
  };
  merchant_action_required: number;
  merchant_action_required_text: string;
  phone: string;
}

type FetchDataFunction = (
  page: number,
  pageSize?: number,
  enabled?: boolean,
  search?: ISearch,
) => UseQueryResult<OrderListResponse, Error>;

const useOrderData = (
  current: number,
  activeTab: OrderStatusTab,
  activeButton: string,
  pageSize: number,
  searchValue?: ISearch,
) => {
  // FetchListOnHold to display count badge on the tab name
  const { data: dataItemFirstOnHold } = useFetchListOnHold(current, pageSize, true, searchValue);

  //define API call fo each tab
  const useFetchListData: {
    [key in OrderStatusTab]: Record<string, FetchDataFunction>;
  } = {
    [OrderStatusTab.ReadyToShip]: { ['all']: useFetchListOrderReady },
    [OrderStatusTab.InProgress]: {
      ['all']: useFetchListInprogress,
      ['en-route']: useFetchOrderEnRoute,
      ['collecting']: useFetchOrderCollecting,
    },
    [OrderStatusTab.OnHold]: {
      ['all']: useFetchListOnHold,
      ['action-required']: useFetchActionRequiredOrderList,
    },
    [OrderStatusTab.RTO]: {
      ['all']: useFetchListRTO,
      ['rto-requested']: useFetchListRtoRequest,
      ['rto-in-transit']: useFetchReturningOrigin,
      ['rto-received-warehouse']: useFetchReturningWarehouse,
    },
    [OrderStatusTab.Finished]: { ['all']: useFetchListFinished },
  };

  // get order list using status slug(used for some of tabs)
  const fetchWithStatusSlug = (
    page: number,
    pageSize?: number,
    enabled?: boolean,
    search?: ISearch,
  ) => {
    return useFetchListByStatusSlug(activeButton, page, pageSize, search);
  };

  // if selectedTab has it own API use it. else=> use fetchWithStatusSlug
  const queryFunction = useFetchListData[activeTab][activeButton] || fetchWithStatusSlug;
  const { data, isLoading, isFetching, refetch } = queryFunction(
    current,
    pageSize,
    true,
    searchValue,
  );

  // transform data
  const mapOrderData = (data: OrderListResponse | undefined): OrderDataType[] => {
    if (!data?.object) return [];
    return (
      data?.object?.items?.map((item) => ({
        key: item.uid,
        orderDate: formatDateByDayjs(item.created_at, 'D MMMM YYYY'),
        orderId: item?.platform_code,
        customerName: item?.full_name,
        customerAddress: item?.address_text,
        orderValue: item?.products_value,
        platform_code: item?.platform_code,
        custom_id: item?.custom_id || '---',
        status: {
          slug: (item?.status?.slug as OrderStatusEnum) || '',
          merchantTitle: (item?.status?.merchant_title as MerchantTitleOrderStatusEnum) ?? '',
        },
        // ! We need slug to realize the exact status of the order (not merchant_title) for checking "inProgressActionsByStatus".
        carrier_message: item?.carrier_message || '---',
        pickupLocation: getOrderPickupAddress(item)?.title || '---',
        pickupAddressId: getOrderPickupAddress(item)?.uid || '',
        products: item?.products?.map((product) => ({
          name: product?.title,
          quantity: product?.quantity,
          price: product?.price,
        })),
        driver: {
          phone: item?.driver?.phone,
          assign: Boolean(item?.driver) ?? false,
        },
        merchant_action_required: item?.merchant_action_required || 0,
        merchant_action_required_text: item.merchant_action_required_text || '',
        phone: item.phone,
      })) || []
    );
  };

  const totals = isFetching || isLoading ? 0 : data?.object.total || 0;

  const activeData = isFetching || isLoading ? [] : mapOrderData(data);

  const onHoldItemsCount = dataItemFirstOnHold?.object?.total || 0;
  return {
    activeData,
    totals,
    isLoading,
    isFetching,
    onHoldItemsCount,
    refetch,
  };
};

export default useOrderData;
