import { Card } from '@/components/Card';
import { Heading } from '@/components/Heading';
import { SpinnerIcon, DownloadIcon } from '@/components/Icons';
import { StatusTag } from '@/components/Status';
import { THEME_VARIABLE } from '@/config';
import { File } from '@/types';
import { formatDateByDayjs } from '@/utils';
import { Button, Divider, Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const FileCardItem = ({ file, name, created_at }: File) => {
  const { t } = useTranslation();

  return (
    <Card extraClassName='mt-2 pt-6 pb-8 rounded-2xl flex gap-3'>
      <Flex justify='space-between'>
        <Typography.Text className='text-base'>{t('bulk_export')}</Typography.Text>
        {file ? (
          <StatusTag
            text={t('completed')}
            color={'green'}
            className='border-2'
            iconColor={THEME_VARIABLE?.customGreen}
          />
        ) : (
          <Flex gap={8}>
            <div className='animate-spin w-6 h-6'>
              <SpinnerIcon />
            </div>
            <Typography.Text className='text-base font-thin text-customGrayShade5'>
              {t('in_queue')}
            </Typography.Text>
          </Flex>
        )}
      </Flex>
      <Divider className='my-0' />
      <Flex justify='space-between'>
        <Typography.Text className='text-base font-thin text-customGrayShade5'>
          {t('requested_date')}
        </Typography.Text>
        <Typography.Text className='text-base font-thin text-customGrayShade5'>
          {formatDateByDayjs(created_at, 'D MMM YYYY, hh:mm')}
        </Typography.Text>
      </Flex>
      <Flex justify='space-between'>
        <Typography.Text className='text-base font-thin text-customGrayShade5'>
          {t('name')}
        </Typography.Text>
        <Typography.Text className='text-base font-thin text-customGrayShade5'>
          {name}
        </Typography.Text>
      </Flex>
      {file && (
        <Button
          onClick={() => {
            window.location.href = file.abs_url;
          }}
          type='primary'
          className='rounded-xl gap-x-2 py-[27px] mt-4'
        >
          <DownloadIcon />
          <Heading
            title={t('download')}
            fontWeight='font-bold'
            fontSize='text-sm'
            upperCase
            extraClassName='leading-5'
          />
        </Button>
      )}
    </Card>
  );
};

export default FileCardItem;
