import { SVGProps } from 'react';

const PDFIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} fill='none' {...props}>
    <g clipPath='url(#clip0_4312_17963)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.4948 6.50849V16.5623C17.4948 17.7706 16.5155 18.7499 15.3072 18.7499H4.7072C3.49893 18.7499 2.51953 17.7706 2.51953 16.5623V3.4375C2.51953 2.22923 3.49893 1.24994 4.7072 1.24994H12.241C12.5723 1.24994 12.8904 1.38174 13.1247 1.61604L15.1291 3.62041L15.1296 3.62094L17.1296 5.62558C17.3635 5.85989 17.4948 6.17727 17.4948 6.50849ZM10.9373 2.50001H4.7072C4.18948 2.50001 3.76952 2.91963 3.76952 3.4375V16.5626C3.76952 17.0802 4.18922 17.4998 4.7072 17.5001H15.3072C15.8252 17.4998 16.2449 17.0802 16.2449 16.5623V7.18744H12.1873C11.497 7.18744 10.9373 6.62777 10.9373 5.93737V2.50001ZM13.5621 10.1652H12.2089V10.7861H13.3937V11.2548H12.2089V12.3863H11.6487V9.69104H13.5621V10.1652ZM7.58568 11.4158H7.01244V12.3863H6.45222V9.68731H7.62765C7.89877 9.68731 8.11487 9.75707 8.27598 9.89606C8.43708 10.0353 8.51763 10.2507 8.51763 10.5425C8.51763 10.8612 8.43708 11.0862 8.27598 11.2183C8.11487 11.3501 7.88469 11.4158 7.58568 11.4158ZM7.84924 10.8538C7.92249 10.7891 7.95925 10.6866 7.95925 10.5463C7.95925 10.4059 7.92223 10.3058 7.84845 10.2459C7.77442 10.186 7.67119 10.1562 7.53798 10.1562H7.01244V10.951H7.53798C7.67119 10.951 7.77494 10.9185 7.84924 10.8538ZM10.1034 12.3863H8.94072V9.68731H10.1034C10.2705 9.68997 10.4097 9.70941 10.5207 9.74589C10.71 9.80819 10.8633 9.92242 10.9803 10.0883C11.0744 10.2228 11.1386 10.3679 11.1727 10.5242C11.2069 10.6804 11.2238 10.8293 11.2238 10.9709C11.2238 11.3298 11.1519 11.6339 11.008 11.8829C10.8124 12.2186 10.5111 12.3863 10.1034 12.3863ZM10.5226 10.3758C10.436 10.2294 10.2645 10.1562 10.0082 10.1562H9.48816V11.9177H10.0082C10.2741 11.9177 10.4597 11.7865 10.5648 11.5239C10.6221 11.3799 10.6508 11.2084 10.6508 11.0095C10.6508 10.7348 10.6081 10.5236 10.5226 10.3758ZM15.675 5.93737L14.2451 4.50412L12.241 2.50001H12.1873V5.93737H15.675Z'
        fill='#939496'
      />
    </g>
    <defs>
      <clipPath id='clip0_4312_17963'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export default PDFIcon;
