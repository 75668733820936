import { useQueryClient } from '@tanstack/react-query';
import { Col, Flex, Form, message, Row } from 'antd';
import { Store } from 'antd/es/form/interface';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Heading } from '@/components';
import {
  ProductDetailsCard,
  CustomerDetailsCard,
  PackageAndPaymentCard,
  DeliveryInstructionCard,
  OrderIdCard,
  PickupAddressCard,
} from '@/components/OrderForm';
import { ROUTE } from '@/config';
import { useChangeProduct, useCustomerIO, useUpdateOrderPricePreview } from '@/hooks';
import { useAsyncCreateOrders, useCreateContact, useFetchUser } from '@/hooksApi';
import useProductStore from '@/stores/productStore';
import { BasicProduct, ContactRequest, Order } from '@/types';
import { useConfirmModalStore } from '@/stores';
import BottomOrderCard from './BottomOrderCard';
import { scrollTop } from '@/utils';

const AddNewOrder = () => {
  const [selectedProvinceCode, setSelectedProvinceCode] = useState<string | undefined>(undefined);
  const [addOrderLoading, setAddOrderLoading] = useState<'creating' | 'readyToShip' | undefined>(
    undefined,
  );

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate: mutateAsyncAddNewOrder } = useAsyncCreateOrders();
  const { mutate: mutateAddNewContact } = useCreateContact();

  const { data: userData } = useFetchUser();
  const { openConfirm } = useConfirmModalStore();
  const { trackEvent } = useCustomerIO();

  const { products, resetProducts } = useProductStore();

  const {
    handleFormUpdated,
    orderPrice,
    orderPriceLoading,
    customShipmentFee,
    setCustomShipmentFee,
    setPackageSize,
    transformValuesToOrder,
    checkOrderFormValidation,
    handleProductChanged,
  } = useUpdateOrderPricePreview(products, form);

  const { handleAddProduct, handleDeleteProduct, handleUpdateProduct, setProducts } =
    useChangeProduct(handleProductChanged);

  useEffect(() => {
    return () => {
      form.resetFields();
      resetProducts();
    };
  }, []);

  const handleSubmit = (ready_to_ship: boolean) => {
    const formValues = form.getFieldsValue();

    const isValid = checkOrderFormValidation({ ...formValues, products });

    form.submit();
    form
      .validateFields()
      .then((values) => {
        if (isValid) onFinish(values, ready_to_ship);
      })
      .catch((errorInfo) => {
        console.warn('Failed: ', errorInfo);
      });
  };

  const onFinish = (values: Store, ready_to_ship: boolean) => {
    if (userData?.object.verified_at) {
      const orderPayload = transformValuesToOrder(values, ready_to_ship);

      addNewOrder(orderPayload, ready_to_ship);

      if (values.saveToAddress) {
        saveContact(orderPayload);
      }
    } else {
      openNotVerifiedConfirm();
    }
  };

  const addNewOrder = (orderPayload: Order, ready_to_ship: boolean) => {
    setAddOrderLoading(ready_to_ship ? 'readyToShip' : 'creating');
    mutateAsyncAddNewOrder(
      { orders: [orderPayload], ready_to_pick_up: ready_to_ship },
      {
        onSuccess: () => {
          message?.success(t('order_added_successfully'));
          setProducts([]);
          form.resetFields(); // Reset the form fields after a successful submission
          setTimeout(() => {
            if (ready_to_ship) {
              navigate(ROUTE.PICKUPS);
            } else {
              navigate(ROUTE.ORDERS);
            }
            setAddOrderLoading(undefined);
          }, 2000);
        },
        onError: () => {
          setAddOrderLoading(undefined);
        },
      },
    );
  };

  const saveContact = (orderPayload: Order) => {
    const contactPayload = { ...orderPayload.contact } as ContactRequest;

    mutateAddNewContact(contactPayload, {
      onSuccess: () => {
        queryClient.refetchQueries(['contacts']);
        message?.success(t('contact_added'));
      },
    });
  };

  const openNotVerifiedConfirm = () => {
    openConfirm({
      description: t('user_not_verified'),
      onConfirm: () => {
        trackEvent(
          'request_verification',
          {
            userId: userData?.object?.uid,
            traits: {
              phone: userData?.object?.phone,
              user_type: 'MERCHANT',
              email: userData?.object?.email,
              firstname: userData?.object?.first_name,
              lastname: userData?.object?.last_name,
              fullname: `${userData?.object?.first_name}${userData?.object?.last_name}`,
            },
          },
          t('thank_you_for_choosing_Boxy_we_will_contact_you_soon'),
        );
      },
      approveText: t('request_activation'),
      cancelText: t('cancel'),
    });
  };

  const handleCreateProduct = (product: BasicProduct) => {
    const formValues = form.getFieldsValue();

    const newProductList = [...(formValues.products ?? []), product];

    if (checkOrderFormValidation({ ...formValues, products: newProductList }, true)) {
      handleAddProduct(product);
      return true;
    }
    return false;
  };

  const handleSetSelectedProvinceCode = (provinceCode?: string) => {
    form.setFieldsValue({ province: provinceCode, region_uid: undefined });
    form.setFieldValue('region_uid', undefined);
    setSelectedProvinceCode(provinceCode);
  };

  const handleFormUpdateForAddress = (formValue: Store) => {
    handleFormUpdated(
      { saved_pick_up_address_uid: formValue.saved_pick_up_address_uid },
      { ...formValue },
    );
  };

  return (
    <Flex vertical>
      <Form
        form={form}
        initialValues={{
          packageSize: 'S',
          CustomerOrMerchant: 'BY_CUSTOMER',
          CodOrPrepaid: 'COLLECT_ON_DELIVERY',
        }}
        name='add-new-order'
        onValuesChange={handleFormUpdated}
      >
        <Heading
          title={t('add_new_order')}
          routeLink='/orders'
          fontSize='text-2xl'
          fontWeight='font-black'
          extraClassName='leading-[27.84px]'
        />

        <Row gutter={[24, 16]} className='mt-[40px] mb-3'>
          <Col lg={14} md={24} xs={24}>
            <CustomerDetailsCard
              form={form}
              setSelectedProvinceCode={handleSetSelectedProvinceCode}
              selectedProvinceCode={selectedProvinceCode}
            />
            <DeliveryInstructionCard />
            <OrderIdCard />
            <ProductDetailsCard
              products={products}
              handleCreateProduct={handleCreateProduct}
              handleDeleteProduct={handleDeleteProduct}
              handleUpdateProduct={handleUpdateProduct}
            />
            <PickupAddressCard form={form} previewOrderPrice={handleFormUpdateForAddress} />
          </Col>
          <Col lg={10} md={24} xs={24}>
            <PackageAndPaymentCard
              form={form}
              onPackageSizeChange={setPackageSize}
              orderPrice={orderPrice}
              orderPriceLoading={orderPriceLoading}
              onCustomShipmentFeeChange={setCustomShipmentFee}
              customShipmentFee={customShipmentFee}
            />
          </Col>
        </Row>
        <Row className='sticky bottom-4'>
          <Col lg={24} md={24} xs={24}>
            <BottomOrderCard
              addOrderLoading={addOrderLoading}
              disable={!orderPrice.orders_value}
              createOrderTitle={t('create_order')}
              readyToShipTitle={t('create_ready_ship')}
              handleSubmit={handleSubmit}
            />
          </Col>
        </Row>
      </Form>
    </Flex>
  );
};

export default AddNewOrder;
