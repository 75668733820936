import { create } from 'zustand';
import { message } from 'antd';
import { t } from 'i18next';

export interface ToastData {
  fileName: string;
  toastText: string;
}

interface PollingProcess extends ToastData {
  intervalId?: NodeJS.Timeout;
  timeoutId?: NodeJS.Timeout;
}

interface ProcessStore {
  processes: Map<ProcessNameEnum, PollingProcess>;
  startPolling: (
    processName: ProcessNameEnum,
    toastData: ToastData,
    checkStatusFunction: () => Promise<boolean>,
    timeout?: number,
    customIntervalTime?: number,
  ) => void;
  stopPolling: (processName: ProcessNameEnum) => void;
}

export enum ProcessNameEnum {
  EXPORT_ORDER = 'export-order',
  BULK_IMPORT = 'bulk-import',
}

export const usePollingMechanismStore = create<ProcessStore>((set) => ({
  // Store to manage polling processes with their associated intervals and timeouts
  processes: new Map(),

  startPolling: (
    processName,
    toastData,
    checkStatusFunction,
    timeout = 120000, // Default timeout of 2 minutes
    customIntervalTime = 5000, // Default interval time of 5 seconds
  ) => {
    const { processes, stopPolling } = usePollingMechanismStore.getState();
    const existingProcess = processes.get(processName);

    // Clear any existing intervals and timeouts for the process
    if (existingProcess?.intervalId) {
      clearInterval(existingProcess.intervalId);
    }
    if (existingProcess?.timeoutId) {
      clearTimeout(existingProcess.timeoutId);
    }

    // Start polling with the provided interval time
    const intervalId = setInterval(async () => {
      try {
        console.log('polling', `Starting polling for ${processName}`);

        // Check the status of the process
        const isComplete = await checkStatusFunction();
        if (isComplete) {
          // Stop polling if the process is complete
          stopPolling(processName);
        }
      } catch (error) {
        console.error(`Polling error for process ${processName}:`, error);

        // Stop polling on error and show a warning message
        stopPolling(processName);
        console.error(`Polling error for process ${processName}:`, error);
        message.warning(
          t('polling.error_description', {
            fileName: toastData.fileName,
            processName,
          }),
        );
      }
    }, customIntervalTime);

    // Set timeout to automatically stop polling after the specified duration
    const timeoutId = setTimeout(() => {
      console.log('polling', `Polling timeout reached for ${processName}`);

      stopPolling(processName);
    }, timeout);

    // Update the store with the new polling process
    set((state) => {
      console.log('polling', `Setting process ${processName} in store`);

      const newProcesses = new Map(state.processes);
      newProcesses.set(processName, { ...toastData, intervalId, timeoutId });
      return { processes: newProcesses };
    });
  },

  stopPolling: (processName) => {
    console.log('polling', `Stopping polling for ${processName}`);
    set((state) => {
      const existingProcess = state.processes.get(processName);
      if (existingProcess?.intervalId) {
        // Clear the polling interval
        clearInterval(existingProcess.intervalId);
      }
      if (existingProcess?.timeoutId) {
        // Clear the polling timeout
        clearTimeout(existingProcess.timeoutId);
      }
      // Remove the process from the store
      const newProcesses = new Map(state.processes);
      newProcesses.delete(processName);
      return { processes: newProcesses };
    });
  },
}));
