import {
  AddNewOrder,
  ClientError,
  Dashboard,
  EditOrder,
  Finance,
  FinanceSingle,
  Import,
  Login,
  NoData,
  NotFound,
  OrderDetails,
  Orders,
  Pickups,
  PickupSingle,
  RtoDetails,
  ServerError,
  Settings,
} from '@/pages';
import { IRoute } from '../types/route';
import { Main } from '@/layout';
import Register from '@/pages/register';
import Verify from '@/pages/verify';
import OrderRtoSingle from '@/pages/pickups/RTOsingle';
import { Navigate } from 'react-router-dom';
import MagicToken from '@/pages/magic-token';

const ROUTE = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  ORDERS: '/orders',
  PICKUPS: '/pickups',
  RTO_LIST: '/pickups#rto',
  PICKUPS_DETAILS: '/pickups/details',
  ORDER_DETAILS: '/orders',
  ORDER_DETAILS_RTO: '/orders/rto',
  FINANCE: '/finance',
  FINANCE_DETAILS: '/finance/details',
  LOGIN: '/login',
  REGISTER: '/register',
  VERIFY: '/verify',
  ADDNEWORDER: '/orders/add',
  ORDER_EDIT: '/orders/edit/:orderUid',
  IMPORT: '/import',
  SETTINGS: '/settings',
  RTO_DETAILS: '/rto/details',
  NO_DATA: '/no-data',
  ERROR_400: '/400',
  ERROR_500: '/500',
  NOT_VERIFIED: '/not-verified',
  MAGIC_TOKEN: '/login-with-magic-token/:magicToken',
  NOT_FOUND: '*',
};

const routes: IRoute[] = [
  // {
  //   path: ROUTE.HOME,
  //   element: (
  //     <Main>
  //       <Orders />
  //     </Main>
  //   ),
  //   private: true,
  // },
  {
    path: ROUTE.HOME,
    element: <Navigate to={ROUTE.ORDERS} />, // Redirecting '/' to '/orders'
    private: true,
  },
  {
    path: ROUTE.DASHBOARD,
    element: (
      <Main>
        <Dashboard />
      </Main>
    ),
    private: true,
  },
  {
    path: ROUTE.ORDERS,
    element: (
      <Main>
        <Orders />
      </Main>
    ),
    private: true,
  },
  {
    path: ROUTE.FINANCE,
    element: (
      <Main>
        <Finance />
      </Main>
    ),
    private: true,
  },
  {
    path: `${ROUTE.FINANCE_DETAILS}/:id`,
    element: (
      <Main>
        <FinanceSingle />
      </Main>
    ),
    private: true,
  },
  {
    path: ROUTE.PICKUPS,
    element: (
      <Main>
        <Pickups />
      </Main>
    ),
    private: true,
  },
  {
    path: ROUTE.ADDNEWORDER,
    element: (
      <Main>
        <AddNewOrder />
      </Main>
    ),
    private: true,
  },
  {
    path: `${ROUTE.ORDER_DETAILS}/:orderId`,
    element: (
      <Main>
        <OrderDetails />
      </Main>
    ),
    private: true,
  },
  {
    path: `${ROUTE.ORDER_DETAILS_RTO}/:id`,
    element: (
      <Main>
        <OrderRtoSingle />
      </Main>
    ),
    private: true,
  },
  {
    path: `${ROUTE.PICKUPS_DETAILS}/:id`,
    element: (
      <Main>
        <PickupSingle />
      </Main>
    ),
    private: true,
  },

  {
    path: ROUTE.IMPORT,
    element: (
      <Main>
        <Import />
      </Main>
    ),
    private: true,
  },
  {
    path: ROUTE.SETTINGS,
    element: (
      <Main>
        <Settings />
      </Main>
    ),
    private: true,
  },
  {
    path: ROUTE.RTO_DETAILS,
    element: (
      <Main>
        <RtoDetails />
      </Main>
    ),
    private: true,
  },
  {
    path: ROUTE.ORDER_EDIT,
    element: (
      <Main>
        <EditOrder />
      </Main>
    ),
    private: true,
  },
  {
    path: ROUTE.LOGIN,
    element: <Login />,
    private: false,
  },
  {
    path: ROUTE.REGISTER,
    element: <Register />,
    private: false,
  },
  {
    path: ROUTE.VERIFY,
    element: <Verify />,
    private: false,
  },
  {
    path: ROUTE.NO_DATA,
    element: (
      <Main>
        <NoData />
      </Main>
    ),
    private: false,
  },
  {
    path: ROUTE.ERROR_400,
    element: (
      <Main>
        <ClientError />
      </Main>
    ),
    private: false,
  },
  {
    path: ROUTE.ERROR_500,
    element: (
      <Main>
        <ServerError />
      </Main>
    ),
    private: false,
  },
  // {
  //   path: ROUTE.NOT_VERIFIED,
  //   element: <NotVerified />,
  //   private: false,
  // },
  {
    path: ROUTE.MAGIC_TOKEN,
    element: <MagicToken />,
    private: false,
  },
  {
    path: ROUTE.NOT_FOUND,
    element: (
      <Main>
        <NotFound />
      </Main>
    ),
    private: false,
  },
];

export { routes, ROUTE };
