import { OrderStatusTab } from '@/types';

export enum OrderCommonActionsEnum {
  EDIT = 'edit',
  CANCEL = 'cancel',
}
export enum OrderStatusEnum {
  new = 'new',
  scheduled = 'scheduled',
  collecting = 'collecting',
  out_for_collecting = 'out-for-collecting',
  collected = 'collected',
  sorted = 'sorted',
  transferred_carrier = 'transferred-carrier',
  in_transit = 'in-transit',
  out_for_delivery = 'out-for-delivery',
  postponed = 'postponed',
  // 'out-for-collection', // ? is it correct?
  received_warehouse = 'received-warehouse',
  received_carrier_warehouse = 'received-carrier-warehouse',
  exception = 'exception',
  on_hold = 'on-hold',
  hold_resolved = 'hold-resolved',
  retry_request = 'retry-request',
  rto_received_warehouse = 'rto-received-warehouse',
  rto_in_transit = 'rto-in-transit',
  rto_requested = 'rto-requested',
  rto_request_pending = 'rto-request-pending',
  rto_scheduled = 'rto-scheduled',
  rto_received_carrier_warehouse = 'rto-received-carrier-warehouse',
  rto_collecting = 'rto-collecting',
  delivered = 'delivered',
  rto_partially_delivered = 'rto-partially-delivered',
  rto_delivered = 'rto-delivered',
  partially_delivered = 'partially-delivered',
}
export const ORDER_STATUE_GROUPED_BY_TAB: { [key in OrderStatusTab]: OrderStatusEnum[] } = {
  [OrderStatusTab.ReadyToShip]: [OrderStatusEnum.new],
  [OrderStatusTab.InProgress]: [
    OrderStatusEnum.scheduled,
    OrderStatusEnum.collecting,
    OrderStatusEnum.out_for_collecting,
    OrderStatusEnum.collected,
    OrderStatusEnum.sorted,
    OrderStatusEnum.transferred_carrier,
    OrderStatusEnum.in_transit,
    OrderStatusEnum.out_for_delivery,
    OrderStatusEnum.postponed,
    // OrderStatusEnum.en_route, // ? is it correct?
    // OrderStatusEnum.out_for_collection, // ? is it correct?
    OrderStatusEnum.received_warehouse,
    OrderStatusEnum.received_carrier_warehouse,
  ],
  [OrderStatusTab.OnHold]: [
    OrderStatusEnum.on_hold,
    OrderStatusEnum.hold_resolved,
    OrderStatusEnum.retry_request,
    OrderStatusEnum.exception,
  ],
  [OrderStatusTab.RTO]: [
    OrderStatusEnum.rto_received_warehouse,
    OrderStatusEnum.rto_in_transit,
    OrderStatusEnum.rto_requested,
    OrderStatusEnum.rto_request_pending,
    OrderStatusEnum.rto_scheduled,
    OrderStatusEnum.rto_received_carrier_warehouse,
    OrderStatusEnum.rto_collecting,
  ],
  [OrderStatusTab.Finished]: [
    OrderStatusEnum.delivered,
    OrderStatusEnum.rto_partially_delivered,
    OrderStatusEnum.rto_delivered,
    OrderStatusEnum.partially_delivered,
    // 'cancelled',
  ],
};
export enum MerchantTitleOrderStatusEnum {
  new = 'new',
  scheduled = 'scheduled',
  collecting = 'collecting',
  collected = 'collected',
  en_route = 'in-transit',
  out_for_delivery = 'out-for-delivery',
  postponed = 'postponed',
  on_hold = 'on-hold',
  exception = 'exception',
  returned_warehouse = 'returned-warehouse',
  returning_origin = 'returning-origin',
  rto_requested = 'rto-requested',
  rto_scheduled = 'rto-scheduled',
  delivered = 'delivered',
  partially_delivered = 'partially-delivered',
  returned = 'returned',
  partially_returned = 'partially-returned',
  //
  // out_for_collecting = 'out-for-collecting',
  // sorted = 'sorted',
  // transferred_carrier = 'transferred-carrier',
  // in_transit = 'in-transit',
  // received_warehouse = 'received-warehouse',
  // received_carrier_warehouse = 'received-carrier-warehouse',
  // hold_resolved = 'hold-resolved',
  // retry_request = 'retry-request',
  // rto_received_warehouse = 'rto-received-warehouse',
  // rto_in_transit = 'rto-in-transit',
  // rto_request_pending = 'rto-request-pending',
  // rto_received_carrier_warehouse = 'rto-received-carrier-warehouse',
  // rto_collecting = 'rto-collecting',
  // rto_delivered = 'rto-delivered',
  // rto_partially_delivered = 'rto-partially-delivered',
}
