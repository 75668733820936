import { Button, Col, Dropdown, Layout, Row, Space } from 'antd';
import { SupportIcon } from '../';
import { useTranslation } from 'react-i18next';
import { LOCAL_AR, LOCAL_EN, ROUTE, STYLE_HEADER } from '@/config';
import { useLocation } from 'react-router-dom';
import { Heading } from '../Heading';
import { useState, useEffect } from 'react';
import { GlobalOutlined } from '@ant-design/icons';
import type { MenuInfo } from 'rc-menu/lib/interface';
import useLanguageStore from '@/stores/useLanguageStore';
import { useCrisp } from '@/hooks';
import { ProgressBarToast } from './ProgressBarToast';
import NotificationDrawer from './NotificationDrawer';
import { getPageTitleWithPathAndHash } from '@/utils';
import { OrderPageHeader } from './OrderPageHeader';
import { usePollingMechanismStore } from '@/stores';

const Header = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [pageTitle, setPageTitle] = useState('');

  const { t } = useTranslation();
  const location = useLocation();
  const { setLanguage } = useLanguageStore();
  const { showCrispChat } = useCrisp();
  const { processes, stopPolling } = usePollingMechanismStore();

  // Update page title based on location path and hash
  useEffect(() => {
    const pathname = location.pathname.startsWith('/')
      ? location.pathname.slice(1)
      : location.pathname;

    const title = getPageTitleWithPathAndHash(pathname, location.hash);
    setPageTitle(title);
  }, [location.pathname, window.location.hash]);

  const toggleLanguage = (e: MenuInfo) => {
    const newLanguage = e.key;
    setLanguage(newLanguage);
  };

  // Language menu with English and Arabic options
  const languageMenu = {
    items: [
      { key: LOCAL_EN, label: 'English' },
      { key: LOCAL_AR, label: 'العربية' },
    ],
    onClick: toggleLanguage,
  };

  return (
    <Layout className='flex flex-col '>
      <Row>
        <Col md={24} className='flex justify-end mt-4 '>
          <Space size={16}>
            <Dropdown menu={languageMenu} trigger={['click']}>
              <Button icon={<GlobalOutlined style={{ fontSize: '26px' }} />} type='text'></Button>
            </Dropdown>

            <Button onClick={showCrispChat} id='open-chat' type='text' className={STYLE_HEADER}>
              <SupportIcon />
            </Button>
          </Space>
        </Col>
      </Row>
      <Row className='mt-6 w-full justify-between'>
        {pageTitle && pageTitle !== 'Unknown' && (
          <Heading title={t(pageTitle)} upperCase fontWeight='font-bold' level={2} />
        )}
        {location.pathname === ROUTE.ORDERS && <OrderPageHeader />}
      </Row>
      <Row gutter={8}>
        {Array.from(processes.entries()).map(([processName, toastData], index) => (
          <Col md={24} key={index}>
            <ProgressBarToast
              progressToastData={toastData}
              processName={processName}
              removeToast={stopPolling}
            />
          </Col>
        ))}
      </Row>
      <NotificationDrawer drawerVisible={drawerVisible} setDrawerVisible={setDrawerVisible} />
    </Layout>
  );
};

export default Header;
