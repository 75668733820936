import { useSentryMutation, useSentryQuery } from '../utils/sentryQueryWrapper';
import {
  DeleteOrderParams,
  MoveOrderParams,
  OrderExcelResponse,
  OrderExcelRowRequestUpdate,
  OrderExcelRowResponse,
  OrderResponseExcelRowUpdate,
  UploadExcelResponse,
} from '@/types';
import { ErrorMessageResponse } from '@/types/error';
import {
  deleteOrderExcelByUID,
  fetchOrderExcelByUID,
  fetchOrderInValidExcelByUID,
  fetchOrderValidExcelByUID,
  getOrderExcelRowByUID,
  moveOrderExcelByUID,
  updateOrderExcelRow,
  uploadBulkResolveExcel,
  uploadOrderExcel,
} from '@/api';
import { t } from 'i18next';

// Upload file excel
export const useUploadExcel = () => {
  return useSentryMutation<UploadExcelResponse, ErrorMessageResponse, FormData>((data) =>
    uploadOrderExcel(data),
  );
};

// Upload Bulk Resolve Excel
export const useUploadBulkResolveExcel = () => {
  return useSentryMutation<UploadExcelResponse, ErrorMessageResponse, FormData>((data) =>
    uploadBulkResolveExcel(data),
  );
};

// Fetch order excel by UID
export const useFetchOrderExcelByUID = (
  uid: string,
  page?: number,
  pageSize?: number,
  enabled?: boolean,
) => {
  return useSentryQuery<OrderExcelResponse, ErrorMessageResponse>(
    ['import', uid, page],
    () => fetchOrderExcelByUID(uid, page, pageSize),
    {
      enabled: !!uid && enabled,
    },
  );
};

// Fetch order Valid excel by UID
export const useFetchOrderValidExcelByUID = (
  uid: string,
  page?: number,
  pageSize?: number,
  enabled?: boolean,
) => {
  return useSentryQuery<OrderExcelResponse, ErrorMessageResponse>(
    ['import-valid', uid, page],
    () => fetchOrderValidExcelByUID(uid, page, pageSize),
    {
      enabled: !!uid && enabled,
    },
  );
};

// Fetch order InValid excel by UID
export const useFetchOrderInValidExcelByUID = (
  uid: string,
  page?: number,
  pageSize?: number,
  enabled?: boolean,
) => {
  return useSentryQuery<OrderExcelResponse, ErrorMessageResponse>(
    ['import-invalid', uid, page],
    () => fetchOrderInValidExcelByUID(uid, page, pageSize),
    {
      enabled: !!uid && enabled,
    },
  );
};

// Delete an order Excel by ID
export const useDeleteOrderExcelById = () =>
  useSentryMutation<void, ErrorMessageResponse, DeleteOrderParams>(({ uid, key }) =>
    deleteOrderExcelByUID(uid, key),
  );

// Move an order Excel by ID
export const useMoveOrderExcelById = () =>
  useSentryMutation<void, ErrorMessageResponse, MoveOrderParams>(({ uid, data }) =>
    moveOrderExcelByUID(uid, data),
  );

// Fetch a single order excel row by UID
export const useOrderExcelRowByUID = (uid: string, key: string, options = {}) => {
  return useSentryQuery<OrderExcelRowResponse, ErrorMessageResponse>(
    ['orderExcelRow', uid, key],
    () => getOrderExcelRowByUID(uid, key),
    {
      defaultErrorMessage: t('failed_to_get_order_row'),
      enabled: !!uid && !!key, // Query is only enabled if both uid and key are truthy
      ...options, // Spread the options (like onError) into useQuery
    },
  );
};

// Update an order Excel row by UID and key
export const useUpdateOrderExcelRow = (uid: string, key: string) => {
  return useSentryMutation<
    OrderResponseExcelRowUpdate,
    ErrorMessageResponse,
    Partial<OrderExcelRowRequestUpdate>
  >(
    (rowUpdates: Partial<OrderExcelRowRequestUpdate>) => updateOrderExcelRow(uid, key, rowUpdates), // Mutation function to update an order Excel row
    { defaultErrorMessage: t('update_failed') },
  );
};
