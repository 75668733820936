import { Statuses, THEME_VARIABLE } from '@/config';
import { OrderStatusTab } from '@/types';
import translations from '@/i18n/en/translation.json';
import { ORDER_STATUE_GROUPED_BY_TAB, OrderStatusEnum } from '@/config/constants';

export function getStatusTagData(status?: OrderStatusEnum) {
  const statusTagData = {
    [OrderStatusTab.ReadyToShip]: { iconColor: THEME_VARIABLE?.customDarkBlue, tagColor: 'purple' },
    [OrderStatusTab.InProgress]: { iconColor: THEME_VARIABLE?.customBlue, tagColor: 'processing' },
    [OrderStatusTab.OnHold]: { iconColor: THEME_VARIABLE?.customOrange, tagColor: 'warning' },
    [OrderStatusTab.RTO]: { iconColor: THEME_VARIABLE?.colorPrimary, tagColor: 'yellow' },
    [OrderStatusTab.Finished]: { iconColor: THEME_VARIABLE?.customGreen, tagColor: 'success' },
  };

  const orderTab = getOrderTabByStatus(status);
  if (orderTab)
    return {
      key: orderTab,
      ...statusTagData[orderTab],
    };

  return undefined;
}

export const getOrderTabByStatus = (statusSlug?: OrderStatusEnum) => {
  if (statusSlug)
    for (const key in ORDER_STATUE_GROUPED_BY_TAB) {
      if (ORDER_STATUE_GROUPED_BY_TAB[key as OrderStatusTab].includes(statusSlug)) {
        return key as OrderStatusTab;
      }
    }
  return undefined;
};

export function getStatusActivePickup(active: string): number {
  switch (active) {
    case 'new':
      return -1;
    case 'scheduled':
      return 0;
    case 'collecting':
      return 1;
    case 'out-for-collection':
      return 1;
    case 'out-for-collecting':
      return 1;
    case 'collected':
      return 2;
    default:
      return 0;
  }
}

export function getStatusActiveOrderRTO(active: string): number {
  switch (active) {
    case 'rto-scheduled':
      return 0;
    case 'rto-in-transit':
      return 1;
    case 'rto-delivered':
      return 2;
    default:
      return -1;
  }
}

export function mapOrderStatusToKey(status: OrderStatusEnum): keyof typeof Statuses | null {
  const statusInfo = getStatusTagData(status);

  if (!statusInfo?.key) {
    return null;
  }

  switch (statusInfo.key) {
    case OrderStatusTab.ReadyToShip:
      return 'CREATED';
    case OrderStatusTab.InProgress:
      return 'IN_PROGRESS';
    case OrderStatusTab.OnHold:
      return 'ON_HOLD';
    case OrderStatusTab.Finished:
      return 'DELIVERED';
    default:
      return 'CREATED';
  }
}

export function mapOrderStatusToValue(status: OrderStatusEnum): string | null {
  const statusInfo = getStatusTagData(status);

  if (!statusInfo?.key) {
    return null;
  }

  switch (statusInfo.key) {
    case OrderStatusTab.ReadyToShip:
      return 'created';
    case OrderStatusTab.InProgress:
      return 'inProgress';
    case OrderStatusTab.OnHold:
      return 'onHold';
    case OrderStatusTab.Finished:
      return 'delivered';
    default:
      return 'created';
  }
}

export interface listItem {
  title: string;
  value: string;
}

export const listStatusButtons: { [key in OrderStatusTab]: listItem[] } = {
  [OrderStatusTab.ReadyToShip]: [{ title: 'new', value: 'new' }],
  [OrderStatusTab.InProgress]: [
    { title: 'scheduled', value: 'scheduled' },
    { title: 'collecting', value: 'collecting' },
    { title: 'collected', value: 'collected' },
    { title: 'en-route', value: 'en-route' },
    { title: 'out-for-delivery', value: 'out-for-delivery' },
    { title: 'postponed', value: 'postponed' },
  ],
  [OrderStatusTab.RTO]: [
    { title: 'rto-requested', value: 'rto-requested' },
    { title: 'rto-scheduled', value: 'rto-scheduled' },
    { title: 'returned-warehouse', value: 'rto-received-warehouse' },
    { title: 'returning-origin', value: 'rto-in-transit' },
  ],
  [OrderStatusTab.Finished]: [
    { title: 'delivered', value: 'delivered' },
    { title: 'partially-returned', value: 'rto-partially-delivered' },
    { title: 'returned', value: 'rto-delivered' },
    // { title: 'cancelled', value: 'cancelled' },
    { title: 'partially-delivered', value: 'partially-delivered' },
  ],
  [OrderStatusTab.OnHold]: [
    // { title: 'on-hold', value: 'on-hold' },
    { title: 'action-required', value: 'action-required' },
    { title: 'exception', value: 'exception' },
  ],
};

export enum TypeTransition {
  deposit = 'deposit',
  withdraw = 'withdraw',
}

export function groupStatusesByMerchantTitle() {
  const grouped: Record<string, string[]> = {};
  const subCategories: Record<string, string> = translations.merchant_order_status;
  Object.entries(subCategories).forEach(([key, value]) => {
    if (!grouped[value]) {
      grouped[value] = [];
    }
    grouped[value].push(key);
  });

  return grouped;
}
