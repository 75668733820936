import {
  useAddProductToOrder,
  useDeleteProductFromOrder,
  useUpdateProductByUID,
} from '@/hooksApi/useOrder';
import useProductStore from '@/stores/productStore';
import { BasicProduct } from '@/types';

export const useChangeProduct = (
  handleProductChanged: (products: BasicProduct[]) => void,
  orderUid?: string,
) => {
  const { products, setProducts } = useProductStore();

  const { mutate: updateProductByUID } = useUpdateProductByUID(); // Use mutation hook for updating products
  const { mutate: addProductToOrder } = useAddProductToOrder(); // Use mutation hook for adding products to the order
  const { mutate: deleteProductFromOrder } = useDeleteProductFromOrder(); // Use mutation hook for adding products to the order

  const handleSetProduct = (productList: BasicProduct[]) => {
    handleProductChanged(productList);
    setProducts(productList);
  };

  const handleAddProduct = (newProduct: BasicProduct) => {
    if (newProduct.title && newProduct.quantity && newProduct.price) {
      // if (orderUid) {
      //   addProductToOrder(
      //     { orderUID: orderUid, product: newProduct },
      //     {
      //       onSuccess: (data: any) => {
      //         handleSetProduct([...products, { ...newProduct, uid: data.object.uid ?? '' }]);
      //       },
      //     },
      //   );
      // } else {
      handleSetProduct([...products, newProduct]);
      // }
    }
  };

  const handleDeleteProduct = (index: number) => {
    const updatedProducts = products.filter((_, i) => i !== index);
    // if (products[index].uid) {
    //   if (orderUid)
    //     deleteProductFromOrder(
    //       { orderUID: orderUid, productUID: products[index].uid },
    //       {
    //         onSuccess: () => {
    //           handleSetProduct(updatedProducts);
    //         },
    //       },
    //     );
    // } else {
    handleSetProduct(updatedProducts);
    // }
  };

  const handleUpdateProduct = (index: number, field: keyof BasicProduct, value: string) => {
    const updatedProducts = [...products];

    updatedProducts[index][field] = value;
    handleSetProduct(updatedProducts);

    // if (updatedProducts[index].uid && orderUid) {
    //   updateProductByUID({
    //     orderUID: orderUid,
    //     productUID: updatedProducts[index].uid,
    //     updatedProduct: { [field]: value },
    //   });
    // }
  };

  return {
    handleAddProduct,
    handleDeleteProduct,
    handleUpdateProduct,
    setProducts,
  };
};
