import { Card, Flex, Typography } from 'antd';
import CloseIcon from '../Icons/CloseIcon';
import { SpinnerIcon } from '../Icons';
import { ProcessNameEnum, ToastData } from '@/stores/pollingMechanismStore';

interface Props {
  progressToastData: ToastData;
  processName: ProcessNameEnum;
  removeToast: (processName: ProcessNameEnum) => void;
}
export const ProgressBarToast = ({ progressToastData, processName, removeToast }: Props) => {
  return (
    <Card className='rounded-lg shadow-card-dark' styles={{ body: { padding: '16px 16px' } }}>
      <Flex justify='space-between' className='items-center'>
        <Flex gap={8} className='items-center'>
          <div className='animate-spin w-6 h-6'>
            <SpinnerIcon />
          </div>
          {/* <Spin size='large' percent={percent} /> */}
          <Flex vertical>
            <Typography.Text className='font-light text-xs text-[10px] text-gray-400'>
              {progressToastData?.toastText}
            </Typography.Text>
            <Typography.Text>{progressToastData?.fileName}</Typography.Text>
          </Flex>
        </Flex>
        <Flex
          justify='center'
          className='w-[24px] h-[24px] cursor-pointer items-center'
          onClick={() => removeToast(processName)}
        >
          <CloseIcon color='#9E9E9E' width={16} height={16} />
        </Flex>
      </Flex>
    </Card>
  );
};
