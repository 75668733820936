import { fetchLabelsPickupByUID } from '@/api';
import {
  BottomSheet,
  BoxyLogoSmall,
  Card,
  CloseOutline,
  DownloadIcon,
  DriverInfo,
  FlagIcon,
  Heading,
  MapComponent,
  OrderStatus,
  PaymentStatus,
  PickupsIcon,
  ProductDetails,
} from '@/components';
import { OrderItemStatus } from '@/components/PickupsCard/OrderStatus';
import { ROUTE } from '@/config';
import { useCancelPickupById, useFetchSinglePickupById } from '@/hooksApi';
import { downloadFile, isValidLatLng } from '@/utils';
import { getStatusActivePickup } from '@/utils/getStatus';
import { useQueryClient } from '@tanstack/react-query';
import { Col, Flex, Layout, MenuProps, message, Row, Space, Spin, Tooltip, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import mapImage from '@/assets/images/single-pickup-map-image.png';

const PickupSingle = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { mutate: CancelPickupById, isLoading: cancelPickupLoading } = useCancelPickupById();
  const [disabled, setDisabled] = useState(false);

  const { data: dataSinglePickup, isLoading: loadingSinglePickup } = useFetchSinglePickupById(
    id as string,
    600000,
  );

  const items: OrderItemStatus[] = [
    {
      title: t('scheduled'),
      // description: '12:23 PM',
    },
    {
      title: t('collect'),
      // description: '12:23 PM',
    },
    {
      title: t('collected'),
    },
  ];

  const menuItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Heading
          title={t('cancel')}
          leadingIcon={<CloseOutline />}
          rotation={false}
          loading={cancelPickupLoading}
          onClick={() => {
            CancelPickupById(id as string, {
              onSuccess: () => {
                message.success(t('operation'));
                queryClient.refetchQueries(['pickups']);
              },
            });
          }}
        />
      ),
    },
    // {
    //   type: 'divider',
    // },
    // {
    //   key: '2',
    //   label: <Heading title={t('export_to_csv')} leadingIcon={<ExportIcon />} rotation={false} />,
    // },
  ];

  if (loadingSinglePickup) return <Spin />;

  return (
    <Layout className='relative h-fit'>
      {/* Pickup Title */}
      <Row justify='start' align='middle'>
        <Heading
          routeLink={ROUTE.PICKUPS}
          title={t('pickup_details')}
          subTitle={`#${dataSinglePickup?.object?.platform_code || ''}`}
          fontSize='text-2xl'
          subTitleClassName='font-normal text-2xl leading-[27.84px] text-customTunaPrimary'
          fontWeight='font-bold'
        />
      </Row>
      {/* End of Pickup Title */}

      <Row gutter={[24, 16]} className='mt-6 mb-32'>
        {/* Map */}
        {isValidLatLng(dataSinglePickup?.object.task?.lat, dataSinglePickup?.object.task?.lng) ? (
          <Col md={14}>
            <Flex vertical className={`max-h-[28rem] rounded-[10px] shadow-card-dark`}>
              <MapComponent
                targetLocation={
                  dataSinglePickup?.object.task
                    ? {
                        latitude: Number(dataSinglePickup?.object.task.lat),
                        longitude: Number(dataSinglePickup?.object.task.lng),
                      }
                    : undefined
                }
                driverLocation={
                  dataSinglePickup?.object.driver?.last_position &&
                  isValidLatLng(
                    dataSinglePickup?.object.driver?.last_position.lat,
                    dataSinglePickup?.object.driver?.last_position.lng,
                  )
                    ? {
                        latitude: Number(dataSinglePickup?.object.driver?.last_position.lat),
                        longitude: Number(dataSinglePickup?.object.driver?.last_position.lng),
                      }
                    : undefined
                }
              />
            </Flex>
          </Col>
        ) : (
          <Col md={14}>
            <div className='h-[28rem] w-full rounded-[10px] shadow-card-dark relative'>
              <img
                src={mapImage}
                alt='Pickup Map'
                className='w-full h-full rounded-[10px] object-cover'
              />
              <Tooltip title={t('boxy_warehouse')}>
                <Space className='absolute top-[20%] left-[84%] bg-customYellowPrimary shadow-sm rounded-full w-7 h-7 flex items-center justify-center'>
                  <BoxyLogoSmall className='mt-1' />
                </Space>
              </Tooltip>
              <Tooltip title={t('driver')}>
                <Space className='absolute top-[41%] left-[45%] bg-customYellowPrimary/20 rounded-full w-fit h-fit p-[5px] flex items-center justify-center'>
                  <Space className='bg-customYellowPrimary/50 rounded-full w-fit h-fit p-[5px] flex items-center justify-center'>
                    <Space className='bg-customYellowPrimary rounded-full w-[46px] h-[46px] flex items-center justify-center shadow-sm'>
                      <PickupsIcon className='mt-2' />
                    </Space>
                  </Space>
                </Space>
              </Tooltip>
              <Tooltip title={t('you')}>
                <Space className='absolute top-[22%] left-[15%] bg-customGreen shadow-sm rounded-full w-7 h-7 flex items-center justify-center'>
                  <FlagIcon />
                </Space>
              </Tooltip>
              <Flex className='absolute justify-center items-center rounded-[10px] h-8 w-[80%] bottom-[10%] left-[10%] bg-customValentineRed/20'>
                <Typography.Text>
                  {t('real_time_map_will_appear_when_an_order_is_assigned_to_a_driver')}
                </Typography.Text>
              </Flex>
            </div>
          </Col>
        )}

        {/*End of Map */}

        {/* ORDERS STATUS And Payment */}
        <Col md={10}>
          <Flex vertical justify='space-between h-full flex flex-col'>
            <Card extraClassName='px-0 py-6 mb-5'>
              <OrderStatus
                statusMessage={t(dataSinglePickup?.object?.status?.title || 'new')}
                items={items}
                current={getStatusActivePickup(dataSinglePickup?.object?.status?.title || 'new')}
              />
            </Card>
            <Card>
              <PaymentStatus
                headerTitle={t('payment')}
                titleFirst={t('order_value')}
                titleSecond={t('shipment_fee')}
                total={Number(dataSinglePickup?.object?.total_price) || 0}
                valueFirst={Number(dataSinglePickup?.object?.order_value) || 0}
                valueSecond={Number(dataSinglePickup?.object?.shipment_fee) || 0}
              />
            </Card>
          </Flex>
        </Col>
        {/* End of ORDERS STATUS And Payment */}

        <Col md={14} xs={24} className='mb-16'>
          <Card>
            <DriverInfo
              assign={dataSinglePickup?.object.driver === null ? false : true}
              driver={dataSinglePickup?.object.driver}
            />
          </Card>
          <Card>
            <ProductDetails value={String(dataSinglePickup?.object?.orders_count)} />
          </Card>
        </Col>
      </Row>
      {/* End of DRIVER INFO And PRODUCT DETAILS */}
      <Row className='sticky bottom-4'>
        <BottomSheet
          isVisible={true}
          labelBtn={t('labels')}
          Icon={<DownloadIcon />}
          loadingSubmit={disabled}
          onSubmit={async () => {
            setDisabled(true);
            await downloadFile(id as string, fetchLabelsPickupByUID, 'pickup-labels.pdf');
            setDisabled(false);
          }}
          showRecords={false}
          menuItems={menuItems}
        />
      </Row>
    </Layout>
  );
};

export default PickupSingle;
