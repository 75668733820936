import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { Heading } from '../../Heading';
import { useFetchExportedFiles } from '@/hooksApi';
import FileCardItem from './FileCardItem';

const FilesSection = () => {
  const { t } = useTranslation();

  const { data: exportedFileList } = useFetchExportedFiles();

  return (
    <Flex vertical>
      <Flex justify='space-between'>
        <Heading title={t('file')} fontSize='text-lg' fontWeight='font-bold' />
      </Flex>
      <Flex vertical gap={16}>
        {exportedFileList?.object.items?.map((item, index) => (
          <FileCardItem key={item.uid} {...item} />
        ))}
      </Flex>
    </Flex>
  );
};

export default FilesSection;
