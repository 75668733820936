import { useFetchPickups } from '@/hooksApi';
import { PickupItem } from '@/types';
import { formatDateByDayjs } from '@/utils';

/**
 * Hook for getting pickup data
 * @param current Current page
 * @param pageSize Page size
 * @param enabled Whether to enable the query
 */
const usePickupData = (current: number, pageSize?: number, enabled = true) => {
  const { data, isLoading, isFetching } = useFetchPickups(current, pageSize, enabled);
  console.log({ enabled, data }, 'usePickupData');

  const dataPickup = !enabled
    ? []
    : (data?.object?.items || []).map((item: PickupItem | undefined) => ({
        key: item?.uid ?? '',
        location: item?.pick_up_address?.title,
        driver: {
          phone: item?.driver?.phone,
          name: `${item?.driver?.first_name || ''} ${item?.driver?.last_name || ''}`,
          assign: !!item?.driver,
        },
        status: item?.status?.title,
        platform_code: item?.platform_code,
        pickupTime: formatDateByDayjs(item?.created_at, 'D MMMM YYYY'),
        pickupLocation: formatDateByDayjs(item?.created_at, 'H A'),
      }));

  // totals
  const totals = dataPickup.length;

  return { dataPickup, totals, isLoading, isFetching };
};

export default usePickupData;
