import React, { ReactNode } from 'react';
import { Avatar, Row, Col, Space, Flex, Spin } from 'antd';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { Heading } from '../Heading';
import { STYLE_STAR_USER } from '@/config';
import { useTranslation } from 'react-i18next';
import '@/styles/pickups.css';

interface UserContactCardProps {
  profileImageUrl: string;
  rating: number;
  textItem?: ReactNode;
  subTitleItem?: ReactNode;
  assign?: boolean;
}

const UserContactCard: React.FC<UserContactCardProps> = ({
  profileImageUrl,
  rating,
  textItem,
  subTitleItem,
  assign = false,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {!assign ? (
        <Flex>
          <Spin indicator={<LoadingOutlined spin />} size='small' className='custom-spin' />
          <Heading
            extraClassName='ml-4'
            title={t('assign_driver')}
            fontSize='text-sm'
            color='text-customGrayShade5'
          />
        </Flex>
      ) : (
        <Row align='middle' gutter={16} className='w-fit'>
          <Col className='relative'>
            <Avatar size={40} src={profileImageUrl} icon={<UserOutlined />} className='border' />
            <Space className={STYLE_STAR_USER}>
              {/* <Heading
                title={String(rating)}
                trailingIcon={
                  <Rate
                    defaultValue={rating}
                    count={1}
                    className='text-[8px] text-customTunaPrimary'
                    disabled
                  />
                }
                rotation={false}
              /> */}
            </Space>
          </Col>
          <Col flex='auto'>
            <Space direction='vertical' size={0}>
              {textItem}
              {subTitleItem}
            </Space>
          </Col>
        </Row>
      )}
    </>
  );
};

export default UserContactCard;
