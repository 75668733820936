import { ROUTE, Statuses } from '@/config'; // Ensure this import is correct
import { Button, Dropdown, Flex, MenuProps } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../Card';
import { Heading } from '../Heading';
import { DeleteIcon, EditOutline, MoreOutline, PickupsIcon, ResolveIcon } from '../Icons'; // Import specific icons
import { ResolveModal } from '../ResolveModal';
import { useNavigate } from 'react-router-dom';
import { useHandleCancelOrder } from '@/hooks';
import { OrderStatusTab } from '@/types';

// Define the Props interface
interface BottomOrderDetailsCardProps {
  status: keyof typeof Statuses; // Add the status prop
  orderId?: string;
  platformCode?: string;
  comment?: string;
  merchant_action_required?: number;
  editDisabled?: boolean;
  cancelDisabled?: boolean;
  hideButton?: boolean;
}

// Update the component to accept props
const BottomOrderDetailsCard: React.FC<BottomOrderDetailsCardProps> = ({
  status,
  orderId,
  platformCode,
  comment,
  merchant_action_required,
  editDisabled = false,
  cancelDisabled = false,
  hideButton = false,
}) => {
  const [resolveModalOpen, setResolveModalOpen] = useState(false);

  let icon;
  let headingTitle;
  let handleClick = () => {}; // Default click handler does nothing

  if (status === 'DELIVERED') {
    icon = <PickupsIcon width={20} height={20} className='mb-[2px]' />;
    headingTitle = 'ship_now';
  } else if (status === 'ON_HOLD' && merchant_action_required === 1) {
    icon = <ResolveIcon width={20} height={20} className='mt-[3px]' />;
    headingTitle = 'resolve';
    handleClick = () => {
      setResolveModalOpen(true); // Open the modal when ON_HOLD
    };
  }
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { handleCancelOrder, isLoading } = useHandleCancelOrder(
    orderId || '',
    OrderStatusTab.OnHold,
  );

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Heading
          onClick={() => navigate(ROUTE.ORDER_EDIT.replace(':orderUid', String(orderId)))} // Navigate to order edit with uid
          title={t('edit')}
          leadingIcon={<EditOutline />}
          rotation={false}
          disabled={editDisabled}
        />
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '3',
      label: (
        <Heading
          title={t('cancel')}
          leadingIcon={<DeleteIcon />}
          rotation={false}
          onClick={handleCancelOrder}
          loading={isLoading}
          disabled={cancelDisabled}
        />
      ),
    },
  ];

  return (
    <Card extraClassName='py-[19px] w-full !mb-0'>
      <Flex className='gap-x-4' justify='flex-end'>
        {icon && headingTitle && !hideButton && (
          <Button
            onClick={handleClick} // Attach the determined click handler
            type='primary'
            className='rounded-[8px] gap-x-2'
            style={{ paddingBottom: '27px', paddingTop: '27px' }}
          >
            {icon}
            <Heading
              title={t(headingTitle)} // Use the determined title
              fontWeight='font-bold'
              fontSize='text-sm'
              extraClassName='uppercase leading-5'
            />
          </Button>
        )}
        <Dropdown menu={{ items }} trigger={['click']} className='relative right-0'>
          <Button
            className='rounded-[12.27px] w-[56px] border border-solid border-customTunaPrimary flex justify-center items-center'
            style={{ paddingBottom: '27px', paddingTop: '27px' }}
          >
            <MoreOutline width={29} />
          </Button>
        </Dropdown>
      </Flex>
      <ResolveModal
        visible={resolveModalOpen}
        onClose={() => setResolveModalOpen(false)}
        orderId={orderId}
        platformCode={platformCode}
        comment={comment}
      />
    </Card>
  );
};

export default BottomOrderDetailsCard;
