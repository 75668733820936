import React from 'react';
import { Flex, Typography } from 'antd';
import { Trans } from 'react-i18next';
import { PaymentType, ShipmentFeeType } from '@/types';
import { formatWithThousandSeparator } from '@/utils';

const { Text } = Typography;

interface OrderShipmentDescriptionProps {
  orderValue: number;
  shipmentFee: number;
  customShipmentFee: number;
  total: number;
  currency: string;
  shipmentFeeType: ShipmentFeeType;
  paymentType: PaymentType;
}

const OrderShipmentDescription: React.FC<OrderShipmentDescriptionProps> = ({
  orderValue,
  shipmentFee,
  customShipmentFee = 0,
  total,
  currency,
  shipmentFeeType,
  paymentType,
}) => {
  if (orderValue)
    return (
      <Flex vertical className='bg-customBlueShade1 p-2 rounded-xl mt-1 mb-3'>
        <Text className={`font-thin leading-[20px] text-[14px] text-gray-600`}>
          <Trans
            i18nKey='Boxy_charges_shipment_fee_for_the_delivery_fees'
            values={{
              shipmentFee: formatWithThousandSeparator(shipmentFee),
            }}
            components={{ b: <b /> }}
          />
        </Text>
      </Flex>
    );
  return <></>;
  // if (
  //   orderValue &&
  //   paymentType === PaymentType.COLLECT_ON_DELIVERY &&
  //   (customShipmentFee > 0 || shipmentFeeType !== ShipmentFeeType.BY_CUSTOMER)
  // )
  //   return (
  //     <Flex vertical className='bg-customBlueShade1 p-2 rounded-xl mt-1 mb-3'>
  //       {shipmentFee - customShipmentFee > 0 ? (
  //         <Text className={`font-thin leading-[20px] text-[14px] text-gray-600`}>
  //           <Trans
  //             i18nKey='boxyFeeDeduction'
  //             values={{
  //               shipmentFee,
  //               currency,
  //               deductedAmount: shipmentFee - customShipmentFee,
  //             }}
  //             components={{ b: <b /> }}
  //           />
  //         </Text>
  //       ) : (
  //         <Text className={`font-thin leading-[20px] text-[14px] text-gray-600`}>
  //           <Trans
  //             i18nKey='boxyFeeAddition'
  //             values={{
  //               shipmentFee,
  //               currency,
  //               addedAmount: total - shipmentFee,
  //             }}
  //             components={{ b: <b /> }}
  //           />
  //         </Text>
  //       )}
  //     </Flex>
  //   );
  // return <></>;
};

export default OrderShipmentDescription;
