import React, { useRef } from 'react';
import { CheckboxRef, Col, Flex, Radio, Typography } from 'antd';
import { BoxLargeIcon, BoxMediumIcon, BoxSmallIcon } from '../Icons';
import { Trans, useTranslation } from 'react-i18next';
import { LOCAL_EN } from '@/config';
import { PackageSizeFormValues } from '../OrderForm/PackageSizeCard';

const { Text } = Typography;

interface PackageSizeProps {
  title: string;
  packageSizeKey: PackageSizeFormValues;
  description: string;
  icon: React.ComponentType<{ width: number; height: number; color?: string }>;
  selectedValue: string;
  onSelect: (value: PackageSizeFormValues) => void;
}

const PackageSize: React.FC<PackageSizeProps> = ({
  packageSizeKey,
  title,
  icon: Icon,
  description,
  selectedValue,
  onSelect,
}) => {
  const radioRef = useRef<CheckboxRef>(null);
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const iconColor = selectedValue === packageSizeKey ? '#38383E' : '#939496';

  const handleToggle = () => {
    if (radioRef.current?.input) {
      onSelect(packageSizeKey);
      radioRef.current.input.checked = true;
    }
  };

  return (
    <Flex
      className={`h-[153px] py-1 rounded-[10px] flex flex-col justify-center items-center cursor-pointer  ${
        selectedValue === packageSizeKey
          ? 'bg-customYellowPrimary border border-solid border-customTunaPrimary'
          : 'border border-solid border-customGrayShade4'
      }`}
      onClick={handleToggle}
    >
      <Icon color={iconColor} width={35} height={39} />
      <Text
        className={` capitalize ${
          selectedValue === packageSizeKey ? 'font-bold' : 'font-normal'
        } font-bold text-base leading-[24.48px] text-customTunaPrimary`}
      >
        {t(title)}
      </Text>
      <Text
        className={` font-normal my-1 ${
          selectedValue === packageSizeKey ? 'text-customYellowDark' : 'text-customGrayShade5'
        }  text-xs leading-[18px] text-center`}
        // dir='ltr'
        style={{ unicodeBidi: 'plaintext' }}
      >
        <Trans i18nKey={description} components={{ br: <br /> }} />
      </Text>
      <Col className='pb-2'>
        <Radio
          ref={radioRef}
          value={packageSizeKey}
          checked={selectedValue === packageSizeKey}
          className={`custom-radio ${i18n.language === LOCAL_EN ? 'pl-2' : 'pr-2'}  ${
            selectedValue === packageSizeKey ? 'text-customTunaPrimary' : 'text-customGrayShade5'
          }  flex items-center font-bold text-lg leading-[20.88px]`}
        ></Radio>
      </Col>
    </Flex>
  );
};

export default PackageSize;
