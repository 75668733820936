import { DecodeSize, OrderItem, PaymentType, PickupAddress, ShipmentFeeType } from '@/types';
import { countries } from './CountryCodes';
import { isValidPhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';
import { DEFAULT_COUNTRY_CODE } from '@/config';
import { handleErrorMessage } from './handleError';
import { t } from 'i18next';
import dayjs from '@/dayjsSetup';
import useLanguageStore from '@/stores/useLanguageStore';
import { fetchBulkOrderByQuery } from '@/api';

export function getDialCodeByCountryCode(countryCode: string): string | null {
  const country = countries.find((c) => c.dialCode?.includes(countryCode));
  if (country && country.dialCode && country.dialCode.length > 0) {
    return country.dialCode[0].replace('+', '');
  }
  return null;
}

//HANDLE TO REMOVE NON-NUMERIC CHARACTERS:
export const handlePhoneInput = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
  event.target.value = numericValue; // Update input value to be numeric
};
export function totalPrices(numbers: number[]): number {
  return numbers.reduce((sum, number) => sum + number, 0);
}

export function convertedText(input: string): string {
  const words = input.split('_') || input.split('-');

  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  return capitalizedWords.join(' ');
}

export function decodeSize(size: keyof typeof DecodeSize) {
  return DecodeSize[size] || 'Unknown size';
}
export const downloadFile = async (
  uid: string,
  fetchFunction: (uid: string) => Promise<Blob>,
  fileName: string,
) => {
  try {
    const fileBlob = await fetchFunction(uid);

    const url = window.URL.createObjectURL(fileBlob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);

    document.body.appendChild(link);
    link.click();

    link.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(url);

    return true;
  } catch (_error: any) {
    const error = await convertBlobErrorToJSON(_error);

    handleErrorMessage(error, t('operation_failed_please_try_again'), true);

    return false;
  }
};

const convertBlobErrorToJSON = async (error: any) => {
  if (error.response && error.response.data instanceof Blob) {
    const errorText = await error.response.data.text();
    const errorJSON = JSON.parse(errorText);

    return { ...errorJSON, statusCode: error.status };
  } else {
    console.error('Error:', error);
    return null;
  }
};

export function commaSeparatedObjectToArray(obj: {
  product_title: string;
  product_price: string;
  product_quantity: string;
}) {
  const titles = obj.product_title?.split(',');
  const prices = obj.product_price?.split(',')?.map(Number);
  const quantities = obj.product_quantity?.split(',')?.map(Number);

  return titles.map((title: any, index: any) => ({
    title: title.trim(),
    price: prices[index],
    quantity: quantities[index],
  }));
}

// function arrayToCommaSeparatedObject(array:any) {
//   const productTitles = array.map(item:any => item.title).join(", ");
//   const productPrices = array.map(item => item.price).join(",");
//   const productQuantities = array.map(item => item.quantity).join(",");

//   return {
//     product_title: productTitles,
//     product_price: productPrices,
//     product_quantity: productQuantities,
//   };
// }
interface Product {
  uid?: string | null;
  platform_code?: string;
  title?: string;
  price?: number | string;
  currency?: string;
  quantity?: string | number;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string | null;
}

interface CommaSeparatedObject {
  product_title: string;
  product_price: string;
  product_quantity: string;
}

export function arrayToCommaSeparatedObject(array: Product[]): CommaSeparatedObject {
  const productTitles = array.map((item) => item.title).join(', ');
  const productPrices = array.map((item) => item?.price?.toString()).join(',');
  const productQuantities = array.map((item) => item?.quantity?.toString()).join(',');

  return {
    product_title: productTitles,
    product_price: productPrices,
    product_quantity: productQuantities,
  };
}

export const convertArabicToEnglishNumbers = (input: any) => {
  if (!input) {
    return ''; // Return an empty string if input is undefined or null
  }

  const arabicToEnglishMap: { [key: string]: string } = {
    '۰': '0',
    '۱': '1',
    '۲': '2',
    '۳': '3',
    '۴': '4',
    '۵': '5',
    '۶': '6',
    '۷': '7',
    '۸': '8',
    '۹': '9',
    '٠': '0',
    '١': '1',
    '٢': '2',
    '٣': '3',
    '٤': '4',
    '٥': '5',
    '٦': '6',
    '٧': '7',
    '٨': '8',
    '٩': '9',
  };

  return input
    .toString() // Convert input to string to handle numbers
    .split('')
    .map((char: string) => arabicToEnglishMap[char] || char) // Type char as string
    .join('');
};

export const parsePhoneNumber = (phone?: string) => {
  const parsedPhone = parsePhoneNumberFromString('+' + phone);

  return {
    phone: parsedPhone?.nationalNumber,
    countryCode: `${parsedPhone?.countryCallingCode ?? DEFAULT_COUNTRY_CODE}`,
  };
};

export const attachCountryCodeToPhone = (phoneNumber?: string, _countryCode?: string) => {
  if (phoneNumber && _countryCode && phoneNumber !== '') {
    const cleanedPhoneNumber = cleanPhoneNumber(phoneNumber);

    const countryCode = _countryCode;

    // If the phone number starts with 98 or 964, don't append the country code
    return `${countryCode}${cleanedPhoneNumber}`;
  }
  return undefined;
};

export const cleanPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) return undefined;
  const cleanedValue = convertArabicToEnglishNumbers(phoneNumber).replace(/[^0-9]/g, '');
  return cleanedValue.startsWith('0') ? cleanedValue.slice(1) : cleanedValue;
};

export const cleanNumber = (number?: string | number) => {
  return number ? convertArabicToEnglishNumbers(number).replace(/[^\d]/g, '') : '';
};

export const getOrderPickupAddress = (orderData: OrderItem): PickupAddress | undefined => {
  return (
    (orderData?.status?.title === 'new'
      ? orderData?.saved_pick_up_address
      : orderData?.pick_up?.pick_up_address) || undefined
  );
};

export const checkPhoneNumberValidation = (phoneNumber?: string, countryCode?: string) => {
  const fullPhoneNumber = attachCountryCodeToPhone(phoneNumber, countryCode);

  const isPhoneValid = fullPhoneNumber ? isValidPhoneNumber('+' + fullPhoneNumber) : false;
  return isPhoneValid;
};

export const convertToArabicNumbers = (input: string): string => {
  const arabicNumbers = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];

  return input.replace(/\d/g, (digit) => arabicNumbers[parseInt(digit)]);
};

export const formatDateByDayjs = (date?: string, format?: string) => {
  if (!date) return '---';
  const language = useLanguageStore.getState().language;

  const formattedDate = dayjs(date).format(format);

  return language === 'en' ? formattedDate : convertToArabicNumbers(formattedDate);
};

export const getWarehouseAddressType = (pickupAddresses?: PickupAddress[]) => {
  return pickupAddresses?.filter((item) => item.type === 'WAREHOUSE') || [];
};

export const getSidebarRouteInfo = (_pathname: string) => {
  // In sidebarRoutes each key represents the route URL, and each value represents the associated title text for display.
  const sidebarRoutes = new Map<string, string>([
    ['orders', 'orders'],
    ['pickups', 'pickups'],
    ['finance', 'finance'],
    ['dashboard', 'dashboard'],
    // ['import', 'import'],
    ['settings', 'settings'],
  ]);

  const notValidRoutes = new Set(['no-data', '400', '500', '*']);
  const pathname = _pathname.startsWith('/') ? _pathname.slice(1) : _pathname;

  if (notValidRoutes.has(pathname)) {
    return null;
  }

  const matchedRoute = sidebarRoutes.get(pathname);

  if (matchedRoute) {
    return { location: pathname, name: matchedRoute! };
  }

  return { location: '/', name: 'Unknown' };
};

// Get page title considering both path and hash
export const getPageTitleWithPathAndHash = (pathname: string, hash: string) => {
  const routeInfo = getSidebarRouteInfo(pathname);

  // Check for pickups page with rto hash
  if (pathname === 'pickups' || pathname === '/pickups') {
    if (hash === '#rto') {
      return 'returns_list';
    }
  }

  // For all other cases, return the regular sidebar route name
  return routeInfo?.name || 'Unknown';
};

export const exportOrders = async (queryString: string, fileName?: string) => {
  return await downloadFile(
    queryString,
    fetchBulkOrderByQuery,
    `orders-${new Date().getTime()}.csv`,
  );
};

export const calculateTotalValue = (
  paymentType: PaymentType,
  shipmentFeeType: ShipmentFeeType,
  orderValue: number,
  shipmentFee: number,
  customShipmentFee: number,
): string => {
  let totalValue = 0;
  if (paymentType === PaymentType.COLLECT_ON_DELIVERY) {
    totalValue = orderValue;
  }

  return formatWithThousandSeparator(totalValue);

  // const shipmentFeeValue = customShipmentFee > 0 ? customShipmentFee : shipmentFee;
  // if (paymentType === PaymentType.PREPAID && shipmentFeeType === ShipmentFeeType.BY_CUSTOMER) {
  //   return shipmentFeeValue;
  // }

  // if (
  //   paymentType === PaymentType.COLLECT_ON_DELIVERY &&
  //   shipmentFeeType === ShipmentFeeType.BY_MERCHANT
  // ) {
  //   return orderValue;
  // }

  // if (paymentType === PaymentType.PREPAID && shipmentFeeType === ShipmentFeeType.BY_MERCHANT) {
  //   return 0;
  // }

  // return orderValue + shipmentFeeValue;
};

export const formatWithThousandSeparator = (num: number | string): string => {
  const number = Number(num);

  return !Number.isNaN(number) ? Intl.NumberFormat().format(number) : ' - ';
};

export const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const isValidLatLng = (lat?: string, lng?: string) => {
  if (!lat || !lng) return false;

  const latNum = parseFloat(lat);
  const lngNum = parseFloat(lng);

  return (
    !isNaN(latNum) &&
    latNum >= -90 &&
    latNum <= 90 &&
    !isNaN(lngNum) &&
    lngNum >= -180 &&
    lngNum <= 180
  );
};
