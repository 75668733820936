import { Flex, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import WebHookForm from './WebHookForm';

interface Props {
  webHookUID?: string;
  onClose: () => void;
}
const WebHookModal = ({ webHookUID, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      className='rounded-[20px] bg-white border   border-customGrayShade3'
      title={
        <Flex
          className='font-bold text-lg leading-5  text-customTunaPrimary '
          style={{ borderBottom: '1px solid #E1E1E3', paddingBottom: '20px' }}
        >
          {t('add_new_webHook')}
        </Flex>
      }
      width={760}
      centered
      footer={null}
      open={webHookUID !== undefined}
      onOk={onClose}
      onCancel={onClose}
      destroyOnClose
    >
      <WebHookForm onClose={onClose} webHookUID={webHookUID} />
    </Modal>
  );
};

export default WebHookModal;
