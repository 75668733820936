import { THEME_VARIABLE } from '@/config';
import { Col, Grid, Input, InputNumber, Row } from 'antd';
import React, { KeyboardEvent, useEffect } from 'react';

import { BasicProduct } from '@/types';
import { useTranslation } from 'react-i18next';
import ProductSubmitButton from './ProductSubmitButton';
import { cleanNumber, formatWithThousandSeparator } from '@/utils';

interface ProductInputsRowProps {
  product: BasicProduct;
  handleChangeProductKey: (field: keyof BasicProduct, value: string) => void;
  handleSubmit: () => void;
  buttonIcon: React.ComponentType<{ width?: number; height?: number; color?: string }>;
  isPrimaryButton?: boolean;
  disableButton?: boolean;
}

const ProductInputsRow: React.FC<ProductInputsRowProps> = ({
  product,
  buttonIcon: ButtonIcon,
  isPrimaryButton,
  disableButton,
  handleChangeProductKey,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const breakPoints = Grid.useBreakpoint();
  const handleEnterKey = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleSubmit();
  };

  return (
    <Row gutter={[12, 8]}>
      <Col xxl={10} xl={24} lg={24} xs={24} className='flex gap-4'>
        <Input
          placeholder={t('product_name')}
          value={product.title}
          onChange={(e) => handleChangeProductKey('title', e.target.value)}
          onKeyDown={handleEnterKey}
          className='border relative border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg'
        />
        {(breakPoints.lg || breakPoints.md || breakPoints.sm) && !breakPoints.xxl && (
          <ProductSubmitButton
            buttonIcon={ButtonIcon}
            handleSubmit={handleSubmit}
            disableButton={disableButton}
            isPrimaryButton={isPrimaryButton}
          />
        )}
      </Col>

      <Col xxl={3} xl={6} lg={6} xs={6}>
        <InputNumber
          min={'1'}
          formatter={(value) => value} // Format to display clean and english number
          parser={cleanNumber}
          placeholder={t('qty')}
          value={product.quantity}
          className='border relative custom-input-number flex items-center w-full border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg'
          onChange={(value) => handleChangeProductKey('quantity', value?.toString() || '')}
          onKeyDown={handleEnterKey}
        />
      </Col>
      <Col xxl={11} xl={18} lg={18} xs={18} className='flex gap-4'>
        <InputNumber
          min={'1'}
          // style={{ direction: 'ltr' }}
          formatter={(value) => (value ? formatWithThousandSeparator(value) : '')} // Format with thousand separator
          parser={cleanNumber} // Parse value back without commas and convert to eng numbers
          placeholder={t('price')}
          value={product.price}
          onChange={(value) => handleChangeProductKey('price', value?.toString() || '')}
          onKeyDown={handleEnterKey}
          className='border relative custom-input-number flex items-center w-full border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg'
        />
        {(!breakPoints.lg && !breakPoints.md && !breakPoints.sm) || breakPoints.xxl ? (
          <ProductSubmitButton
            buttonIcon={ButtonIcon}
            handleSubmit={handleSubmit}
            disableButton={disableButton}
            isPrimaryButton={isPrimaryButton}
          />
        ) : (
          <div className='min-w-[56px]' />
        )}
      </Col>
    </Row>
  );
};

export default ProductInputsRow;
