import { THEME_VARIABLE } from '@/config';
import { Col, Flex, Input, InputNumber, Row } from 'antd';
import React, { KeyboardEvent } from 'react';

import { BasicProduct } from '@/types';
import { useTranslation } from 'react-i18next';

interface ProductSubmitButtonProps {
  handleSubmit: () => void;
  buttonIcon: React.ComponentType<{ width?: number; height?: number; color?: string }>;
  isPrimaryButton?: boolean;
  disableButton?: boolean;
}

const ProductSubmitButton: React.FC<ProductSubmitButtonProps> = ({
  buttonIcon: ButtonIcon,
  isPrimaryButton,
  disableButton,
  handleSubmit,
}) => {
  return (
    <Flex
      className='border cursor-pointer rounded-lg border-solid flex justify-center items-center h-[56px] w-[56px] min-w-[56px]'
      onClick={handleSubmit}
      style={{
        cursor: disableButton ? 'not-allowed' : 'pointer',
        backgroundColor: isPrimaryButton
          ? THEME_VARIABLE.customYellowPrimary
          : THEME_VARIABLE.customWhite,
        borderColor: isPrimaryButton
          ? THEME_VARIABLE.customYellowPrimary
          : THEME_VARIABLE.GrayShade5,
      }}
    >
      {isPrimaryButton ? (
        <ButtonIcon color={THEME_VARIABLE.customBlack} width={24} height={24} />
      ) : (
        <ButtonIcon color={THEME_VARIABLE.customTunaPrimary} width={24} height={24} />
      )}
    </Flex>
  );
};

export default ProductSubmitButton;
