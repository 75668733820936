import { useTranslation } from 'react-i18next';
import { Card } from '../Card';
import { Heading } from '../Heading';
import { Col, Form, Input, Row, Typography } from 'antd';

const { TextArea } = Input;
const { Text } = Typography;

const DeliveryInstructionCard = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <Heading
        title={t('delivery_instruction')}
        fontSize='text-lg'
        fontWeight='font-bold'
        extraClassName='uppercase'
      />
      <Text>{t('lorem_ipsum')}</Text>
      <Row>
        <Col lg={24} md={24} xs={24} className=' '>
          <Form.Item name='deliveryInstruction'>
            <TextArea
              autoComplete='off'
              variant='borderless'
              className='h-[65px] rounded-[10px] border mt-4 border-solid  border-customGrayShade5'
              placeholder={t('do_not_ring_door')}
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default DeliveryInstructionCard;
