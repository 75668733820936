import {
  Card,
  CustomSettingsCard,
  HelpAndSupport,
  IntegrationCard,
  LogOutIcon,
  PersonalCompanyInfo,
  PickupDrop,
  WebHookSection,
  AccessTokenSection,
} from '@/components';
import AccountCard from '@/components/SettingsCards/AccountCard';
import { FilesSection } from '@/components/SettingsCards/FilesSection';
import { TeamMemberSection } from '@/components/SettingsCards/TeamMemberSection';
import { ROUTE, THEME_VARIABLE } from '@/config';
import { useUserStore } from '@/stores';
import { useAuthStore } from '@/stores/authStore';
import type { MenuProps } from 'antd';
import { Col, ConfigProvider, Flex, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export enum SettingSection {
  Profile = 'profile',
  Location = 'location',
  File = 'file',
  TeamMembers = 'team_members',
  AccessToken = 'access_token',
  WebHook = 'web_hook',
  Logout = 'logout',
}

const Settings = () => {
  const [selectedSection, setSelectedSection] = useState<SettingSection>(SettingSection.Profile);

  const clearToken = useAuthStore((state) => state.clearToken);
  const setUser = useUserStore((state) => state.setUser);

  const navigation = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const sectionComponents = {
    [SettingSection.Profile]: <PersonalCompanyInfo />,
    [SettingSection.Location]: <PickupDrop />,
    [SettingSection.TeamMembers]: <TeamMemberSection />,
    [SettingSection.File]: <FilesSection />,
    [SettingSection.AccessToken]: <AccessTokenSection />,
    [SettingSection.WebHook]: <WebHookSection />,
    [SettingSection.Logout]: <></>,
  };

  useEffect(() => {
    const redirectData = location.state;
    if (redirectData?.activeTab && Object.values(SettingSection).includes(redirectData.activeTab)) {
      setSelectedSection(redirectData.activeTab);
    }
  }, []);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    setSelectedSection(e.key as SettingSection);
  };

  const handleLogOut = () => {
    clearToken?.();
    setUser(undefined);
    navigation(ROUTE.LOGIN);
  };

  return (
    <Row gutter={[24, 16]} className='mt-[34px]'>
      <Col lg={10} md={24} xs={24}>
        {/* <FreeOrders referText={t('refer_and_get_rewarded')} mgCode='MG-567' /> */}
        <ConfigProvider
          theme={{
            token: {
              colorPrimaryBg: THEME_VARIABLE.customYellowPrimary,
            },
          }}
        >
          <AccountCard selectedSection={selectedSection} handleMenuClick={handleMenuClick} />
          {/* <ShippingCard /> */}
          <IntegrationCard selectedSection={selectedSection} handleMenuClick={handleMenuClick} />
        </ConfigProvider>

        <HelpAndSupport selectedSection={selectedSection} />
        <Card extraClassName='mt-6 px-12'>
          <Flex onClick={handleLogOut} className='cursor-pointer'>
            <CustomSettingsCard
              IconLeft={<LogOutIcon width={24} height={24} color={THEME_VARIABLE.customRed} />}
              title={t('log_out')}
              iconBackgroundColor='bg-customRedShade1'
              sectionName={SettingSection.Logout}
              selectedSection={selectedSection}
            />
          </Flex>
        </Card>
      </Col>
      <Col lg={14} md={24} xs={24}>
        {sectionComponents[selectedSection]}
      </Col>
    </Row>
  );
};

export default Settings;
