import { Flex, Typography } from 'antd';
import React from 'react';
import { LOCAL_EN, THEME_VARIABLE } from '@/config';
import { useTranslation } from 'react-i18next';
import { RightIcon } from '../Icons';
import { SettingSection } from '@/pages/settings';

const { Text } = Typography;

interface CustomSettingsCardProps {
  IconLeft: React.ReactNode;
  title?: string;
  titleColor?: string;
  description?: string;
  borderBottomStyle?: string;
  extraClassName?: string;
  onclick?: () => void;
  selectedSection?: SettingSection;
  sectionName?: SettingSection;
  iconBackgroundColor?: string;
}

const CustomSettingsCard: React.FC<CustomSettingsCardProps> = ({
  IconLeft,
  title,
  titleColor = 'text-customTunaPrimary',
  description,
  borderBottomStyle,
  extraClassName,
  selectedSection,
  sectionName,
  iconBackgroundColor,
  onclick,
}) => {
  const { i18n } = useTranslation();

  const leftIconBackgroundColor =
    iconBackgroundColor ??
    (selectedSection && selectedSection === sectionName
      ? 'bg-customYellowPrimary'
      : 'bg-customGrayShade2');

  const descriptionColor =
    selectedSection && selectedSection === sectionName
      ? 'text-customTunaPrimary'
      : 'text-customGrayShade5';

  return (
    <Flex
      style={{ borderBottom: borderBottomStyle }}
      className={`flex justify-between items-center w-full  cursor-pointer  ${extraClassName}`}
      onClick={onclick}
    >
      <Flex className='gap-x-[14px]'>
        <Flex
          className={`w-[43px] h-[43px] rounded-full flex justify-center items-center ${leftIconBackgroundColor}`}
        >
          {IconLeft}
        </Flex>
        <Flex vertical className={`${description ? 'space-y-1' : 'justify-center'}`}>
          {title && (
            <Text
              className={` capitalize font-normal text-base leading-[19.2px] ${titleColor} tracking-[-0.32px]`}
            >
              {title}
            </Text>
          )}
          {description && (
            <Text
              className={` capitalize font-normal ${descriptionColor} text-sm leading-[16.8px]  tracking-[-0.32px]`}
            >
              {description}
            </Text>
          )}
        </Flex>
      </Flex>
      <RightIcon
        className={`${i18n.language === LOCAL_EN ? 'rotate-0' : 'rotate-180'}`}
        width={12}
        height={12}
        color={THEME_VARIABLE.customGrayShade5}
      />
    </Flex>
  );
};

export default CustomSettingsCard;
