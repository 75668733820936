import { Flex, Popover, Row, Steps, StepsProps, Tag } from 'antd';
import { Heading } from '../Heading';
import { CalendarOutlineIcon, CollectedOutlineIcon, DotIcon, TruckOutlineIcon } from '../Icons';
import { LOCAL_EN, STYLE_STATUS } from '@/config';
import { useTranslation } from 'react-i18next';
import '@/styles/style.css';

const Icons: { [key: number]: React.ReactNode } = {
  0: <CalendarOutlineIcon />,
  1: <TruckOutlineIcon />,
  2: <CollectedOutlineIcon />,
};

const customDot: StepsProps['progressDot'] = (dot, { index }) => (
  <>
    <Row className='relative'>
      <Flex className='text-xs text-gray-500 absolute -top-14 -left-2'>{Icons[index]}</Flex>
    </Row>
    <Popover>{dot}</Popover>
  </>
);

export interface OrderItemStatus {
  title: string;
  description?: string;
}

interface OrderStatusProps {
  items: OrderItemStatus[];
  statusMessage: string;
  current?: number;
}

const OrderStatus = ({ items, statusMessage, current = 0 }: OrderStatusProps) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Flex justify='space-between' className='px-6 pb-6'>
        <Heading title={t('order_status')} fontSize='text-lg' fontWeight='font-bold' />
        <Tag color='processing' className={STYLE_STATUS} icon={<DotIcon />}>
          {statusMessage}
        </Tag>
      </Flex>
      <Row className='custom-steps'>
        <div
          className={`w-full ${i18n.language === LOCAL_EN ? 'custom-steps-en' : 'custom-steps-ar'}`}
        >
          <Steps
            size='small'
            current={current}
            progressDot={customDot}
            className='mt-16 '
            items={items}
            // responsive={false}
            direction='horizontal'
          />
        </div>
      </Row>
    </>
  );
};

export default OrderStatus;
