import { Card } from '../Card';

import React from 'react';
import { THEME_VARIABLE } from '@/config';
import { Col, Flex, Row, Typography } from 'antd';
import { Heading } from '../Heading';
import OrderShipmentValueCard from '../OrderForm/PaymentCard/OrderShipmentValueCard/OrderShipmentValueCard';
import { PaymentType, ShipmentFeeType } from '@/types';
import { convertedText } from '@/utils';

const { Text } = Typography;

// Define the props interface
interface PaymentOrderCardProps {
  title: string;
  orderFee: string;
  shippingFee: string;
  shipmentFeeType: ShipmentFeeType;
  paymentType: PaymentType;
  orderValue: number;
  shipmentFee: number;
  walletFee?: number;
  total: number;
  orderValueText: string;
  shipmentFeeText: string;
  walletFeeText?: string;
  totalText: string;
  currency: string;
  customShipmentFee: number;
  customShipmentFeeText: string;
}

const PaymentOrder: React.FC<PaymentOrderCardProps> = ({
  title,
  orderFee,
  paymentType,
  shippingFee,
  shipmentFeeType,
  orderValue,
  shipmentFee,
  walletFee,
  total,
  orderValueText,
  shipmentFeeText,
  walletFeeText,
  totalText,
  currency,
  customShipmentFee,
  customShipmentFeeText,
}) => {
  return (
    <Card>
      <Heading title={title} fontSize='text-lg' fontWeight='font-bold' extraClassName='uppercase' />
      <Row className='mt-3'>
        <Col lg={10} md={24} xs={24}>
          <Flex vertical className='gap-y-1'>
            <Text className='font-normal text-sm leading-[18.2px]  text-customGrayShade5'>
              {orderFee}
            </Text>
            <Text className=' font-normal text-base leading-[20.48px] text-customTunaPrimary'>
              {convertedText(paymentType ?? '')}
            </Text>
          </Flex>
        </Col>
        <Col lg={14} md={24} xs={24}>
          <Flex vertical className='gap-y-1'>
            <Text className='font-normal text-sm leading-[18.2px]  text-customGrayShade5'>
              {shippingFee}
            </Text>
            <Text className=' font-normal text-base leading-[20.48px] text-customTunaPrimary'>
              {convertedText(shipmentFeeType ?? '')}
            </Text>
          </Flex>
        </Col>
      </Row>
      <OrderShipmentValueCard
        orderValue={orderValue}
        shipmentFee={shipmentFee}
        total={total}
        shipmentFeeType={shipmentFeeType}
        paymentType={paymentType}
        // isCollectOnDelivery={}
        orderValueText={orderValueText}
        shipmentFeeText={shipmentFeeText}
        customShipmentFee={customShipmentFee}
        customShipmentFeeText={customShipmentFeeText}
        totalText={totalText}
        currency={currency}
      />
    </Card>
  );
};

export default PaymentOrder;
