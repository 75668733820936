import {
  EditOrderModal,
  GreenCheckIcon,
  Heading,
  PencilIcon,
  PriceWithUnit,
  RedCheckIcon,
  TrashIcon,
} from '@/components';
import {
  COLUMN_CUSTOM_DETAILS,
  COLUMN_CUSTOMER_ADDRESS,
  COLUMN_IMPORT_VALID,
  COLUMN_ORDER_PAYMENT,
  COLUMN_PACKAGE_SIZE,
  COLUMN_PRODUCT_DETAILS,
  COLUMN_SHIPMENT,
  LOCAL_EN,
  ORDER_ACTIONS,
  ORDER_ID,
  STYLE_SHARE_TAG,
  THEME_VARIABLE,
} from '@/config';
import { ButtonFilter, DecodeSize, ImportItem, OrderDataImport, OrderExcelResponse } from '@/types';
import { Button, Divider, Flex, message, Row, Spin, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { convertedText, decodeSize } from '@/utils';
import { Key, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
  useDeleteOrderExcelById,
  useFetchOrderExcelByUID,
  useFetchOrderInValidExcelByUID,
  useFetchOrderValidExcelByUID,
} from '@/hooksApi/useImport';

const { Text } = Typography;

const userImportData = (
  id: string,
  current: any,
  buttonFilter: ButtonFilter,
  setCurrentPage: (page: number) => void,
  pageSize?: number,
) => {
  const [editingOrderUid, setEditingOrderUid] = useState<string | undefined>(undefined);

  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const [idSelect, setIdSelect] = useState<string | null>(null);
  // Fetch All data
  const {
    data: dataOrderExcel,
    isLoading: loadingOrderExcel,
    isFetching: isFetchingOrderExcel,
  } = useFetchOrderExcelByUID(id, current, pageSize, buttonFilter === ButtonFilter.All);

  // Fetch valid data
  const {
    data: dataOrderValidExcel,
    isLoading: loadingOrderValidExcel,
    isFetching: isFetchingOrderValidExcel,
  } = useFetchOrderValidExcelByUID(id, current, pageSize, buttonFilter === ButtonFilter.VALID);

  // Fetch invalid data
  const {
    data: dataOrderInValidExcel,
    isLoading: loadingOrderInValidExcel,
    isFetching: isFetchingOrderInValidExcel,
  } = useFetchOrderInValidExcelByUID(id, current, pageSize, buttonFilter === ButtonFilter.INVALID);

  // Delete order excel
  const { mutate: deleteOrderExcelById, isLoading: loadingDeleteOrder } = useDeleteOrderExcelById();

  const handleDelete = (key: string) => {
    deleteOrderExcelById(
      {
        key,
        uid: id,
      },
      {
        onSuccess: async () => {
          message.success(t('operation'));

          queryClient.invalidateQueries(['import', id]);
          queryClient.invalidateQueries(['import-valid', id]);
          queryClient.invalidateQueries(['import-invalid', id]);

          if (isTheOnlyItem()) {
            setCurrentPage(current - 1);
          }
        },
      },
    );
  };

  const isTheOnlyItem = () => {
    const tableItemList = getCurrentDataTable();

    return tableItemList.length === 1;
  };

  const getCurrentDataTable = () => {
    return buttonFilter === ButtonFilter.All
      ? mapOrderData(dataOrderExcel?.object?.items || [])
      : buttonFilter === ButtonFilter.VALID
        ? mapOrderData(dataOrderValidExcel?.object?.items || [])
        : mapOrderData(dataOrderInValidExcel?.object?.items || []);
  };
  const columns: ColumnsType<OrderDataImport> = [
    {
      title: t('validity'),
      dataIndex: COLUMN_IMPORT_VALID,
      key: COLUMN_IMPORT_VALID,
      render: (validity: string, record: OrderDataImport) => {
        const keysErrors = record?.errors && Object.keys(record?.errors);

        return validity === 'VALID' ? (
          <GreenCheckIcon />
        ) : (
          <Tooltip
            title={
              keysErrors &&
              keysErrors?.length > 0 && (
                <Flex vertical>
                  <Text>
                    {keysErrors?.map((key: string, index: number) => (
                      <span key={index}>
                        {key}
                        <br />
                      </span>
                    ))}
                  </Text>
                  <Divider />
                  {keysErrors?.map((error: string, index: Key | undefined) => (
                    <Text key={index}>{`. ${record?.errors[error]}`}</Text>
                  ))}
                </Flex>
              )
            }
            placement='right'
            color='#fff'
            overlayInnerStyle={{ color: THEME_VARIABLE.customTunaPrimary }}
          >
            <Flex>
              <Row
                className={`bg-customValentineRed w-2 h-full absolute ${i18n.language === LOCAL_EN ? '-left-2' : '!-right-2 !w-2'}  top-0`}
              />
              <RedCheckIcon />
            </Flex>
          </Tooltip>
        );
      },
    },
    {
      title: t('table_order_id'),
      dataIndex: ORDER_ID,
      key: ORDER_ID,
      render: (text) => <Heading title={text} />,
    },
    {
      title: t('customer_details'),
      key: COLUMN_CUSTOM_DETAILS,
      render: (_, record: OrderDataImport) => (
        <Flex vertical>
          <Heading title={record.customerName} fontSize='text-sm' />
          <Heading title={record.customerPhone} fontSize='text-sm' />
        </Flex>
      ),
    },
    {
      title: t('customer_address'),
      dataIndex: COLUMN_CUSTOMER_ADDRESS,
      key: COLUMN_CUSTOMER_ADDRESS,
      render: (text) => <Heading title={text} fontSize='text-sm' color='text-customGrayShade5' />,
    },
    {
      title: t('product_details'),
      key: COLUMN_PRODUCT_DETAILS,
      render: (record: OrderDataImport) => (
        <Flex vertical gap={8}>
          <Flex gap={4}>
            <Heading title={t('qty')} fontSize='text-sm' />
            <PriceWithUnit
              subTitle={String(record?.quantity) || ''}
              fontSize='14px'
              fontSizeSub='text-sm'
              fontWeight='font-normal'
              tagStyle={STYLE_SHARE_TAG}
              fontWeightSub='font-medium'
            />
          </Flex>
          <Flex gap={4}>
            <Heading title={t('total')} fontSize='text-sm' />
            <PriceWithUnit
              value={record?.total || 0}
              subTitle={t('iqd')}
              fontSize='14px'
              fontSizeSub='text-sm'
              fontWeight='font-normal'
              tagStyle={STYLE_SHARE_TAG}
              fontWeightSub='font-medium'
            />
          </Flex>
        </Flex>
      ),
    },
    {
      title: t('package_size'),
      dataIndex: COLUMN_PACKAGE_SIZE,
      key: COLUMN_PACKAGE_SIZE,
      render: (text) => (
        <Heading title={decodeSize(text as keyof typeof DecodeSize)} fontSize='text-sm' />
      ),
    },
    {
      title: t('order_payment'),
      dataIndex: COLUMN_ORDER_PAYMENT,
      key: COLUMN_ORDER_PAYMENT,
      render: (text) => <Heading title={convertedText(text)} fontSize='text-sm' />,
    },
    {
      title: t('shipment_payment'),
      dataIndex: COLUMN_SHIPMENT,
      key: COLUMN_SHIPMENT,
      render: (text) => <Heading title={convertedText(text)} fontSize='text-sm' />,
    },
    {
      title: t('actions'),
      key: ORDER_ACTIONS,
      render: (record: OrderDataImport) => (
        <Flex align='center' gap={8}>
          <Button
            onClick={() => setEditingOrderUid(record?.key)}
            className='border-solid border-customTunaPrimary'
          >
            <PencilIcon />
          </Button>
          <Button
            className='border-solid border-customTunaPrimary'
            onClick={() => {
              handleDelete?.(record?.key);
              setIdSelect?.(record?.key);
            }}
          >
            {idSelect === record?.key ? <Spin /> : <TrashIcon />}
          </Button>
        </Flex>
      ),
    },
  ];

  const mapOrderData = (data: OrderExcelResponse['object']['items'] | undefined) => {
    return data && data.length > 0
      ? data.map((item: ImportItem) => ({
          key: item?.uid,
          validity: item?.validation_status || '',
          orderId: item?.platform_code || '',
          customerName: item?.full_name || '',
          customerPhone: item?.phone || '',
          customerAddress: item?.address_text || '',
          quantity: item?.product_quantity || '',
          total: item?.products_value || '',
          packageSize: item?.size || '',
          orderPayment: item?.payment_type || '',
          shipmentPayment: item?.shipment_fee_type || '',
          errors: item?.errors ? JSON.parse(item.errors) : {},
        }))
      : [];
  };

  return {
    columns,
    editingOrderUid: editingOrderUid,
    setEditingOrderUid: setEditingOrderUid,
    dataTables: getCurrentDataTable(),
    loadingOrderExcel:
      buttonFilter === ButtonFilter.All
        ? loadingOrderExcel || isFetchingOrderExcel
        : buttonFilter === ButtonFilter.VALID
          ? loadingOrderValidExcel || isFetchingOrderValidExcel
          : loadingOrderInValidExcel || isFetchingOrderInValidExcel,
    total:
      buttonFilter === ButtonFilter.All
        ? dataOrderExcel?.object?.total || 0
        : buttonFilter === ButtonFilter.VALID
          ? dataOrderValidExcel?.object?.total || 0
          : dataOrderInValidExcel?.object?.total || 0,
    invalid_rows_count:
      buttonFilter === ButtonFilter.VALID
        ? dataOrderValidExcel?.object?.invalid_rows_count || 0
        : buttonFilter === ButtonFilter.All
          ? dataOrderExcel?.object?.invalid_rows_count || 0
          : dataOrderInValidExcel?.object?.invalid_rows_count || 0,
  };
};

export default userImportData;
