import { useSentryMutation, useSentryQuery } from '../utils/sentryQueryWrapper';
import { ResponseType, FileList } from '@/types';
import { exportFilesAsync, fetchExportedFiles } from '@/api';

export const useFetchExportedFiles = () => {
  return useSentryQuery<ResponseType<FileList>, Error>(
    ['exported-file'],
    () => fetchExportedFiles(),
    {
      keepPreviousData: true,
    },
  );
};

export const useExportFilesAsync = () => {
  return useSentryMutation<ResponseType<null>, Error, string>(
    (query: string) => exportFilesAsync(query),
    {},
  );
};
