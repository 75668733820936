import { SVGProps } from 'react';

interface FileIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const FileIcon = ({ width = 24, height = 24, color = '#38383E', ...props }: FileIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    width={width}
    height={height}
    fill='none'
    {...props}
  >
    <path
      fill={color}
      d='M19 2a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h14Zm0 1.5H5a1.5 1.5 0 0 0-1.497 1.412L3.5 5v14a1.5 1.5 0 0 0 1.412 1.497L5 20.5h14a1.5 1.5 0 0 0 1.497-1.412L20.5 19V5a1.5 1.5 0 0 0-1.412-1.497L19 3.5Zm-7.2 11c.11 0 .2.09.2.2v1.1a.2.2 0 0 1-.2.2H7.2a.2.2 0 0 1-.2-.2v-1.1c0-.11.09-.2.2-.2h4.6Zm5-3c.11 0 .2.09.2.2v1.1a.2.2 0 0 1-.2.2H7.2a.2.2 0 0 1-.2-.2v-1.1c0-.11.09-.2.2-.2h9.6Zm0-3c.11 0 .2.09.2.2v1.1a.2.2 0 0 1-.2.2H7.2a.2.2 0 0 1-.2-.2V8.7c0-.11.09-.2.2-.2h9.6Z'
    />
  </svg>
);

export default FileIcon;
