import { Button, Col, Flex, Layout, message, Row, Spin, Tooltip, Typography } from 'antd';
import Heading from '../Heading/Heading ';
import PriceWithUnit from '../Price/PriceWithUnit';
import { InformationIcon, CashIcon, RightIcon, VaultIcon, WarningModalIcon } from '../Icons';
import LineChartItem from '../Chart/LineChartItem';
import { useTranslation } from 'react-i18next';
import { STYLE_TEXT_COD, THEME_VARIABLE } from '@/config';
import UpCircleOutlineIcon from '../Icons/UpCircleOutlineIcon';
import { useCreateRemittance, useFetchAvailableCod } from '@/hooksApi/useRemittance';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import CustomModal from '../Modal/Modal';

const { Text } = Typography;

interface CardAvailableCodProps {
  showChart?: boolean;
  last?: boolean;
}

const CardAvailableCod = ({ showChart = true, last = true }: CardAvailableCodProps) => {
  const [modalWithdraw, setModalWithdraw] = useState<boolean>(false);
  const { t } = useTranslation();
  const { mutate, isLoading } = useCreateRemittance();
  const { data, isLoading: loadingAvailableCod } = useFetchAvailableCod();
  const queryClient = useQueryClient();

  return (
    <Layout className='h-full bg-white flex flex-col rounded-[10px] border-customGrayShade3 border-[1px] shadow-card-dark justify-between items-stretch'>
      <Row className='flex flex-col w-full p-6 h-full' justify='space-between' align='stretch'>
        <Row>
          {!showChart && (
            <Col md={24} className='mt-2 '>
              <Flex className='flex justify-between'>
                {' '}
                <Flex className='gap-x-1 flex items-center mb-auto'>
                  <Text className='font-bold text-xl leading-[23.2px] text-customTunaPrimary'>
                    {t('available_cod')}
                  </Text>
                  <Tooltip title={t('the_balance_that_you_can_withdraw')}>
                    <InformationIcon
                      width={16}
                      height={16}
                      color={THEME_VARIABLE.customGrayShade5}
                    />
                  </Tooltip>
                </Flex>
                <Flex className='p-[20px]  bg-customGrayShade2 rounded-full flex justify-center items-center'>
                  <VaultIcon />
                </Flex>
              </Flex>
            </Col>
          )}
          {showChart && (
            <Col md={21}>
              <Heading
                trailingIcon={<InformationIcon />}
                textTooltip='test'
                title={t('available_cod')}
                level={!showChart ? 4 : 3}
                extraClassName='mb-0'
                fontWeight={!showChart ? 'font-bold' : 'font-normal'}
              />
            </Col>
          )}
          {showChart && (
            <Col md={3}>
              <Heading
                title={t('view_all')}
                trailingIcon={<RightIcon className='mt-1' width={13} height={13} />}
                parentClassName='justify-end'
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col md={24} className={!showChart ? 'mt-16 flex items-center justify-between' : ''}>
            {loadingAvailableCod ? (
              <Spin />
            ) : (
              <Flex vertical>
                <PriceWithUnit
                  value={data?.object?.['available-cod'] || 0}
                  color={THEME_VARIABLE.customTunaPrimary}
                  subTitle={t('iqd')}
                  fontSize={!showChart ? '32px' : '50px'}
                  fontSizeSub='text-2xl'
                />
                {/* <Row className='flex items-center gap-1'>
                  {!showChart && (
                    <Flex align='center' className='gap-x-1'>
                      <UpCircleOutlineIcon />
                      <Text className={STYLE_TEXT_COD}>15%</Text>
                    </Flex>
                  )}
                  <Text className={STYLE_TEXT_COD}>+350,000 {t('iqd')}</Text>
                  <Text className='text-xs font-medium text-customTunaPrimary'>{t('from')}</Text>
                </Row> */}
              </Flex>
            )}
            {!showChart && (
              <Button
                style={{ paddingLeft: '24px', paddingRight: '24px' }}
                type='primary'
                className='rounded-md'
                disabled={data?.object?.['available-cod'] === 0}
                onClick={() => setModalWithdraw(true)}
              >
                <Heading
                  title={t('withdraw')}
                  leadingIcon={<CashIcon width={16} height={16} className='mt-1' />}
                  fontWeight='font-bold'
                  loading={isLoading}
                  textLoading={`${t('loading')}...`}
                />
              </Button>
            )}
          </Col>
        </Row>
      </Row>
      {showChart && <LineChartItem />}

      {/* modal widthdraw */}
      {modalWithdraw && (
        <CustomModal
          visible={modalWithdraw}
          onClose={() => setModalWithdraw(false)}
          title={t('withdraw')}
          footer={false}
        >
          <Flex vertical>
            <Flex vertical className=' items-center mt-8'>
              <WarningModalIcon />
              <Heading
                title={t('You_will_be_withdrawing_all_of_your_current_balance')}
                extraClassName='my-8'
                level={5}
              />
            </Flex>
            <Flex className='justify-end w-full'>
              <Button
                type='primary'
                className='w-28'
                onClick={() =>
                  mutate(
                    {},
                    {
                      onSuccess: () => {
                        message.success(t('operation'));
                        queryClient.refetchQueries(['availableCod']);
                        setModalWithdraw(false);
                      },
                    },
                  )
                }
              >
                {t('confirm')}
              </Button>
            </Flex>
          </Flex>
        </CustomModal>
      )}
    </Layout>
  );
};

export default CardAvailableCod;
