import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';
import useRTOListData from './useRTOListData';
import { getRTOColumns } from '@/pages/pickups/rtoList.tsx';

/**
 * Custom hook to manage RTO tab data and columns
 * @param current Current page number
 * @param pageSize Size of each page
 * @param enabled Whether the tab is currently active
 * @param navigate Navigation function
 */
const useRTOTab = (
  current: number,
  pageSize: number,
  enabled = true,
  navigate: NavigateFunction,
) => {
  const { t } = useTranslation();

  const { dataRTO, totals, isFetching } = useRTOListData(current, pageSize, enabled);

  // Get columns for RTO tab
  const columns = getRTOColumns(t, navigate);

  return {
    data: dataRTO,
    totals,
    isFetching,
    columns,
  };
};

export default useRTOTab;
