import { SVGProps } from 'react';

interface DuplicateIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const DuplicateIcon = ({
  width = 20,
  height = 20,
  color = '#939496',
  ...props
}: DuplicateIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 20'
    width={width}
    height={height}
    fill='none'
    {...props}
  >
    <path
      fill={color}
      fillRule='evenodd'
      d='M4.682 15.313H3.76a1.84 1.84 0 0 1-1.839-1.84V3.765A1.84 1.84 0 0 1 3.76 1.926h9.72a1.84 1.84 0 0 1 1.838 1.838v.924h.923a1.84 1.84 0 0 1 1.84 1.838v9.71a1.84 1.84 0 0 1-1.84 1.838H6.52a1.84 1.84 0 0 1-1.838-1.838v-.924Zm9.385-1.84V3.765a.589.589 0 0 0-.588-.588h-9.72a.589.589 0 0 0-.589.588v9.71c0 .325.264.588.589.588h9.72a.589.589 0 0 0 .588-.588Zm-8.135 1.84v.923c0 .325.264.588.589.588h9.72a.589.589 0 0 0 .588-.588v-9.71a.589.589 0 0 0-.589-.589h-.923v7.537a1.84 1.84 0 0 1-1.838 1.838H5.932Zm2.062-6.069H6.119a.625.625 0 0 1 0-1.25h1.875V6.119a.625.625 0 0 1 1.25 0v1.875h1.875a.625.625 0 0 1 0 1.25H9.244v1.875a.625.625 0 0 1-1.25 0V9.244Z'
      clipRule='evenodd'
    />
  </svg>
);

export default DuplicateIcon;
