import { Card } from '@/components/Card';
import { Heading } from '@/components/Heading';
import { EditOutline, DeleteIcon, CopyIcon, MeatballMenu, CheckOutline } from '@/components/Icons';
import { THEME_VARIABLE } from '@/config';

import { useQueryClient } from '@tanstack/react-query';
import { Col, Divider, Dropdown, Flex, MenuProps, message, Row, Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

interface TeamMembersData {
  uid: string;
  fName: string;
  lName: string;
  phoneNumber?: string;
  email?: string;
  role: string;
}
interface Props extends TeamMembersData {
  setTeamMemberModalData: (TeamMemberUid: string) => void;
}
const TeamMemberCardItem = ({ fName, lName, role, uid, setTeamMemberModalData }: Props) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const handleDeleteTeamMember = () => {
    // deleteTeamMembers(uid, {
    //   onSuccess: () => {
    //     queryClient.invalidateQueries(['TeamMembers']);
    //     message.success(t('operation_completed_successfully'));
    //   },
    // });
  };

  const menuItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Heading
          title={t('edit')}
          leadingIcon={<EditOutline color={THEME_VARIABLE.customTunaPrimary} />}
          rotation={false}
          extraClassName='capitalize'
        />
      ),
      onClick: () => {
        setTeamMemberModalData(uid);
      },
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: (
        <Heading
          extraClassName='capitalize'
          title={t('delete')}
          leadingIcon={<DeleteIcon color={THEME_VARIABLE.customTunaPrimary} />}
          rotation={false}
          // loading={loadingDelete}
        />
      ),
      onClick: () => {
        // Delete
      },
    },
  ];
  return (
    <Card extraClassName='mt-2 rounded-[20px]'>
      <Row gutter={[16, 16]}>
        <Col lg={18} md={18} xs={20}>
          <Typography.Text className='text-base font-light text-customTunaPrimary'>
            {fName + ' ' + lName}
          </Typography.Text>
        </Col>
        <Col lg={6} md={6} xs={4} className='flex items-center justify-between'>
          <Typography.Text className='text-sm text-customGrayShade5'>{role}</Typography.Text>
          <Dropdown
            menu={{ items: menuItems }}
            trigger={['click']}
            rootClassName='bottomSheet'
            placement='bottomLeft'
          >
            <Flex className='cursor-pointer'>
              <MeatballMenu width={20.48} height={20.48} />
            </Flex>
          </Dropdown>
        </Col>
      </Row>
    </Card>
  );
};

export default TeamMemberCardItem;
