import { Button, Col, Flex, Form, Input, message, Row, Select } from 'antd';
import { Store } from 'antd/es/form/interface';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { Heading } from '@/components/Heading';
import { PhoneNumberInput } from '@/components/PhoneNumberInput';
import { AddIcon } from '@/components/Icons';

interface Props {
  teamMemberUID?: string;
  onClose: () => void;
}
const TeamMemberForm = ({ teamMemberUID, onClose }: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  // const { data: TeamMemberData, isLoading } = useFetchTeamMembersByUid(teamMemberUID);
  // const { mutate: createTeamMembers } = useCreateTeamMembers();
  // const { mutate: updateTeamMembers } = useUpdateTeamMembers();

  // useEffect(() => {
  //   if (teamMemberUID) {
  //     // if it's in editing mode => set form value
  //     // form.setFieldsValue({
  //     //   url: TeamMemberData?.object.url ?? '',
  //     //   triggers: TeamMemberData?.object.events?.map((event) => event.uid) ?? [],
  //     // });
  //   }
  //   return () => {
  //     form.resetFields();
  //     queryClient.invalidateQueries(['TeamMembers']);
  //   };
  // }, [teamMemberUID, TeamMemberData]);

  const onFinish = async (values: Store) => {
    if (teamMemberUID === '') {
      // createTeamMembers(
      //   {
      //     event_uids: values.triggers,
      //     properties: values.properties,
      //     url: values.url,
      //   },
      //   {
      //     onSuccess: () => {
      //       onClose();
      //       queryClient.invalidateQueries(['TeamMembers']);
      //     },
      //   },
      // );
    } else {
      // updateTeamMembers(
      //   {
      //     event_uids: values.triggers,
      //     properties: values.properties,
      //     url: values.url,
      //     uid: teamMemberUID ?? '',
      //   },
      //   {
      //     onSuccess: () => {
      //       onClose();
      //       queryClient.invalidateQueries(['TeamMembers']);
      //     },
      //   },
      // );
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Form submission failed:', errorInfo);
    message.error(t('submission_failed'));
  };

  // return isLoading && !!teamMemberUID ? (
  //   <Spin size='large' className='flex justify-center items-center' />
  // ) :
  return (
    <Form
      form={form}
      name='TeamMember-form'
      className='py-2'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Heading title={t('customer_details')} upperCase fontSize='text-base' />
      <Row gutter={[16, 16]} className='mt-4'>
        <Col lg={12} md={12} xs={24}>
          <Form.Item
            name='first_name'
            rules={[{ required: true, message: t('this_field_is_required') }]}
          >
            <Input
              suffix={<span className='text-customRed text-base'>*</span>}
              variant='outlined'
              placeholder={t('first_name')}
              className='custom-input border custom-select-placeholder border-solid border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg w-full'
            />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} xs={24}>
          <Form.Item
            name='last_name'
            rules={[{ required: true, message: t('this_field_is_required') }]}
          >
            <Input
              suffix={<span className='text-customRed text-base'>*</span>}
              variant='outlined'
              placeholder={t('last_name')}
              className='custom-input border custom-select-placeholder border-solid border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg w-full'
            />
          </Form.Item>
        </Col>

        <Col lg={12} md={24} xs={24} className='flex gap-x-2' style={{ direction: 'ltr' }}>
          <PhoneNumberInput
            form={form}
            countryCodeName={'countryCode'}
            phoneNumberName={'phone'}
            phoneNumberPlaceholder={t('primary_phone')}
            required
          />
        </Col>
        <Col lg={12} md={12} xs={24}>
          <Form.Item name='email'>
            <Input
              variant='outlined'
              placeholder={t('email')}
              className='custom-input border custom-select-placeholder border-solid border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg w-full'
            />
          </Form.Item>
        </Col>
        <Col lg={24} md={24} xs={24}>
          <Form.Item name='role' rules={[{ required: true, message: t('this_field_is_required') }]}>
            <Select
              className='custom-multi-select-item custom-select-placeholder min-h-[56px] h-fit rounded-[10px]'
              // suffix={<span className='text-customRed text-base'>*</span>}
              variant='borderless'
              allowClear
              style={{ width: '100%' }}
              suffixIcon={<span className='text-customRed text-base'>*</span>}
              placeholder={t('role')}
              // defaultValue={selectedEvents}
              // onChange={handleSelectedEventsChanged}
              options={[{ title: 'title', uid: 'uid' }].map((item) => ({
                label: item.title,
                value: item.uid,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Flex className='mt-8' justify='end' gap={16}>
        <Button
          className='px-10 py-6 rounded-lg uppercase font-semibold border-customTunaPrimary'
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
        <Button
          type='primary'
          className='px-10 py-6 rounded-lg h-14'
          loading={false}
          htmlType='submit'
        >
          <Heading
            leadingIcon={teamMemberUID ? undefined : <AddIcon width={20} height={20} />}
            title={teamMemberUID ? t('save') : t('add_member')}
            fontWeight='font-semibold'
            fontSize='text-sm'
            extraClassName={'text-customTunaPrimary uppercase'}
          />
        </Button>
      </Flex>
    </Form>
  );
};

export default TeamMemberForm;
