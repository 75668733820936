import { useEffect, useMemo, useState } from 'react';
import { Col, Layout, Row } from 'antd';
import { TableAction, BottomSheet, DeleteIcon, Heading } from '@/components';
import TabBar from '@/components/Tab/TabBar';
import { useTranslation } from 'react-i18next';
import '@/styles/pickups.css';
import { useBulkCancelPickup } from '@/hooksApi/usePickup.ts';
import { useQueryClient } from '@tanstack/react-query';
import useConfirmModalStore from '@/stores/useConfirmModalStore.ts';
import { scrollTop } from '@/utils/helper.ts';
import { PickupPageTabEnum } from '@/types/pickup';
import { useLocation, useNavigate } from 'react-router-dom';
import usePickupTab from '@/hooks/usePickupTab';
import useRTOTab from '@/hooks/useRTOTab';

const pageSize = 25;

const Pickups = () => {
  const [activeTab, setActiveTab] = useState<PickupPageTabEnum>(PickupPageTabEnum.Pickups);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  // Use tab-specific hooks with enabled flag
  const {
    data: pickupData,
    totals: pickupTotals,
    isFetching: pickupFetching,
    columns: pickupColumns,
  } = usePickupTab(
    currentPage,
    pageSize,
    activeTab === PickupPageTabEnum.Pickups,
    navigate,
    queryClient,
  );

  const {
    data: rtoData,
    totals: rtoTotals,
    isFetching: rtoFetching,
    columns: rtoColumns,
  } = useRTOTab(currentPage, pageSize, activeTab === PickupPageTabEnum.RTO, navigate);

  const { mutate: bulkCancelPickup, isLoading: isCancelingPickup } = useBulkCancelPickup();

  // Determine which data to use based on active tab
  const data = activeTab === PickupPageTabEnum.Pickups ? pickupData : rtoData;
  const totals = activeTab === PickupPageTabEnum.Pickups ? pickupTotals : rtoTotals;
  const isFetching = activeTab === PickupPageTabEnum.Pickups ? pickupFetching : rtoFetching;
  const columns = activeTab === PickupPageTabEnum.Pickups ? pickupColumns : rtoColumns;

  const tabItems = [
    {
      key: PickupPageTabEnum.Pickups,
      title: t('pickups'),
      children: null,
    },
    {
      key: PickupPageTabEnum.RTO,
      title: t('returns'),
      children: null,
    },
  ];

  const { openConfirm } = useConfirmModalStore();

  useEffect(() => {
    scrollTop();
  }, [currentPage]);

  useEffect(() => {
    // Reset selection when changing tabs
    setSelectedIds([]);
    setIsAllSelected(false);
    setCurrentPage(1);
  }, [activeTab]);

  useEffect(() => {
    console.log('active tab changed  hhhh');

    // if (activeTab === PickupPageTabEnum.RTO) {
    //   window.location.hash = activeTab;
    // } else {
    //   // Remove hash if on Pickups tab
    //   if (location.hash) {
    //     window.history.pushState('', document.title, location.pathname);
    //   }
    // }
  }, [activeTab]);

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    console.log('hhhhhh', { hash: location.hash, activeTab, res: hash === PickupPageTabEnum.RTO });
    if (hash === PickupPageTabEnum.RTO) {
      setActiveTab(PickupPageTabEnum.RTO);
    } else {
      // Default to Pickups tab and ensure no hash
      setActiveTab(PickupPageTabEnum.Pickups);
      if (location.hash) {
        window.history.pushState('', document.title, location.pathname);
      }
    }
  }, []);

  const allOrdersInCurrentPage = useMemo(() => {
    const result = data.map((item: any) => item.key);
    if (isAllSelected) setSelectedIds(result);
    return result;
  }, [data]);

  const handleChangeTab = (newTab: PickupPageTabEnum) => {
    if (newTab === PickupPageTabEnum.RTO) {
      window.location.hash = newTab;
    } else {
      // Remove hash if on Pickups tab
      if (location.hash) {
        window.history.pushState('', document.title, location.pathname);
      }
    }
    setActiveTab(newTab);
  };

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleCancelPickup = () => {
    openConfirm({
      description: [t('cancel_pickup_confirm'), t('cancel_pickup_condition')],
      cancelText: t('cancel'),
      onConfirm: bulkCancel,
    });
  };

  const bulkCancel = () => {
    bulkCancelPickup(isAllSelected ? [] : selectedIds, {
      onSuccess: () => {
        // Invalidate appropriate cache based on active tab
        if (activeTab === PickupPageTabEnum.Pickups) {
          queryClient.invalidateQueries(['pickups']);
        } else {
          queryClient.invalidateQueries(['rto']);
        }
      },
    });
  };

  const handleChangeSelectedIds = (ids: string[]) => {
    if (isAllSelected) setIsAllSelected(false);
    setSelectedIds(ids);
  };

  const handleToggleIsAllSelected = () => {
    if (isAllSelected) setSelectedIds([]);
    setIsAllSelected(!isAllSelected);
  };

  const allOrdersInCurrentPageAreSelected =
    allOrdersInCurrentPage.length === selectedIds.length &&
    new Set(allOrdersInCurrentPage).size === new Set(selectedIds).size &&
    allOrdersInCurrentPage.every((item) => selectedIds.includes(item));

  return (
    <Layout className='overflow-visible relative w-full'>
      <Row className='w-full'>
        <Col span={24} className='mb-6'>
          <TabBar
            activeTab={activeTab}
            tabItems={tabItems}
            // handleChange={(key) => handleChangeTab(key as PickupPageTabEnum)}
            setActiveTab={(value) => handleChangeTab(value as PickupPageTabEnum)}
          />
        </Col>
        <Col md={24} xs={24} className='mt-6 mb-16'>
          <TableAction
            columns={columns}
            data={data}
            pageSize={pageSize}
            current={currentPage}
            total={totals}
            customClassName='table-pickup'
            onPaginationChange={handlePaginationChange}
            loading={isFetching}
            selectedRowKeys={selectedIds}
            setSelectedRowKeys={handleChangeSelectedIds}
            rowSelectionEnabled={activeTab === PickupPageTabEnum.Pickups}
          />
        </Col>
      </Row>
      <Row
        className={`sticky bottom-6 ${selectedIds.length > 0 && activeTab === PickupPageTabEnum.Pickups ? 'z-50' : '-z-50'}`}
      >
        {/* <Row className={`sticky bottom-6 ${selectedIds.length > 0 ? 'z-50' : '-z-50'}`}> */}
        <div className='relative block w-full overflow-hidden h-[90px]'>
          <BottomSheet
            disableDropdown
            isVisible={selectedIds.length > 0}
            numberSelected={isAllSelected ? totals : selectedIds.length}
            total={totals}
            labelBtn={t('cancel')}
            Icon={<DeleteIcon />}
            onSubmit={handleCancelPickup}
            loadingSubmit={isCancelingPickup}
            isAllSelected={isAllSelected}
            toggleIsAllSelected={handleToggleIsAllSelected}
            shouldHaveSelectAll={allOrdersInCurrentPageAreSelected && totals > 1}
          />
        </div>
      </Row>
    </Layout>
  );
};

export default Pickups;
