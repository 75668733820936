import { SVGProps } from 'react';

interface BoxyLogoSmallProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const BoxyLogoSmall = ({
  width = 20,
  height = 20,
  color = 'white',
  ...props
}: BoxyLogoSmallProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <path
      d='M3.08268 1.66675H16.2597C16.7141 1.66675 17.0825 2.03984 17.0825 2.50008C17.0825 2.56859 17.0741 2.63684 17.0577 2.70328L15.7734 7.87659C15.7668 7.90296 15.7668 7.93053 15.7734 7.9569L17.0577 13.1302C17.1685 13.5766 16.901 14.0294 16.4603 14.1416C16.3947 14.1583 16.3273 14.1667 16.2597 14.1667H4.31685C4.2248 14.1667 4.15018 14.2414 4.15018 14.3334C4.15018 14.3334 4.15018 14.3334 4.15018 14.3334L4.15021 18.1667C4.15021 18.2588 4.07559 18.3334 3.98355 18.3334C3.98355 18.3334 3.98355 18.3334 3.98355 18.3334L3.08268 18.3334C2.99063 18.3334 2.91602 18.2588 2.91602 18.1667V1.83341C2.91602 1.74137 2.99063 1.66675 3.08268 1.66675ZM15.5188 2.91675H4.31688C4.22483 2.91675 4.15021 2.99137 4.15021 3.08341V12.7501C4.15021 12.8421 4.22483 12.9167 4.31688 12.9167H15.5188C15.6109 12.9167 15.6855 12.8421 15.6855 12.7501C15.6855 12.7365 15.6838 12.7231 15.6806 12.7099L14.5008 7.9569C14.4942 7.93053 14.4942 7.90296 14.5008 7.8766L15.6806 3.12357C15.7027 3.03423 15.6483 2.94383 15.559 2.92166C15.5458 2.9184 15.5323 2.91675 15.5188 2.91675Z'
      fill={color}
    />
  </svg>
);

export default BoxyLogoSmall;
