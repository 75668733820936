import { FORM_TYPE_LOGIN } from '@/config';
import { Button, Col, Flex, Form, Select, Typography } from 'antd';
import type { Store } from 'antd/es/form/interface'; // Import the type for the form values
import type { ValidateErrorEntity } from 'rc-field-form/lib/interface'; // Import the correct type for errorInfo
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { PhoneNumberInput } from '../PhoneNumberInput';
import { useForm } from 'antd/es/form/Form';

const { Option } = Select;

interface LoginFormCardProps {
  onFinish: (values: Store) => void;
  onFinishFailed: (errorInfo: ValidateErrorEntity) => void;
  loginText: string;
  phoneNumberPlaceholder: string;
  loginButtonText: string;
  registerText: string;
  registerLinkText: string;
  isLoading?: boolean;
}
const { Text } = Typography;

const LoginFormCard = ({
  onFinish,
  onFinishFailed,
  loginText,
  phoneNumberPlaceholder,
  loginButtonText,
  registerText,
  registerLinkText,
  isLoading,
}: LoginFormCardProps) => {
  const [form] = Form.useForm();

  //HANDLE TO REMOVE NON-NUMERIC CHARACTERS:
  // const handlePhoneInput = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { value } = event.target;
  //   const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
  //   const englishNumberValue = convertArabicToEnglishNumbers(numericValue); // Convert Arabic numbers to English
  //   event.target.value = englishNumberValue;
  // };
  return (
    <Col span={10} className='bg-white'>
      <Form
        form={form}
        name={FORM_TYPE_LOGIN}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className='h-full px-[152px] flex flex-col justify-center items-center mx-auto'
      >
        <Col className='h-full  flex flex-col min-w-[344px]  mt-[98px] justify-between pb-[36px]'>
          <Flex className='flex flex-col gap-2 '>
            <Text className='text-[24px] block  text-customTunaPrimary font-[900] mb-4 leading-[27.8px]'>
              {loginText}
            </Text>

            <Flex
              style={{ direction: 'ltr' }}
              className='flex justify-between gap-x-3 items-center '
            >
              <PhoneNumberInput
                form={form}
                countryCodeName={'countryCode'}
                phoneNumberName={'phoneNumber'}
                phoneNumberPlaceholder={phoneNumberPlaceholder}
              />
            </Flex>
          </Flex>
          <Form.Item>
            <Button
              style={{ paddingBottom: '27px', paddingTop: '27px' }}
              type='primary'
              htmlType='submit'
              loading={isLoading}
              className='w-full text-customTunaPrimary flex uppercase justify-center items-center rounded-[8px]   h-[56px]  text-sm font-bold leading-[13.54px] bg-customYellowPrimary'
            >
              {loginButtonText}
            </Button>
            <Text className='font-[400] mb-10  mt-4 whitespace-nowrap flex pt-[2px] justify-center gap-x-[4px] text-[16px] leading-[11px] text-customTunaPrimary'>
              {registerText}{' '}
              <Link
                to={'/register'}
                className='text-customBlue  font-[700] text-[16px] leading-[11px]'
              >
                {registerLinkText}
              </Link>
            </Text>
          </Form.Item>
        </Col>
      </Form>
    </Col>
  );
};

export default LoginFormCard;
