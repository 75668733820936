import { Flex, Typography } from 'antd';
import React, { useState } from 'react';
import { Card } from '../../Card';
import { Heading } from '../../Heading';
import { AddIcon, DeleteIcon, CheckOutline } from '../../Icons';
import { BasicProduct } from '@/types';
import { useTranslation } from 'react-i18next';
import ProductInputsRow from './ProductInputsRow';

const { Text } = Typography;

const emptyProduct = {
  title: '',
  quantity: '1',
  price: '',
};
interface ProductDetailsCardProps {
  products: BasicProduct[];
  handleCreateProduct: (product: BasicProduct) => boolean;
  handleDeleteProduct: (index: number) => void;
  handleUpdateProduct: (index: number, field: keyof BasicProduct, value: string) => void;
}

const ProductDetailsCard: React.FC<ProductDetailsCardProps> = ({
  products,
  handleDeleteProduct,
  handleUpdateProduct,
  ...props
}) => {
  const [newProduct, setNewProduct] = useState<BasicProduct>(emptyProduct);
  const isProductValid = !!newProduct.title && !!newProduct.quantity && !!newProduct.price;

  const { t } = useTranslation();

  const handleCreateProduct = () => {
    if (isProductValid) {
      const result = props.handleCreateProduct(newProduct);
      if (result) setNewProduct(emptyProduct);
    }
  };

  const handleChangeNewProduct = (field: keyof BasicProduct, value: string) => {
    setNewProduct({ ...newProduct, [field]: value });
  };

  return (
    <Card>
      <Flex>
        <Heading title={t('product_details')} fontSize='text-lg' fontWeight='font-bold' />
        <Text className='text-colorError text-2xl mx-1'>*</Text>
      </Flex>

      <Flex vertical>
        <Text className='font-normal text-base text-customGrayShade5'>{`Product #${products.length + 1}`}</Text>
        <ProductInputsRow
          buttonIcon={isProductValid ? CheckOutline : AddIcon}
          product={newProduct}
          handleChangeProductKey={handleChangeNewProduct}
          handleSubmit={handleCreateProduct}
          disableButton={!isProductValid}
          isPrimaryButton={isProductValid}
        />
      </Flex>

      {products.map((product: BasicProduct, index) => (
        <Flex vertical key={index} className='mt-4'>
          <Text className='font-normal text-base text-customGrayShade5'>{`Product #${index + 1}`}</Text>
          <ProductInputsRow
            buttonIcon={DeleteIcon}
            product={product}
            handleChangeProductKey={(field, value) => handleUpdateProduct(index, field, value)}
            handleSubmit={() => handleDeleteProduct(index)}
          />
        </Flex>
      ))}
    </Card>
  );
};

export default ProductDetailsCard;
