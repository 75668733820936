import { useFetchRTO } from '@/hooksApi';
import { PickupItem } from '@/types';
import { formatDateByDayjs } from '@/utils';

/**
 * Hook for handling RTO list data
 * Uses dedicated RTO API endpoint
 * @param current Current page
 * @param pageSize Page size
 * @param enabled Whether to enable the query
 */
const useRTOListData = (current: number, pageSize?: number, enabled = true) => {
  const { data, isLoading, isFetching } = useFetchRTO(current, pageSize, enabled);

  const dataRTO =
    (data &&
      data?.object?.items?.length > 0 &&
      data?.object?.items.map((item: PickupItem | undefined) => ({
        key: item?.uid ?? '',
        location: item?.pick_up_address?.title,
        driver: {
          phone: item?.driver?.phone,
          name: `${item?.driver?.first_name || ''} ${item?.driver?.last_name || ''}`,
          assign: Boolean(item?.driver) ?? false,
        },
        status: item?.status?.title,
        platform_code: item?.platform_code,
        pickupTime: formatDateByDayjs(item?.created_at, 'D MMMM YYYY'),
        pickupLocation: formatDateByDayjs(item?.created_at, 'H A'),
        // Additional fields specific to RTO can be added here when API is available
      }))) ||
    [];

  // totals
  const totals = dataRTO.length;

  return { dataRTO, totals, isLoading, isFetching };
};

export default useRTOListData;
