import { THEME_VARIABLE } from '@/config';
import { Flex, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Heading } from '../../Heading';
import { AddIcon, SearchIcon } from '../../Icons';
import { useState } from 'react';
import TeamMemberCardItem from './TeamMemberCardItem';
import TeamMemberModal from './TeamMemberModal/TeamMemberModal';

const TeamMemberSection = () => {
  const [teamMemberModalData, setTeamMemberModalData] = useState<string | undefined>(undefined);

  const { t } = useTranslation();

  return (
    <Flex vertical>
      <Flex justify='space-between'>
        <Heading title={t('team_members')} fontSize='text-lg' fontWeight='font-bold' />
        <Flex justify='end' gap={16}>
          <Input
            size='small'
            placeholder={t('search')}
            prefix={<SearchIcon />}
            className='rounded-lg h-11 border-customGrayShade5 bg-customGrayShade1'
            allowClear
            // onChange={handleSearchInputChange}
            // defaultValue={searchValue?.search || ''}
          />
          <Flex
            onClick={() => {
              setTeamMemberModalData('');
            }}
            className='w-[44px] min-w-11 cursor-pointer  h-[44px] flex justify-center items-center rounded-[10px] border border-solid border-customTunaPrimary'
          >
            <AddIcon width={24} height={24} color={THEME_VARIABLE.customTunaPrimary} />
          </Flex>
        </Flex>
      </Flex>
      <Flex vertical gap={16}>
        {/* {TeamMemberDataList?.object.items?.map((item, index) => ( */}
        <TeamMemberCardItem
          fName='Name'
          lName='Last Name'
          role='Admin'
          uid='cxcxcxcxcx'
          key={'uid'}
          // {...item}
          setTeamMemberModalData={setTeamMemberModalData}
        />
        {/* ))} */}
      </Flex>
      <TeamMemberModal
        teamMemberUID={teamMemberModalData}
        onClose={() => setTeamMemberModalData(undefined)}
      />
    </Flex>
  );
};

export default TeamMemberSection;
