import { useState, useRef } from 'react';
import { message } from 'antd';
import type { Store } from 'antd/es/form/interface';
import type { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import type { InputRef } from 'antd';
import { useTranslation } from 'react-i18next';
import { LOCAL_AR, LOCAL_EN, ROUTE } from '@/config';
import type { MenuInfo } from 'rc-menu/lib/interface';
import { useUserStore } from '@/stores';
import { useVerifyOtp } from '@/hooksApi';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '@/stores/authStore';
import useLanguageStore from '@/stores/useLanguageStore';

const useVerify = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [otp, setOtp] = useState(['', '', '', '']); // Array to hold OTP digits
  const [errors, setErrors] = useState([false, false, false, false]); // Array to hold OTP errors
  const inputRefs = useRef<Array<InputRef | null>>([]); // Refs for input fields
  // const [direction, setDirection] = useState('ltr');
  const { i18n, t } = useTranslation();
  const navigation = useNavigate();
  const setToken = useAuthStore((state) => state.setToken);

  const { mutate: verifyOtp, isLoading } = useVerifyOtp();

  const hash = useUserStore((state) => state.hash);

  const onFinish = (values: Store) => {
    if (values.otp && values.otp.length === 4 && hash) {
      setIsSubmitting(true);
      verifyOtp(
        {
          hash,
          password: values.otp,
        },
        {
          onSuccess: (data) => {
            setIsSubmitting(false);

            navigation(ROUTE.ORDERS);
            setToken(data?.object?.access_token);
          },
          onError: () => {
            setIsSubmitting(false);
          },
        },
      );
    }
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    console.warn('Failed:', errorInfo);
  };

  const handleOtpChange = (index: number, value: string) => {
    if (/^[0-9]*$/.test(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Validate the specific input field
      const newErrors = [...errors];
      newErrors[index] = value === '';
      setErrors(newErrors);

      // Move focus to the next input field if current one is filled
      if (value) {
        if (index < inputRefs.current.length - 1) {
          inputRefs.current[index + 1]?.focus();
        } else {
          inputRefs.current[index + 1]?.focus();

          handleSubmit(newOtp);
        }
      }
    }
  };

  const handleBackspace = (index: number) => {
    const newOtp = [...otp];
    newOtp[index] = '';
    setOtp(newOtp);
    if (0 < index) {
      inputRefs.current[index - 1]?.focus();
      inputRefs.current[index - 1]?.select();
    }
  };

  const validateOtp = (finalOtp?: string[]) => {
    const newErrors = (finalOtp ?? otp).map((digit) => digit === '');
    setErrors(newErrors);

    if (newErrors.some((isError) => isError)) {
      message.error('Please enter all four digits of the OTP');
      return false;
    }
    return true;
  };

  const handleSubmit = (finalOtp?: string[]) => {
    if (validateOtp(finalOtp)) {
      onFinish({ otp: (finalOtp ?? otp).join('') }); // Submit the combined OTP
    }
  };

  return {
    // direction,
    otp,
    errors,
    handleOtpChange,
    handleSubmit,
    onFinishFailed,
    inputRefs, // Export refs to use in the component
    isLoading,
    isSubmitting,
    handleBackspace,
  };
};

export default useVerify;
