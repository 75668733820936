import { Flex, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import TeamMemberForm from './TeamMemberForm';
import { Heading } from '@/components/Heading';

interface Props {
  teamMemberUID?: string;
  onClose: () => void;
}
const TeamMemberModal = ({ teamMemberUID, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      className='rounded-[20px] bg-white border   border-customGrayShade3'
      title={
        <Flex style={{ borderBottom: '1px solid #E1E1E3', paddingBottom: '1rem' }}>
          <Heading
            title={teamMemberUID ? t('edit_member') : t('add_new_member')}
            upperCase
            fontSize='text-lg'
            fontWeight='font-semibold'
          />
        </Flex>
      }
      width={760}
      centered
      footer={null}
      open={teamMemberUID !== undefined}
      onOk={onClose}
      onCancel={onClose}
      destroyOnClose
    >
      <TeamMemberForm onClose={onClose} teamMemberUID={teamMemberUID} />
    </Modal>
  );
};

export default TeamMemberModal;
