import { Card, Heading } from '@/components';
import {
  CustomerDetailsCard,
  PackageAndPaymentCard,
  ProductDetailsCard,
  OrderIdCard,
  DeliveryInstructionCard,
  PickupAddressCard,
} from '@/components/OrderForm';
import { ROUTE } from '@/config';
import { useChangeProduct, useUpdateOrderPricePreview } from '@/hooks';
import { useOrderByUID, useUpdateOrder } from '@/hooksApi/useOrder';
import { useFetchListProvinces, useFetchAreaByProvinceCode } from '@/hooksApi/useRegion';
import useProductStore from '@/stores/productStore';
import { BasicProduct, Order, OrderItem, PaymentType, ShipmentFeeType } from '@/types';
import { getOrderPickupAddress, parsePhoneNumber, scrollTop } from '@/utils';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Col, Flex, Form, message, Row, Spin } from 'antd';
import { Store } from 'antd/es/form/interface';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { useEffect, useState, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

interface EditOrderProps {
  keyValue?: string;
}

const EditOrder = ({ keyValue }: EditOrderProps) => {
  const [selectedProvinceCode, setSelectedProvinceCode] = useState<string | undefined>(undefined);

  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { orderUid } = useParams();
  const location = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { products, resetProducts } = useProductStore();

  const {
    handleFormUpdated,
    orderPrice,
    setOrderPrice,
    orderPriceLoading,
    customShipmentFee,
    setCustomShipmentFee,
    setPackageSize,
    transformValuesToOrder,
    checkOrderFormValidation,
    handleProductChanged,
  } = useUpdateOrderPricePreview(products, form);

  const { handleAddProduct, handleDeleteProduct, setProducts, handleUpdateProduct } =
    useChangeProduct(handleProductChanged, orderUid);

  const { mutate: mutateEditOrder, isLoading: editOrderLoading } = useUpdateOrder(orderUid!);
  const { data: orderData, isLoading: displayOrderLoading } = useOrderByUID(orderUid!); // Use useOrderByUID to fetch the order data
  const { data: provinceList, isLoading: provinceListLoading } = useFetchListProvinces();
  const { data: areaList, isLoading: areaListLoading } = useFetchAreaByProvinceCode(
    selectedProvinceCode!,
  );

  useEffect(() => {
    return () => {
      form.resetFields();
      resetProducts();
    };
  }, []);

  useEffect(() => {
    if (!areaListLoading && areaList && areaList?.object.length > 0) {
      const area = areaList.object.find((item) => item.uid === orderData?.object.region?.uid);

      form.setFieldsValue({ region_uid: area?.uid });
    }
  }, [areaListLoading, areaList, orderData, keyValue]);

  useEffect(() => {
    form.resetFields();

    if (orderData) {
      setOrderPrice({
        ...orderData.object.preview,
      });

      setCustomShipmentFee(orderData.object.fee_customer_payable, true);

      if (orderData?.object?.products) {
        setProducts(orderData?.object.products);
      }
      const provinceCode = provinceList?.object.find(
        (item) => item.province_code === orderData.object.region?.province_code,
      )?.province_code;
      const area_uid =
        provinceCode && provinceCode === selectedProvinceCode
          ? orderData.object.region?.uid
          : undefined;
      if (provinceCode && provinceCode !== selectedProvinceCode)
        setSelectedProvinceCode(provinceCode);

      const primaryPhoneInfo = parsePhoneNumber(orderData.object.phone);
      const additionalPhones: string[] | undefined = orderData.object.additional_phones
        ? JSON.parse(orderData.object.additional_phones)
        : undefined;

      const secondaryPhoneInfo = parsePhoneNumber(additionalPhones?.[0]);

      // Prepopulate the form with fetched order data
      form.setFieldsValue({
        name: orderData.object?.full_name || '',
        address: orderData.object?.address_text || '',
        deliveryInstruction: orderData?.object?.description || '',
        packageSize: orderData?.object?.size || '',
        isFragile: orderData?.object?.is_fragile,
        CodOrPrepaid: orderData?.object?.payment_type,
        CustomerOrMerchant: orderData?.object?.shipment_fee_type,
        orderId: orderData?.object?.custom_id,
        pickupOrDropoff: orderData?.object?.pick_up_type,
        saved_pick_up_address_uid: getOrderPickupAddress(orderData.object as unknown as OrderItem)
          ?.uid,
        freezed_pick_up_address_title:
          orderData.object.status.slug !== 'new'
            ? orderData.object.pick_up?.pick_up_address?.title
            : undefined,
        phoneNumberPrimary: primaryPhoneInfo.phone || '',
        countryCodePrimary: primaryPhoneInfo.countryCode,
        countryCodeSecondary: secondaryPhoneInfo?.countryCode,
        secondaryPhoneNumber: secondaryPhoneInfo?.phone,
        province: provinceCode,
        region_uid: area_uid,
        products: orderData?.object?.products,
      });
    }
  }, [orderData, form, keyValue]); // Include 'form' in the dependency array
  console.log({ products });

  const onFinish = (values: Store) => {
    const orderValue = { products, ...values };
    const isValid = checkOrderFormValidation(orderValue);
    if (isValid) {
      const orderPayload: Order = transformValuesToOrder(orderValue);
      console.log({ orderPayload, orderValue });

      // If saveToAddress is false, directly create the order
      mutateEditOrder(orderPayload, {
        onSuccess: () => {
          setProducts([]);

          queryClient.refetchQueries(['orders-readyToShip', 1]);
          message?.success(t('order_edited_successfully'));
          navigate(location.state?.prevPage ?? ROUTE.ORDERS);
          // scrollTop();
        },
      });
    }
  };
  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    const formValues = form.getFieldsValue();

    checkOrderFormValidation({ ...formValues, products });

    console.warn('Failed: ', { ...errorInfo, products });
  };

  const handleSetSelectedProvinceCode = (provinceCode?: string) => {
    form.setFieldsValue({ province: provinceCode });
    form.setFieldsValue({ region_uid: undefined });
    form.setFieldValue('region_uid', undefined);
    setSelectedProvinceCode(provinceCode);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleCreateProduct = (product: BasicProduct) => {
    const formValues = form.getFieldsValue();

    const newProductList = [...(formValues.products ?? []), product];

    if (checkOrderFormValidation({ ...formValues, products: newProductList }, true)) {
      handleAddProduct(product);
      return true;
    }
    return false;
  };
  return (
    <Flex vertical>
      {displayOrderLoading ? (
        <Spin size='large' className='flex justify-center items-center' />
      ) : (
        <Form
          form={form}
          name='add-new-order'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={handleFormUpdated}
          onKeyDown={handleKeyPress}
        >
          <Heading
            title={t('edit_order')}
            routeLink={location.state?.prevPage ?? ROUTE.ORDERS}
            fontSize='text-2xl'
            fontWeight='font-black'
            extraClassName='leading-[27.84px] uppercase'
          />

          <Row gutter={[24, 16]} className='mt-[34px] mb-3'>
            <Col lg={14} md={24} xs={24}>
              <CustomerDetailsCard
                form={form}
                selectedProvinceCode={selectedProvinceCode}
                setSelectedProvinceCode={handleSetSelectedProvinceCode}
              />
              <DeliveryInstructionCard />
              <OrderIdCard />
              <ProductDetailsCard
                products={products}
                handleCreateProduct={handleCreateProduct}
                handleDeleteProduct={handleDeleteProduct}
                handleUpdateProduct={handleUpdateProduct}
              />
              <PickupAddressCard
                form={form}
                orderStatus={orderData?.object.status.slug}
                previewOrderPrice={(formValue) =>
                  handleFormUpdated(
                    { saved_pick_up_address_uid: formValue.saved_pick_up_address_uid },
                    formValue,
                  )
                }
              />
            </Col>
            <Col lg={10} md={24} xs={24}>
              <div className='sticky top-4  w-full '>
                <PackageAndPaymentCard
                  form={form}
                  onPackageSizeChange={setPackageSize}
                  orderPrice={orderPrice}
                  orderPriceLoading={orderPriceLoading}
                  paymentType={orderData?.object?.payment_type}
                  shipmentFeeType={orderData?.object?.shipment_fee_type}
                  packageSize={orderData?.object?.size}
                  onCustomShipmentFeeChange={setCustomShipmentFee}
                  customShipmentFee={customShipmentFee}
                />
              </div>
            </Col>
          </Row>
          <Row className=' sticky bottom-2'>
            <Col lg={24} md={24} xs={24}>
              <Card extraClassName='py-[19px]'>
                <Flex gap='middle' justify='flex-end' align='center'>
                  <Button
                    loading={editOrderLoading}
                    htmlType='submit'
                    type='primary'
                    className='rounded-[8px] disabled:bg-yellow-100 gap-x-2'
                    style={{ paddingBottom: '27px', paddingTop: '27px' }}
                  >
                    <Heading
                      title={t('update')}
                      fontWeight='font-bold'
                      fontSize='text-sm'
                      extraClassName=' uppercase leading-5 text-customTunaPrimary'
                    />
                  </Button>
                </Flex>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </Flex>
  );
};

export default EditOrder;
