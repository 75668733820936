import { AddIcon, CheckOutline } from '@/components/Icons';
import { THEME_VARIABLE } from '@/config';
import {
  useCreateWebhookProperty,
  useDeleteWebhookProperty,
  useFetchWebhooksProperties,
  useUpdateWebhookProperty,
} from '@/hooksApi';
import { useQueryClient } from '@tanstack/react-query';
import { Col, Flex, Form, FormInstance, Input, Row, Typography } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import PropertiesItem from './PropertiesItem';
import { WebHooksProperty } from '@/types';

interface Props {
  webhookUID?: string;
  formInstance: FormInstance;
  properties?: WebHooksProperty[];
}

export interface PropertyData {
  key?: string;
  value?: string;
  type?: 'header';
}
const PropertiesHeaderSection = ({
  formInstance,
  webhookUID, // if !!webhookUID => is in editing mode
  properties,
}: Props) => {
  const [propertyFormData, setPropertyFormData] = useState<PropertyData | undefined>(undefined);
  const [propertiesList, setPropertiesList] = useState<WebHooksProperty[]>(properties ?? []);

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate: createWebhookProperty } = useCreateWebhookProperty();
  const { mutate: deleteWebhookProperty } = useDeleteWebhookProperty();
  const { mutate: updateWebhookProperty } = useUpdateWebhookProperty();

  const formIsValid = useMemo(() => {
    return !!propertyFormData?.key && !!propertyFormData?.value;
  }, [propertyFormData?.key, propertyFormData?.value]);

  useEffect(() => {
    if (webhookUID) {
      // if it's in editing mode
      setPropertiesList(properties ?? []);
    }
  }, [properties?.[0]?.uid, webhookUID]);

  const handleInputsChanged = (text: string, changedItem: 'key' | 'value') => {
    setPropertyFormData({ ...propertyFormData, [changedItem]: text });
  };

  const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleCreateProperty();
  };

  const handleCreateProperty = () => {
    if (webhookUID) {
      createWebhookProperty(
        {
          webhookUid: webhookUID ?? '',
          type: 'header',
          key: propertyFormData?.key ?? '',
          value: propertyFormData?.value ?? '',
        },
        {
          onSuccess: (data) => {
            handleAddProperty(data.object.uid);
          },
        },
      );
    } else {
      handleAddProperty();
    }
  };

  const handleDeleteProperty = (propertyIndex: number) => {
    if (webhookUID) {
      deleteWebhookProperty(
        {
          uid: propertiesList[propertyIndex].uid ?? '',
          webhookUid: webhookUID,
        },
        {
          onSuccess: () => {
            handleRemoveProperty(propertyIndex);
            queryClient.invalidateQueries(['webhooks']);
          },
        },
      );
    } else {
      handleRemoveProperty(propertyIndex);
    }
  };

  const handleUpdateProperty = (text: string, changedField: 'key' | 'value', index: number) => {
    if (webhookUID) {
      updateWebhookProperty(
        {
          propertyData: {
            ...propertiesList[index],
            type: 'header',
            [changedField]: text,
          },
          webhookUid: webhookUID,
        },
        {
          onSuccess: () => {
            handleChangeProperty(text, changedField, index);
            queryClient.invalidateQueries(['webhooks']);
          },
        },
      );
    } else {
      handleChangeProperty(text, changedField, index);
    }
  };

  const handleAddProperty = (uid?: string) => {
    const propertiesFormValue: WebHooksProperty[] = formInstance.getFieldValue('properties') ?? [
      ...propertiesList,
    ];
    const newPropertiesList: WebHooksProperty[] = [
      ...propertiesFormValue,
      { type: 'header', ...propertyFormData },
    ];

    setPropertiesList(newPropertiesList);
    formInstance.setFieldValue('properties', newPropertiesList);

    setPropertyFormData(undefined);
  };

  const handleRemoveProperty = (propertyIndex: number) => {
    const propertiesFormValue: WebHooksProperty[] = formInstance.getFieldValue('properties') ?? [
      ...propertiesList,
    ];

    propertiesFormValue.splice(propertyIndex, 1);

    setPropertiesList([...propertiesFormValue]);
    formInstance.setFieldValue('properties', [...propertiesFormValue]);
  };

  const handleChangeProperty = (text: string, changedField: 'key' | 'value', index: number) => {
    const propertiesFormValue: WebHooksProperty[] = formInstance.getFieldValue('properties') ?? [];
    if (propertiesFormValue[index]) {
      propertiesFormValue[index] = {
        ...propertiesFormValue[index],
        [changedField]: text,
      };

      setPropertiesList([...propertiesFormValue]);
      formInstance.setFieldValue('properties', [...propertiesFormValue]);
    }
  };

  return (
    <Flex vertical gap={16}>
      <Form.Item name='properties' className='hidden' />
      <Typography.Text className='text-base'>{t('headers')}</Typography.Text>
      <Row gutter={[16, 16]}>
        <Col lg={11} md={11} xs={24}>
          <Input
            placeholder={t('key')}
            value={propertyFormData?.key}
            onChange={(e) => handleInputsChanged(e.target.value, 'key')}
            onKeyDown={handleEnterKey}
            className='border relative border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg'
          />
        </Col>
        <Col lg={10} md={10} xs={24}>
          <Input
            placeholder={t('value')}
            value={propertyFormData?.value}
            onChange={(e) => handleInputsChanged(e.target.value, 'value')}
            onKeyDown={handleEnterKey}
            className='border relative border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg'
          />
        </Col>
        <Col lg={3} md={3} xs={24} className='flex justify-end'>
          <Flex
            className='border rounded-lg border-solid flex justify-center items-center h-[54px] w-[54px]'
            onClick={handleCreateProperty}
            style={{
              cursor: formIsValid ? 'pointer' : 'not-allowed',
              backgroundColor: formIsValid
                ? THEME_VARIABLE.customYellowPrimary
                : THEME_VARIABLE.customWhite,
              borderColor: formIsValid
                ? THEME_VARIABLE.customYellowPrimary
                : THEME_VARIABLE.GrayShade5,
            }}
          >
            {formIsValid ? (
              <CheckOutline color={THEME_VARIABLE.customBlack} width={24} height={24} />
            ) : (
              <AddIcon color={THEME_VARIABLE.customTunaPrimary} />
            )}
          </Flex>
        </Col>
      </Row>
      {propertiesList.map((property, index) => (
        <PropertiesItem
          key={index + (property.key ?? '') + property.uid}
          property={property}
          handleDeleteProperty={() => handleDeleteProperty(index)}
          handleUpdateProperty={(text, changedField) =>
            handleUpdateProperty(text, changedField, index)
          }
        />
      ))}
    </Flex>
  );
};

export default PropertiesHeaderSection;
