import { DownloadIcon, EyeIcon, Heading, PDFIcon, StatusTag } from '@/components';
import {
  DRIVER,
  LOCATION,
  ORDER_ACTIONS,
  ORDER_STATUS,
  PICKUP,
  ROUTE,
  IMAGE_SAMPLE,
  OrderStatusEnum,
} from '@/config';
import { ColumnsType } from 'antd/es/table';
import { Flex, Typography } from 'antd';
import { ITable } from '@/components/TableAction/TableAction';
import { ActionsTable } from '@/components/Action';
import UserContactCard from '@/components/Card/UserContactCard';
import { StatusEnum } from '@/types';
import { NavigateFunction } from 'react-router-dom';
import { getStatusTagData } from '@/utils/getStatus';
import { downloadFile } from '@/utils';
import { fetchLabelRTO_CSV, fetchLabelRTO_PDF } from '@/api';

export interface RTOData extends ITable {
  pickupTime: string;
  pickupLocation: string;
  location: string;
  driver: {
    phone: string;
  };
  status: StatusEnum;
  driver_tasks_Icon: string;
}

const { Text } = Typography;

export const getRTOColumns = (t: (key: string) => string, navigate: NavigateFunction) => {
  // RTO menu items: View, PDF, CSV
  const getRTOMenuItems = (uid: string) => {
    return [
      {
        key: '1',
        label: t('view'),
        onClick: () => navigate(`${ROUTE.ORDER_DETAILS_RTO}/${uid}`),
        leadingIcon: <EyeIcon stroke='#fff' fill='#fff' />,
      },
      {
        key: '2',
        label: 'PDF',
        onClick: async () => {
          // Handle PDF download
          await downloadFile(uid, fetchLabelRTO_PDF, `RTO_${uid}.pdf`);
        },
        leadingIcon: <PDFIcon />,
      },
      {
        key: '3',
        label: 'CSV',
        onClick: async () => {
          // Handle CSV download
          await downloadFile(uid, fetchLabelRTO_CSV, `RTO_${uid}.csv`);
        },
        leadingIcon: <DownloadIcon color='#939496' />,
      },
    ];
  };

  const columns: ColumnsType<RTOData> = [
    {
      title: <Text className='font-bold text-xs leading-[15.23px]'>{t('dropoff_date')}</Text>,
      dataIndex: PICKUP,
      key: PICKUP,
      render: (_, record) => (
        <>
          <Heading title={record.pickupTime} extraClassName='mb-2' />
          <Heading title={record.pickupLocation} />
        </>
      ),
    },
    {
      title: <Text className='font-bold text-xs leading-[15.23px]'>{t('dropoff_location')}</Text>,
      dataIndex: LOCATION,
      key: LOCATION,
      render: (text) => <Heading title={text} fontSize='text-sm' />,
    },
    {
      title: <Text className='font-bold text-xs leading-[15.23px]'>{t('driver')}</Text>,
      dataIndex: DRIVER,
      key: DRIVER,
      render: (record) => {
        return (
          <UserContactCard
            profileImageUrl={IMAGE_SAMPLE}
            rating={4.5}
            assign={record?.assign}
            textItem={<Heading title={record.name || '----'} fontSize='text-sm' />}
            subTitleItem={<Heading title={record.phone || '----'} fontSize='text-sm' />}
          />
        );
      },
    },
    {
      title: <Text className='font-bold text-xs leading-[15.23px]'>{t('status')}</Text>,
      dataIndex: ORDER_STATUS,
      key: ORDER_STATUS,
      render: (text: OrderStatusEnum) => {
        const statusData = getStatusTagData(text ?? OrderStatusEnum.new);
        return (
          <StatusTag
            text={t(`RTO_status.${text ?? 'Unknown'}`)}
            color={statusData?.tagColor || 'processing'}
            className='rounded-full text-sm'
            iconColor={statusData?.iconColor || ''}
          />
        );
      },
    },
    {
      title: (
        <Flex justify='center'>
          <Text className='font-bold text-xs leading-[15.23px]'>{t('actions')}</Text>
        </Flex>
      ),
      key: ORDER_ACTIONS,
      render: (record: { key: string | undefined }) => {
        return (
          <Flex justify='center'>
            <ActionsTable customMenuItems={getRTOMenuItems(record.key || '')} />
          </Flex>
        );
      },
      fixed: 'right',
    },
  ];

  return columns;
};
