import { LOCAL_EN, ROUTE, THEME_VARIABLE } from '@/config';
import { ConfigProvider, Layout, Menu, message, Space } from 'antd';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { BoxyLogo, OrdersIcon, PickupsIcon, FinanceIcon, RightIcon } from '../Icons';
import { useFetchUser } from '@/hooksApi';
import { useAuthStore } from '@/stores/authStore';
import { getSidebarRouteInfo } from '@/utils';
import { useUserStore } from '@/stores';
import { useCustomerIO } from '@/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { AvatarText } from '../AvatarText';
const { Sider } = Layout;

const Sidebar = () => {
  const clearToken = useAuthStore((state) => state.clearToken);
  const { user, setUser } = useUserStore((state) => state);

  const { t, i18n } = useTranslation();
  const location = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { identifyUser } = useCustomerIO();
  const { data } = useFetchUser();

  useEffect(() => {
    validateUser();
  }, [data]);

  const validateUser = useCallback(() => {
    if (!data?.object) return;
    if (!user?.uid) identifyAndSetUser();
    if (data?.object?.type !== 'MERCHANT') {
      message?.error(t('wrong_user_type'));
      handleLogOut();
    }
    if (!data.object.verified_at) {
      message?.error(t('user_not_verified'));
    }
  }, [data, user]);

  const identifyAndSetUser = () => {
    setUser(data?.object);

    identifyUser(data?.object.uid ?? '', {
      phone: data?.object?.phone,
      user_type: 'MERCHANT',
      email: data?.object?.email,
      firstname: data?.object?.first_name,
      lastname: data?.object?.last_name,
      fullname: `${data?.object?.first_name}${data?.object?.last_name}`,
    });
  };
  const handleLogOut = useCallback(() => {
    clearToken();
    queryClient.invalidateQueries(['user']);
  }, [clearToken, navigate]);

  // Identify the selected route based on the current path
  const selectedTab = getSidebarRouteInfo(location.pathname);

  const handleNavigate = useCallback((route: string) => () => navigate(route), [navigate]);

  const menuItems = [
    // { key: ROUTE.DASHBOARD, icon: <DashboardIcon />, label: t('dashboard') },
    { key: ROUTE.ORDERS, icon: <OrdersIcon />, label: t('orders') },
    { key: ROUTE.PICKUPS, icon: <PickupsIcon />, label: t('pickups') },
    { key: ROUTE.FINANCE, icon: <FinanceIcon />, label: t('finance') },
  ];
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: THEME_VARIABLE.colorBlack,
            colorBgContainer: THEME_VARIABLE.colorWhite,
            colorText: THEME_VARIABLE.colorBlack,
            colorPrimaryBg: THEME_VARIABLE.customYellowPrimary,
          },
        }}
      >
        <Sider
          breakpoint='sm'
          collapsedWidth='0'
          width={200}
          theme='light'
          style={{ height: 'calc(100vh - 48px)' }}
          className='rounded-lg bg-colorWhite shadow-card-dark border-solid border-0 border-customGrayShade3 flex flex-col justify-between sticky top-6'
        >
          <div>
            <Space
              className='pt-[34px] pb-[30.8px] pl-8 pr-16'
              onClick={() => navigate(ROUTE.ORDERS)}
            >
              <Link to='/orders'>
                <BoxyLogo />
              </Link>
            </Space>
            <Menu
              mode='vertical'
              selectedKeys={[
                selectedTab?.name === 'Unknown' ? ROUTE.DASHBOARD : `/${selectedTab?.location}`,
              ]}
              className='px-4 flex-1 flex flex-col gap-4'
              style={{ border: 'none' }}
              onClick={(e) => {
                navigate(e.key);
              }}
              items={menuItems.map((item) => ({
                key: item.key,
                icon: item.icon,
                label: item.label,
              }))}
            />

            <Menu className='px-4 flex-1 flex flex-col gap-4' style={{ border: 'none' }}>
              <Menu.Item
                key={ROUTE.SETTINGS}
                onClick={handleNavigate(ROUTE.SETTINGS)}
                className='absolute bottom-6 left-0 right-0  h-[40px] bg-customGrayShade2'
              >
                <Space className=' flex  items-center justify-between  rounded-lg    mt-auto'>
                  <AvatarText
                    subText={data?.object?.type}
                    text={(data?.object.first_name ?? '') + ' ' + (data?.object.last_name ?? '')}
                    avatarUrl={data?.object?.avatar?.abs_url}
                  />
                  <RightIcon
                    className={`align-middle ${i18n.language === LOCAL_EN ? 'rotate-0' : 'rotate-180'}`}
                  />
                </Space>
              </Menu.Item>
            </Menu>
          </div>
        </Sider>
      </ConfigProvider>
    </>
  );
};

export default Sidebar;
